import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Dialog,
  Alert,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useRef } from 'react';
import { MerchantCredentials } from '../../models/merchant';
import { Loader } from '../../utils/Loader';
import CancelIcon from '@mui/icons-material/Cancel';
import action_generate_new_secret from '../../assets/icons/action_generate_new_secret.svg';
import secret_new from '../../assets/icons/secret_new.svg';
import CopyIcon from '../MerchantCredentialsDialog/CopyIcon/CopyIcon';
import ErrorAlert from '../Shared/ErrorAlert';
import SecretExpiry from '../Shared/Secret Expiry';

const useStyles = makeStyles()((theme) => {
  return {
    dialogContentSection: {
      paddingBottom: theme.spacing(1),
    },
    merchantTable: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '85%',
    },
    merchantRowItem: {
      height: '3.125rem',
      display: 'flex',
      alignItems: 'center',
      '& > div': {
        flex: 1,
      },
      '& > div:first-of-type': {
        flexBasis: '150px',
        marginLeft: '0.625rem',
        flexGrow: 0,
      },
    },
    newSecret: {
      transition: 'background 1s ease-in-out',
    },
    secret: {
      padding: theme.spacing(0),
    },
    rowContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    copyButton: {
      marginLeft: theme.spacing(2),
    },
    paragraphText: {
      marginBottom: '0.6rem',
    },
    merchantNameText: {
      color: '#515151',
    },
    merchantLocationText: {
      fontWeight: 'bold',
      fontStyle: 'italic',
      color: '#000000',
    },
    rowLabelText: {
      marginBottom: '0rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: '0.875rem',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      lineHeight: '1.43',
      letterSpacing: '0.01071em',
    },
    rowBodyText: {
      marginBottom: '0rem',
      padding: theme.spacing(1),
      paddingTop: '0px',
      paddingBottom: '0px',
      fontSize: '0.875rem',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: '400',
      lineHeight: '1.43',
      letterSpacing: '0.01071em',
    },
    newCredentiallLoading: {
      marginLeft: theme.spacing(2),
    },
    buttonContainer: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '0.875rem',
      marginBottom: '1.188rem',
      width: '60%',
      justifyContent: 'space-around',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    headerText: {
      paddingTop: '1.875rem',
      paddingBottom: '1.875rem',
      paddingLeft: '2.5rem',
      textAlign: 'left',
      fontFamily: 'Open Sans',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      lineHeight: '1.375rem',
      letterSpacing: '0.0112rem',
      color: 'var(--primary-purple)',
    },
    actionButton: {
      alignSelf: 'center',
      width: '12.5rem',
      backgroundColor: '#E5F6FD',
      borderColor: '#064764',
      padding: '1.063rem',
      marginTop: '1.25rem',
      marginBottom: '0.688rem',
      '&:hover': {
        backgroundColor: '#CEE7F1',
      },
    },
    actionButtonText: {
      paddingLeft: '0.5rem',
      font: 'normal normal normal 14px / 13px Open Sans',
      letterSpacing: '0',
      textAlign: 'center',
      color: '#064764',
    },
    actionButtonIcon: {
      width: '1.2rem',
      height: '1.2rem',
    },
    iconButtonContainer: {
      height: '3.125rem',
      width: '3.125rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: '0.3s',
      '&:hover': {
        borderRadius: '50%',
        backgroundColor: '#F6F6F6',
        verticalAlign: 'middle',
      },
      '&:active': {
        backgroundColor: '#dedede',
        transition: 'background 0s',
      },
    },
    iconButton: {
      color: '#4D4D4D',
    },
    alertBox: {
      fontSize: '0.813rem',
      lineHeight: '1.5rem',
      letterSpacing: '0.008rem',
      fontFamily: 'Open Sans',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '0.875rem',
      borderRadius: '0.313rem',
      textAlign: 'left',
    },
    placeholderImage: {
      width: '2.188rem',
      height: '1.063rem',
      visibility: 'hidden',
    },
    modalProgress: {
      paddingTop: '8rem',
      paddingBottom: '8rem',
    },
  };
});

export interface MerchantCredentialsDialogProps {
  open: boolean;
  selectedMerchant: MerchantCredentials | null;
  onClose: () => void;
  generateNewCredentialAction: (merchantId: number) => void;
  newCredential: boolean;
  newMerchant: boolean;
  loadingNewCredential: boolean;
  loading: boolean;
  credentialsAlertVisible: boolean;
  secretAlertVisible: boolean;
  isPOS: boolean;
}

const MerchantCredentialsDialog = (props: MerchantCredentialsDialogProps) => {
  const { classes } = useStyles();
  const {
    onClose,
    selectedMerchant,
    open,
    generateNewCredentialAction,
    newCredential,
    newMerchant,
    credentialsAlertVisible,
    secretAlertVisible,
    loading,
    isPOS,
  } = props;

  const handleClose = () => {
    onClose();
  };

  const inputRef = useRef(null);

  const copyValue = (valueToCopy: any) => {
    let tempInput = document.createElement('input');
    tempInput.value = valueToCopy;
    const myElement: HTMLElement = inputRef.current!;
    myElement.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    myElement.removeChild(tempInput);
  };

  const handleCopyTenantId = () => {
    copyValue(selectedMerchant?.credential.tenantId);
  };

  const handleCopyResourceId = () => {
    copyValue(selectedMerchant?.credential.resourceId);
  };

  const handleCopyClientId = () => {
    copyValue(selectedMerchant?.credential.clientId);
  };

  const handleCopyClientSecret = () => {
    copyValue(selectedMerchant?.credential.clientSecret);
  };

  const generateNewCredential = () => {
    if (selectedMerchant?.merchantId) {
      generateNewCredentialAction(selectedMerchant.merchantId);
    }
  };

  return (
    <div>
      <Dialog
        aria-labelledby="merchant-credentials-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
        scroll={'paper'}
      >
        {credentialsAlertVisible && (
          <DialogTitle
            className={classes.headerText}
            sx={{ paddingBottm: '1rem' }}
          >
            Error retrieving credentials
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
        )}
        {!credentialsAlertVisible && newMerchant && (
          <DialogTitle className={classes.headerText}>
            New merchant site created successfully
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
        )}
        {!credentialsAlertVisible && !newMerchant && isPOS && (
          <DialogTitle className={classes.headerText}>
            Manage credentials
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
        )}
        {!credentialsAlertVisible && !newMerchant && !isPOS && (
          <DialogTitle className={classes.headerText}>
            View credentials
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
        )}
        {loading && (
          <div className={classes.modalProgress}>
            <Loader isLoading={loading} children={undefined} />
          </div>
        )}
        {!loading && (
          <DialogContent
            sx={{
              marginTop: '-1.5625rem',
              overflowY: 'visible',
              overflowWrap: 'break-word',
              paddingLeft: '2.5rem',
              paddingRight: '2.5rem',
              fontSize: '0.875rem',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              fontWeight: 400,
              lineHeight: 1.43,
              letterSpacing: '0.01071em',
              textAlign: 'left',
            }}
          >
            <span className={classes.merchantNameText}>
              {selectedMerchant?.merchantName}
            </span>
            <span> — </span>
            <span className={classes.merchantLocationText}>
              {selectedMerchant?.siteLocation}
            </span>
          </DialogContent>
        )}
        <Box className={classes.dialogContentSection}>
          {credentialsAlertVisible && (
            <DialogContent dividers sx={{ paddingTop: '2rem' }}>
              <ErrorAlert
                alertAction="this merchant's credentials could not be retrieved"
                alertVisible={credentialsAlertVisible}
              />
            </DialogContent>
          )}
          {!credentialsAlertVisible && !loading && (
            <>
              <List aria-label="List of credential information">
                <DialogContent dividers={true}>
                  <DialogContent className={classes.merchantTable}>
                    <ListItem className={classes.merchantRowItem}>
                      <img className={classes.placeholderImage} />
                      <Box>
                        <div className={classes.rowLabelText}>Tenant ID</div>
                      </Box>
                      <Box className={classes.rowContainer}>
                        <span ref={inputRef}>
                          <span className={classes.rowBodyText}>
                            {selectedMerchant?.credential.tenantId}
                          </span>
                        </span>
                        <CopyIcon onClickAction={handleCopyTenantId} />
                      </Box>
                    </ListItem>
                    <ListItem className={classes.merchantRowItem}>
                      <img className={classes.placeholderImage} />
                      <Box>
                        <div className={classes.rowLabelText}>Resource ID</div>
                      </Box>
                      <Box className={classes.rowContainer}>
                        <span ref={inputRef}>
                          <span className={classes.rowBodyText}>
                            {selectedMerchant?.credential.resourceId}
                          </span>
                        </span>
                        <CopyIcon onClickAction={handleCopyResourceId} />
                      </Box>
                    </ListItem>
                    <ListItem className={classes.merchantRowItem}>
                      {newMerchant && (
                        <img src={secret_new} alt="New Secret Icon" />
                      )}
                      {!newMerchant && (
                        <img className={classes.placeholderImage} />
                      )}
                      <Box>
                        <div className={classes.rowLabelText}>Client ID</div>
                      </Box>
                      <Box className={classes.rowContainer}>
                        <span ref={inputRef}>
                          <span className={classes.rowBodyText}>
                            {selectedMerchant?.credential.clientId}
                          </span>
                        </span>
                        <CopyIcon onClickAction={handleCopyClientId} />
                      </Box>
                    </ListItem>
                    <ListItem className={classes.merchantRowItem}>
                      {(newCredential || newMerchant) && (
                        <img src={secret_new} alt="New Secret Icon" />
                      )}
                      {!newCredential && !newMerchant && (
                        <img className={classes.placeholderImage} />
                      )}
                      <Box>
                        <div className={classes.rowLabelText}>
                          Client Secret
                        </div>
                      </Box>
                      <Box className={classes.rowContainer}>
                        <span ref={inputRef}>
                          <span className={classes.rowBodyText}>
                            {selectedMerchant?.credential.clientSecret}
                          </span>
                        </span>
                        {(newCredential || newMerchant) && (
                          <CopyIcon onClickAction={handleCopyClientSecret} />
                        )}
                        {!newCredential && props.loadingNewCredential && (
                          <div className={classes.newCredentiallLoading}>
                            <Loader
                              isLoading={
                                !newCredential && props.loadingNewCredential
                              }
                              children={undefined}
                            />
                          </div>
                        )}
                      </Box>
                    </ListItem>
                    <ListItem className={classes.merchantRowItem}>
                      {(newCredential || newMerchant) && (
                        <img src={secret_new} alt="New Secret Icon" />
                      )}
                      {!newCredential && !newMerchant && (
                        <img className={classes.placeholderImage} />
                      )}
                      <Box>
                        <div className={classes.rowLabelText}>
                          Secret Expiry
                        </div>
                      </Box>
                      <Box className={classes.rowContainer}>
                        <div className={classes.rowBodyText}>
                          <SecretExpiry
                            secretExpiryDate={
                              selectedMerchant?.credential.expiryDateTime!
                            }
                          />
                        </div>
                      </Box>
                    </ListItem>
                    {newMerchant && (
                      <Box>
                        <Alert className={classes.alertBox} severity="warning">
                          <b>IMPORTANT:</b> Please make a note of your client
                          secret now, as you will not be able to view it again
                          once you close this window. <br />
                          <br />
                          You can generate a new client secret at any time using
                          the 'Manage secret' button in the Merchant Site
                          Credentials section of this portal.
                        </Alert>
                      </Box>
                    )}
                    {newCredential && !newMerchant && (
                      <Box>
                        <Alert className={classes.alertBox} severity="warning">
                          <b>IMPORTANT:</b> Please make a note of your client
                          secret now, as you will not be able to view it again
                          once you close this window. <br />
                          <br />
                          Please update the secret in the merchant site POS
                          system as soon as possible.
                        </Alert>
                      </Box>
                    )}
                    {!newCredential && !newMerchant && isPOS && (
                      <Alert
                        className={classes.alertBox}
                        severity="info"
                        sx={{
                          paddingLeft: '3rem',
                          paddingRight: '3rem',
                        }}
                      >
                        You can generate a new client secret at any time by
                        returning to this screen.
                        <br />
                        <b>
                          Once a new secret has been generated, please update
                          the secret in the merchant site POS system.
                        </b>
                        <br />
                        <Button
                          variant="action"
                          onClick={generateNewCredential}
                          className={classes.actionButton}
                        >
                          <img
                            src={action_generate_new_secret}
                            className={classes.actionButtonIcon}
                            alt="Generate New Secret Button"
                          />
                          <span className={classes.actionButtonText}>
                            Generate new secret
                          </span>
                        </Button>
                      </Alert>
                    )}
                    <div className={classes.alertBox}>
                      <ErrorAlert
                        alertAction="a new secret could not be generated"
                        alertVisible={secretAlertVisible}
                      />
                    </div>
                  </DialogContent>
                </DialogContent>
              </List>
            </>
          )}
          <DialogActions className={classes.buttonContainer}>
            <Button
              onClick={handleClose}
              variant="primary"
              sx={{ width: '12.5rem' }}
            >
              Close
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default MerchantCredentialsDialog;
