import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import cssVars from 'css-vars-ponyfill';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import 'fontsource-roboto';

import { msalConfig } from './config/AuthConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

const msalAppInstance = new PublicClientApplication(msalConfig);

cssVars({
  onlyLegacy: true,
  shadowDOM: true,
  variables: {
    '--primary-white': '#FFFFFF',
    '--primary-black': '#000000',
    '--primary-purple': '#5F277E',
    '--secondary-purple': '#8342A7',
    '--background-purple': '#5B3084',
    '--primary-purple-text': '#500A78',
    '--dss-light-purple': '#FAF7FC',
    '--primary-yellow': '#FEE98E',
    '--secondary-yellow': '#E6B000',
    '--primary-dark-gray': '#222222',
    '--dss-dark-green': '#004052',
    '--dss-dark-green-text': '#005A70',
    '--dss-light-green': '#65C3B3',
    '--disabled-button-gray': '#A7A7A7',
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalAppInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
