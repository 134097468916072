import { Box, Button, Dialog, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import section_2_image from '../../assets/images/home_section_2_image.svg';
import section_3_image_1 from '../../assets/images/home_section_3_1.svg';
import section_3_image_2 from '../../assets/images/home_section_3_2.svg';
import section_3_image_3 from '../../assets/images/home_section_3_3.svg';
import section_3_image_4 from '../../assets/images/home_section_3_4.svg';
import section_3_image_5 from '../../assets/images/home_section_3_5.svg';
import { useNavigate } from 'react-router-dom';
import { ExpressInterestLocation } from '../../publicRouteConstants';
import './HomePage.css';
import { HomePageCard } from './HomePageCard';
import NewTabLink from '../Shared/NewTabLink';
import config from '../../config/config.json';
import IdleTimeoutDialog from '../Shared/IdleTimeoutDialog';

const HomePage = () => {
  const navigate = useNavigate();
  const [idleTimeoutOpen, setIdleTimeoutOpen] = React.useState(false);

  const goToLocation = (location: string) => {
    window.scrollTo(0, 0);
    navigate(location);
  };

  useEffect(() => {
    var getIfShowPrompt = localStorage.getItem('promptSignoutDialog');
    if (getIfShowPrompt) {
      setIdleTimeoutOpen(true);
    }
  }, []);

  const closeIdlePrompt = () => {
    setIdleTimeoutOpen(false);
    localStorage.removeItem('promptSignoutDialog');
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={idleTimeoutOpen}
        aria-describedby="scroll-dialog-description"
      >
        <IdleTimeoutDialog closeFunction={closeIdlePrompt} />
      </Dialog>
      {/* Section 1 */}
      <Box className="section_1_background gutter">
        <Typography className="homepage_h3 yellow" sx={{ mb: '1.25rem' }}>
          {'Product Level Blocking (PLB)'}
        </Typography>
        <Typography className="homepage_h1 yellow" sx={{ mb: '1.25rem' }}>
          {'Welcome to the PLB Portal'}
        </Typography>
        <Typography
          className="homepage_h5 white"
          sx={{ mb: '3.125rem', maxWidth: '59.375rem' }}
        >
          {
            'Here you can learn about Product Level Blocking (PLB), express your interest in joining the program or sign in to access specifications and resources.\n\nPLB is a welfare reform program that automatically prevents enhanced Income Management cards from being used to purchase restricted items (alcohol, gambling services, tobacco products, pornographic material or cash-like products).'
          }
        </Typography>
        <Button
          onClick={() => goToLocation(ExpressInterestLocation.path)}
          variant="yellow"
          className="section_1_button"
        >
          Express interest
        </Button>
      </Box>

      {/* Section 2 */}
      <Box className="section_2_background">
        <Box className="section_2_contentContainer">
          <Typography className="homepage_h2 black alignLeft">
            We’re here to help technology organisations implement Product Level
            Blocking (PLB) for merchants{' '}
            <span className="homepage_h2 purple alignLeft">
              and provide a smoother shopping experience.
            </span>
          </Typography>
          <Grid container>
            <Grid item xs={12} lg={7} className="section_2_container">
              <div className="section_2_divider" />
              <Typography className="homepage_h4 black alignLeft">
                On this site you will find information about PLB, an automated
                technology solution to help your merchant customers accept the{' '}
                <NewTabLink href={config.SA_EIM_SITE}>SmartCard</NewTabLink>
                .
                <br />
                <br />
                Product Level Blocking is an initiative of the government to
                support use of the SmartCard in the community.
              </Typography>
            </Grid>
            <Grid item xs={12} md={5} className="section_2_image_container">
              <img
                src={section_2_image}
                className="section_2_image"
                alt="Cashier checking out produce"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Section 3 */}

      <Box className="section_3 gutter">
        <Typography className="homepage_h2 black" sx={{ mb: '1.5625rem' }}>
          What is Product Level Blocking?
        </Typography>
        <Typography className="homepage_h5 black" sx={{ mb: '3.125rem' }}>
          Product Level Blocking is an automated process to ensure that a
          SmartCard is not able to
          <br /> purchase restricted items. Here’s an example of how it works:
        </Typography>

        <Grid
          container
          spacing={2}
          className="section_3_grid"
          justifyContent={'center'}
          sx={{ mb: '3.125rem' }}
        >
          <HomePageCard
            img={section_3_image_1}
            number="1"
            heading="Restricted item"
            body={
              <p>
                A customer goes to checkout with bread, fruit, milk and{' '}
                <strong>beer</strong> in their trolley.
              </p>
            }
          />
          <HomePageCard
            img={section_3_image_2}
            number="2"
            heading="Payment with a SmartCard"
            body={
              <p>
                The customer attempts to pay for these items with a SmartCard.
              </p>
            }
          />
          <HomePageCard
            img={section_3_image_3}
            number="3"
            heading="Payment cancelled"
            body={
              <p>
                The payment is declined automatically by the EFTPOS terminal and
                a message is displayed to the cardholder.
              </p>
            }
          />
          <HomePageCard
            img={section_3_image_4}
            number="4"
            heading="Operator notified"
            body={
              <p>
                The beer is highlighted on the POS screen, so the operator knows
                which item is restricted.
              </p>
            }
          />
          <HomePageCard
            img={section_3_image_5}
            number="5"
            heading="Customer decides"
            body={
              <p>
                The customer can pay for the beer with a different card or cash,
                or remove the beer from the sale.
              </p>
            }
          />
        </Grid>
      </Box>
      {/* Section 4 */}
      <Box>
        <Box className="section_4 gutter">
          <Box className="homePage_textBlock_contentContainer">
            <Typography className="homepage_h2 black" sx={{ mb: '1.25rem' }}>
              Point of Sale (POS) Providers can
              <span className="purple"> enable PLB</span>
            </Typography>
            <Typography
              className="homePage_textBlock_divider"
              sx={{ mb: '2.1875rem' }}
            />
            <Typography className="homepage_h3 black" sx={{ mb: '1.5625rem' }}>
              POS providers and resellers build and supply POS systems that
              allow merchants to process sales with their customers.
            </Typography>
            <Typography className="homepage_body2">
              {
                'As a POS provider / developer, you can leverage your existing POS solution to configure it for PLB.\n\nAs a POS reseller / support organisation, you can help your retail clients operate with PLB.\n\nA set of documents clearly explains the requirements of the PLB program and is available after you express interest and gain access to the PLB Portal.'
              }
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Section 5 */}
      <Box>
        <Box className="section_5 gutter">
          <Box className="homePage_textBlock_contentContainer">
            <Typography className="homepage_h2 black" sx={{ mb: '1.25rem' }}>
              Payment integrators and acquirers can
              <span className="purple"> enable PLB</span>
            </Typography>
            <Typography
              className="homePage_textBlock_divider"
              sx={{ mb: '2.1875rem' }}
            />
            <Typography className="homepage_h3 black" sx={{ mb: '1.5625rem' }}>
              Payment integrators and acquirers provide the technology for
              merchants to transact payments for their customers.
            </Typography>
            <Typography className="homepage_body2">
              {
                'As a payment integrator or acquirer you can offer PLB support to your POS provider partners to simplify operations for merchants wanting to accept the SmartCard for payment.\n\nA set of documents clearly explains the requirements of the PLB program and is available after you express interest and gain access to the PLB Portal.'
              }
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Section 6 */}
      <Box className="section_1_background gutter">
        <Typography className="homepage_h1 yellow" sx={{ mb: '1.5625rem' }}>
          Interested in PLB?
        </Typography>
        <Typography className="homepage_h5 white" sx={{ mb: '1.5625rem' }}>
          Please click below to express your interest in PLB
        </Typography>
        <Button
          onClick={() => goToLocation(ExpressInterestLocation.path)}
          variant="yellow"
        >
          Express interest
        </Button>
      </Box>
    </>
  );
};

export default HomePage;
