import React, { useContext, useEffect } from 'react';
import {
  Button,
  Paper,
  TableContainer,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  MenuItem,
  FormControl,
  Stack,
  Select,
  Dialog,
  SelectChangeEvent,
  Box,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import action_button_upload from '../../../assets/icons/action_button_upload.svg';
import { AuthContext } from '../../../context/AuthContext';
import DownloadDialog from '../DownloadDialog';
import { formatDateTime } from '../../../utils/FormatDateTime';
import { StakeholderDocumentRow } from '../../../models/stakeholderDocumentRow';
import { makeStyles } from 'tss-react/mui';
import './StakeholderDocumentTable.css';
import { IconActionButton } from '../IconActionButton';
import { Loader } from '../../../utils/Loader';

export interface StakeholderDocumentTableProps {
  data: StakeholderDocumentRow[];
  tableName: string;
  isLoading?: boolean;
  isAccordion?: boolean;
  canSeeUploader?: boolean;
  canUpload?: boolean;
  canDelete?: boolean;
  canSelectStakeholder?: boolean;
  selectedStakeholderIndex?: number;
  handleStakeholderChange?: (event: SelectChangeEvent) => void;
  stakeholderNames?: string[];
  deleteFunction?: (row: StakeholderDocumentRow) => void;
  uploadFunction?: () => void;
  fileCategory: string;
}

const StakeholderDocumentTable = (props: StakeholderDocumentTableProps) => {
  const {
    data,
    tableName,
    isLoading = true,
    canSeeUploader = true,
    canUpload = true,
    canDelete = true,
    isAccordion = false,
    canSelectStakeholder = false,
    selectedStakeholderIndex = 0,
    stakeholderNames = [''],
    handleStakeholderChange,
    deleteFunction,
    uploadFunction,
    fileCategory,
  } = props;
  const [accordionOpen, setAccordionOpen] = React.useState(true);
  const [initialLoad, setInitialLoad] = React.useState(true);
  const [downloadOpen, setDownloadOpen] = React.useState(false);
  const [downloadDetails, setDownloadDetails] = React.useState({
    fileName: '',
    fileContainer: '',
  });
  const authContextValue = useContext(AuthContext);

  const useStyles = makeStyles<{ containerPadding: string }>()((theme) => {
    return {
      header: {
        '& .MuiFormControl-root': {
          maxWidth: '17.625rem',
          marginLeft: '1rem !important',
        },
      },
      topContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: containerPadding,
      },
      tableRoot: {
        '& .MuiDataGrid-cell': {
          outline: 'none !important',
        },
      },
      columnHeaderTitle: {
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: '600',
        },
      },
    };
  });

  const [containerPadding, setContainerPadding] = React.useState<string>(
    '1.0625rem 1.5625rem 0.625rem',
  );
  useEffect(() => {
    if (authContextValue.isAdmin() && initialLoad) {
      setContainerPadding('1.4281rem 1.5625rem 1.3844rem');
    } else {
      setContainerPadding('1.0625rem 1.5625rem 0.625rem');
    }
  }, [authContextValue, initialLoad]);

  const { classes } = useStyles({ containerPadding: containerPadding });

  const triggerDelete = (row: StakeholderDocumentRow) => {
    if (deleteFunction !== undefined) {
      deleteFunction(row);
    }
  };

  const triggerUpload = () => {
    if (uploadFunction !== undefined) {
      uploadFunction();
    }
  };

  const Columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'FILE NAME',
      disableColumnMenu: true,
      flex: 1.5,
      headerClassName: classes.columnHeaderTitle,
    },
    {
      field: 'createdBy',
      headerName: 'UPLOADED BY',
      hide: !canSeeUploader,
      disableColumnMenu: true,
      flex: 1.5,
      headerClassName: classes.columnHeaderTitle,
    },
    {
      field: 'date',
      headerName: 'UPLOAD DATE/TIME',
      type: 'dateTime',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return '';
        }
        return formatDateTime(params.value);
      },
      flex: 1,
      headerClassName: classes.columnHeaderTitle,
    },
    {
      field: 'download',
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'right',
      flex: 0.75,
      renderCell: (params: GridRenderCellParams<StakeholderDocumentRow>) => (
        <Box sx={{ mr: '0.9375rem' }}>
          <IconActionButton
            variant="download"
            onClick={() => downloadDocument(params.row)}
          />

          {canDelete && (
            <IconActionButton
              variant="delete"
              onClick={() => triggerDelete(params.row)}
            />
          )}
        </Box>
      ),
    },
  ];

  const downloadDocument = (row: StakeholderDocumentRow | undefined) => {
    if (row !== undefined) {
      console.log(row);
      setDownloadDetails({ fileName: row.name, fileContainer: row.link });
      setDownloadOpen(true);
    }
  };

  const tableHeader = (
    <div className={classes.header}>
      <div className={classes.topContainer}>
        <div className="stakeholderDocumentTable_TableNameContainer">
          <Typography
            variant="h5"
            className={
              canSelectStakeholder
                ? 'stakeholderDocumentTable_SubheaderWithCaption'
                : 'stakeholderDocumentTable_Subheader'
            }
          >
            {initialLoad ? fileCategory : tableName}
          </Typography>
          {authContextValue.isAdmin() && initialLoad && (
            <p className="stakeholderDocumentTable_innerSubheading">
              {`No organisation selected. Select an organisation using the dropdown to view their ${fileCategory.toLowerCase()}.`}
            </p>
          )}
        </div>
        {canSelectStakeholder && !canUpload && (
          <FormControl className="stakeholderDocumentTable_dropdown" fullWidth>
            <Select
              className="stakeholderDocumentTable_select"
              value={stakeholderNames[selectedStakeholderIndex]}
              defaultValue="View another organisation"
              placeholder="View another organisation"
              label=""
              onChange={handleStakeholderChange}
              renderValue={() =>
                initialLoad ? 'Organisation' : 'View another organisation'
              }
              onClose={() => {
                setInitialLoad(false);
              }}
            >
              {stakeholderNames.map((stakeholder, id) => {
                return (
                  <MenuItem key={id} value={stakeholder}>
                    {stakeholder}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        {canUpload && !canSelectStakeholder && (
          <Button
            variant="action"
            color="primary"
            className="stakeholderDocumentTable_AddButton"
            onClick={() => triggerUpload()}
          >
            <img
              src={action_button_upload}
              className="documentTable_icon"
              alt="Upload Button"
            />
            <span className="stakeholderDocumentTable_DownloadText">
              Upload document
            </span>
          </Button>
        )}
        {canSelectStakeholder && canUpload && (
          <>
            <Button
              variant="action"
              color="primary"
              className="stakeholderDocumentTable_AddButton"
              style={{ height: '3.5rem' }}
              onClick={() => triggerUpload()}
            >
              <img
                src={action_button_upload}
                className="documentTable_icon"
                alt="Upload Button"
              />
              <span className="stakeholderDocumentTable_DownloadText">
                Upload document
              </span>
            </Button>
            <FormControl
              className="stakeholderDocumentTable_dropdown"
              fullWidth
            >
              <Select
                className="stakeholderDocumentTable_select"
                value={stakeholderNames[selectedStakeholderIndex]}
                defaultValue="View another organisation"
                placeholder="View another organisation"
                label=""
                onChange={handleStakeholderChange}
                renderValue={() =>
                  initialLoad ? 'Organisation' : 'View another organisation'
                }
                onClose={() => {
                  setInitialLoad(false);
                }}
              >
                {stakeholderNames.map((stakeholder, id) => {
                  return (
                    <MenuItem key={id} value={stakeholder}>
                      {stakeholder}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </>
        )}
      </div>
    </div>
  );

  const tableBody = (
    <>
      {!isLoading && (
        <TableContainer component={Paper} className={classes.tableRoot}>
          <DataGrid
            rows={data}
            columns={Columns}
            autoHeight
            hideFooter={true}
            rowHeight={84}
            headerHeight={64}
            sortingOrder={['desc', 'asc']}
            initialState={{
              sorting: {
                sortModel: [{ field: 'date', sort: 'desc' }],
              },
            }}
            classes={{
              columnHeader: 'stakeholderDocumentTable_header',
              columnSeparator: 'stakeholderDocumentTable_seperator',
              columnHeaders: 'stakeholderDocumentTable_header_background',
              row: 'stakeholderDocumentTable_row',
              cell: 'stakeholderDocumentTable_cell_padding',
              cellContent: 'stakeholderDocumentTable_cell_content',
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  color="#000000"
                  alignItems="center"
                  justifyContent="center"
                >
                  This section has no content yet.
                </Stack>
              ),
            }}
          />
        </TableContainer>
      )}
      {isLoading && <Loader isLoading={isLoading} children={undefined} />}
    </>
  );

  const accordionTable = (
    <Accordion
      expanded={accordionOpen}
      className="stakeholderDocumentTable_accordion_box"
    >
      <AccordionSummary
        classes={{
          root: 'stakeholderDocumentTable_accordion',
          content: 'stakeholderDocumentTable_accordion_root',
        }}
        onClick={() => setAccordionOpen(!accordionOpen)}
      >
        <Typography
          variant="h5"
          className={
            accordionOpen
              ? 'stakeholderDocumentTable_Subheader'
              : 'stakeholderDocumentTable_SubheaderCollapsed'
          }
        >
          {tableName}
        </Typography>
        {canUpload && (
          <Button
            variant="action"
            color="primary"
            className="stakeholderDocumentTable_AddButton"
            onClick={() => triggerUpload()}
          >
            <img
              src={action_button_upload}
              className="documentTable_icon"
              alt="Upload Button"
            />
            <span className="stakeholderDocumentTable_DownloadText">
              Upload document
            </span>
          </Button>
        )}
        <IconButton
          className={
            canUpload
              ? 'stakeholderDocumentTable_admin_closeIcon'
              : 'stakeholderDocumentTable_closeIcon'
          }
        >
          {accordionOpen ? '−' : '+'}
        </IconButton>
      </AccordionSummary>
      <AccordionDetails className="stakeholderDocumentTable_accordion_details">
        {authContextValue.isAdmin() && !initialLoad && tableBody}
      </AccordionDetails>
    </Accordion>
  );

  const standardTable = (
    <div className="stakeholderDocumentTable_top_box">
      {tableHeader}
      {(!authContextValue.isAdmin() || !initialLoad) && tableBody}
    </div>
  );

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={downloadOpen}
        aria-describedby="scroll-dialog-description"
      >
        <DownloadDialog
          downloadType="stakeholder"
          file={downloadDetails}
          closeDialog={() => setDownloadOpen(false)}
        />
      </Dialog>
      {!isAccordion && <>{standardTable}</>}
      {isAccordion && <>{accordionTable}</>}
    </>
  );
};

export default StakeholderDocumentTable;
