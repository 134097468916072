import './UploadDialog.css';
import {
  Button,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormGroup,
  FormControlLabel,
  SelectChangeEvent,
  Alert,
  Link,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import Dropzone from 'react-dropzone';
import React from 'react';
import POChip from '../POChip';
import PDChip from '../PDChip';
import PIAChip from '../PIAChip';
import { DocumentMeta } from '../../../models/documentmeta';
import ApiService from '../../../services/ApiService';
import { makeStyles } from 'tss-react/mui';
import step_1 from '../../../assets/icons/step_1.svg';
import step_2 from '../../../assets/icons/step_2.svg';
import action_button_upload from '../../../assets/icons/action_button_upload.svg';
import { fileValidation } from '../../../utils/StakeholderDocumentUtil';
import { Loader } from '../../../utils/Loader';
import config from '../../../config/config.json';

export interface UploadDialogProps {
  closeDialog(): void;
  confirmDialog(): void;
  fileCategory: string;
  fileContainer: string;
  userName: string;
  fileName: string;
  rowLink: string;
  saveFunction(): void;
  cancelFunction(): void;
}

const UploadDialog = (props: UploadDialogProps) => {
  const { closeDialog, userName, fileCategory, fileContainer, confirmDialog } =
    props;
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [newFileName, setFileName] = React.useState<string | null>(null);
  const [newFileType, setFileType] = React.useState<string>('');
  const [newFileContent, setFileContent] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string[]>([]);
  const [initialUserError, setInitialUserError] =
    React.useState<boolean>(false);
  const [timedOut, setTimedOut] = React.useState<boolean>(false);
  const [fileTypeError, setFileTypeError] = React.useState<boolean>(false);
  const [noFileExtensionError, setNofileExtensionError] =
    React.useState<boolean>(false);
  const [zipFileError, setZipFileError] = React.useState<boolean>(false);
  const [zipFileErrorMessage, setZipFileErrorMessage] =
    React.useState<string>('');
  const [genericError, setGenericError] = React.useState<boolean>(false);
  const [users, setUsers] = React.useState<string[]>([]);

  const useStyles = makeStyles()(() => {
    return {
      root: {
        '& .MuiDialogContent-root': {
          display: 'flex',
          flexDirection: 'column',
          flex: 'none',
          minHeight: '16.875rem',
          justifyContent: 'space-between',
          border: '0.0625rem solid rgba(0,0,0,0.12)',
        },
        '& .MuiDialogActions-root': {
          justifyContent: 'space-evenly',
          paddingTop: '2.1875rem',
          paddingBottom: '2.5rem',
        },
        '& .MuiAlert-root': {
          width: '100%',
          font: 'normal normal normal 0.8125rem/1.5rem Open Sans',
          letterSpacing: '0.0075rem',
          color: '#8A1010',
          opacity: '1',
          maxHeight: '6.25rem',
        },
        '& .MuiAlert-message': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '0rem',
          '& p': {
            margin: '0rem',
          },
        },
        '& .MuiFormGroup-root': {
          paddingBottom: '3.5rem',
        },
        '& img': {
          paddingRight: '0.625rem',
        },
        '& .MuiTypography-root': {
          textAlign: 'left',
        },
        '& .MuiDialogContent-root:nth-of-type(1)': {
          padding: '0rem',
          flex: '1 1 0',
        },
      },
      uploadFileAlert: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '0.5rem',
        padding: '0rem 0rem 0rem',
        background: 'none',
        '& p': {
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          font: 'normal normal normal 0.75rem/1.0625rem Open Sans',
          color: '#606060',
          backgroundColor: '#F8F8F8',
          padding: '0.25rem 1rem',
          overflowWrap: 'anywhere',
        },
      },
      errorAlertPadding: {
        padding: '0.625rem 1rem 0.75rem',
        marginBottom: '0.8125rem',
        whiteSpace: 'break-spaces',
      },
      noPadding: {
        padding: '0',
      },
    };
  });

  const { classes } = useStyles();
  const isReadyToUpload = () => {
    if (
      newFileName !== null &&
      errorMessage.length === 0 &&
      users.length !== 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onDrop = async (acceptedFiles: any[]) => {
    let availableFiles: string[] = [];
    await ApiService.getDocuments(fileContainer).then((x) => {
      let data = x.data;
      for (let document of data) {
        availableFiles.push(document.fileName);
      }
    });

    setTimedOut(false);
    setFileTypeError(false);
    setNofileExtensionError(false);
    setZipFileError(false);
    setZipFileErrorMessage('');
    setGenericError(false);

    let fileName = acceptedFiles[0].name;
    let fileType = acceptedFiles[0].type;
    let fileSize = acceptedFiles[0].size;

    // If Dropzone fails to detect file type, attempt to attain extension from file name.
    if (fileType === '') {
      var fileNameContent = fileName.split('.');
      if (fileNameContent.length > 1) {
        var fileNameExtension = fileNameContent[fileNameContent.length - 1];
        if (fileNameExtension !== null) {
          fileType = fileNameExtension;
        }
      }
    }

    let validationErrorMessages = fileValidation(
      'Generic Document',
      availableFiles,
      fileName,
      fileType,
      fileSize,
      undefined,
    );
    setErrorMessage(validationErrorMessages);

    setFileName(fileName);
    setFileType(fileType);
    let reader = new FileReader();
    reader.onload = function (e) {
      if (e.target !== null) {
        let contents = e.target.result as string;
        setFileContent(contents);
      }
    };
    reader.readAsDataURL(acceptedFiles[0]);
  };

  const uploadFile = () => {
    setIsUploading(true);
    let usersString = users.toString();
    const uploadDoc: DocumentMeta = {
      fileName: newFileName as string,
      fileExtension: newFileType.split('/')[1],
      data: newFileContent.split(',')[1],
      containerName: fileContainer,
      createdBy: userName,
      category: fileCategory,
      userType: usersString,
      purpose: 'Portal Upload',
      lastModified: new Date().toJSON(),
      createdOn: new Date().toJSON(),
    };
    console.log(uploadDoc);
    let getExtension = uploadDoc.fileName.split('.');
    if (getExtension.length === 1) {
      setIsUploading(false);
      setNofileExtensionError(true);
      return;
    }

    let fileExtension = getExtension[getExtension.length - 1];
    if (fileExtension !== null) {
      let getFileWhitelist = `${process.env.REACT_APP_FILE_EXTENSION_WHITELIST}`;
      let fileWhitelist = getFileWhitelist.split(',');
      if (!fileWhitelist.includes(fileExtension.toLowerCase())) {
        setIsUploading(false);
        setFileTypeError(true);
        return;
      }
    }

    ApiService.postDocument(fileContainer, uploadDoc)
      .then(() => {
        confirmDialog();
      })
      .catch((e) => {
        setIsUploading(false);
        handleUploadError(e);
      });
  };

  // if there's a response, check if it's the file type error
  // other error responses will display the generic error
  // if there's no response, but there's a request, it means it's timed out
  // catch all if the error doesn't contain a request or response, provide the generic error
  const handleUploadError = (e: any) => {
    if (e.response) {
      if (e.response.status === 400) {
        let desc = e.response.data.Detail.Reasons[0].Description as string;
        if (desc.includes('File type is not supported')) {
          setFileTypeError(true);
        } else if (
          desc.includes('Unsupported file types detected within zip file')
        ) {
          setZipFileError(true);
          setZipFileErrorMessage(desc);
        } else {
          setGenericError(true);
        }
      } else {
        setGenericError(true);
      }
    } else if (e.request) {
      setTimedOut(true);
    } else {
      setGenericError(true);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setInitialUserError(true);
    const index = users.indexOf(event.target.value);
    if (index === -1) {
      setUsers([...users, event.target.value]);
    } else {
      setUsers(users.filter((user: any) => user !== event.target.value));
    }
  };

  return (
    <div className={classes.root}>
      <DialogTitle className="uploadDialog_Header">
        <div className="uploadDialog_CloseContainer">
          <IconButton
            disabled={isUploading}
            className="uploadDialog_CloseIcon"
            onClick={() => closeDialog()}
          >
            <CancelIcon />
          </IconButton>
        </div>
        <p className="uploadDialog_Text">Upload document</p>
      </DialogTitle>
      <div className="uploadDialog_flexRow">
        {!isUploading && (
          <DialogContent
            dividers={true}
            className="uploadDialog_container, uploadDialog_flexboxItem"
          >
            <Dropzone onDrop={(e) => onDrop(e)} multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="uploadDialog_Dropzone">
                  <input {...getInputProps()} />
                  <div className="uploadDialog_dialogText">
                    <img src={step_1} alt="Step 1" />
                    <p>Choose file to upload</p>
                  </div>
                  <img
                    className="uploadDialog_uploadIcon"
                    src={action_button_upload}
                    alt="Upload button"
                  />
                  {newFileName !== null && (
                    <Alert className={classes.uploadFileAlert} icon={false}>
                      <p>{newFileName}</p>
                    </Alert>
                  )}
                  <p
                    id="scroll-dialog-description"
                    className="uploadDialog_smallText"
                  >
                    {`Drop a single file to ${
                      newFileName === null ? 'select' : 'replace'
                    }\nOr `}
                    <span className="uploadDialog_highlight">browse</span>
                  </p>
                </div>
              )}
            </Dropzone>
          </DialogContent>
        )}
        {/* Uploading icon */}
        {isUploading && <Loader isLoading={isUploading} children={undefined} />}

        {/* Visibility Component */}
        {!isUploading && (
          <DialogContent
            dividers={true}
            className="uploadDialog_container, uploadDialog_flexboxItem"
          >
            <div className="uploadDialog_dialogTextAlign">
              <img src={step_2} alt="Step 2" />
              <p>Choose visibility for the new file</p>
            </div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value="POSDeveloper"
                    color="default"
                    aria-label="Checkbox POS Developer"
                    checked={users.includes('POSDeveloper')}
                    onChange={handleChange}
                  />
                }
                label={
                  <div>
                    <span className="uploadDialog_checkBoxText">
                      POS Developer
                    </span>
                    <PDChip />
                  </div>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="POSOperations"
                    color="default"
                    aria-label="Checkbox POS Operations"
                    checked={users.includes('POSOperations')}
                    onChange={handleChange}
                  />
                }
                label={
                  <div>
                    <span className="uploadDialog_checkBoxText">
                      POS Operations
                    </span>
                    <POChip />
                  </div>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="AcquirerPaymentIntegrator"
                    color="default"
                    aria-label="Checkbox Payment Integrator/Acquirer"
                    checked={users.includes('AcquirerPaymentIntegrator')}
                    onChange={handleChange}
                  />
                }
                label={
                  <div>
                    <span className="uploadDialog_checkBoxText">
                      Payment Integrator/Acquirer
                    </span>
                    <PIAChip />
                  </div>
                }
              />
            </FormGroup>
          </DialogContent>
        )}
      </div>
      <DialogActions
        className="uploadDialog_ButtonContainer"
        disableSpacing={true}
      >
        {/* Alert for file */}
        {errorMessage.length !== 0 && (
          <Alert className={classes.errorAlertPadding} severity="error">
            {errorMessage.map((message) => (
              <p>{message}</p>
            ))}
          </Alert>
        )}
        {/* Alert for usertype */}
        {initialUserError && users.length === 0 && (
          <Alert className={classes.errorAlertPadding} severity="error">
            <p>Please select at least one user type to see this document.</p>
          </Alert>
        )}
        {noFileExtensionError && (
          <Alert className={classes.errorAlertPadding} severity="error">
            <p>
              Files without a file extension are not allowed. Please contact
              Services Australia at{' '}
              <Link href={'mailto:' + config.SERVICES_AUS_EMAIL}>
                {config.SERVICES_AUS_EMAIL}
              </Link>{' '}
              if you need to submit this file.
            </p>
          </Alert>
        )}
        {fileTypeError && (
          <Alert className={classes.errorAlertPadding} severity="error">
            <p>
              File type is not supported. File cannot be uploaded. Please
              contact Services Australia at{' '}
              <Link href={'mailto:' + config.SERVICES_AUS_EMAIL}>
                {config.SERVICES_AUS_EMAIL}
              </Link>{' '}
              if you need to submit this file.
            </p>
          </Alert>
        )}
        {zipFileError && (
          <Alert className={classes.errorAlertPadding} severity="error">
            <p>
              {zipFileErrorMessage + ' '}
              Please contact Services Australia at{' '}
              <Link href={'mailto:' + config.SERVICES_AUS_EMAIL}>
                {config.SERVICES_AUS_EMAIL}
              </Link>{' '}
              if you need to submit this file.
            </p>
          </Alert>
        )}
        {genericError && (
          <Alert className={classes.errorAlertPadding} severity="error">
            <p>
              An error occurred, and your file could not be uploaded at this
              time. Please try again. If this error continues to occur, please
              contact PLB Portal administrators at{' '}
              <Link href={'mailto:' + config.DXC_EMAIL}>
                {config.DXC_EMAIL}
              </Link>
              .
            </p>
          </Alert>
        )}
        {timedOut && (
          <Alert className={classes.errorAlertPadding} severity="error">
            <p>
              File upload timed out. Upload time cannot exceed 220 seconds.
              Please contact PLB portal administrators at{' '}
              <Link href={'mailto:' + config.DXC_EMAIL}>
                {config.DXC_EMAIL}
              </Link>{' '}
              if you continue to have trouble uploading your file within the
              time limit.
            </p>
          </Alert>
        )}
        <Button
          disabled={
            !isReadyToUpload() ||
            isUploading ||
            timedOut ||
            genericError ||
            fileTypeError ||
            zipFileError ||
            noFileExtensionError
          }
          variant="primary"
          onClick={() => uploadFile()}
          className="uploadDialog_uploadButton"
        >
          Upload
        </Button>
      </DialogActions>
    </div>
  );
};

export default UploadDialog;
