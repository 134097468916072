import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Paper,
  Stack,
  TableContainer,
  Typography,
  Box,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import './FactsheetTable.css';
import ReactGA from 'react-ga4';
import { userType } from '../../../models/mainnav';
import { IconActionButton } from '../IconActionButton';
import { makeStyles } from 'tss-react/mui';
import { FactsheetRow } from '../../../models/FactsheetRow';

const useStyles = makeStyles()((theme) => {
  return {
    tableRoot: {
      '& .MuiDataGrid-cell': {
        outline: 'none !important',
      },
    },
  };
});

export interface FactsheetTableProps {
  data: FactsheetRow[];
  currentUserType: userType | null | undefined;
}

const FactsheetTable = (props: FactsheetTableProps) => {
  const { classes } = useStyles();
  const [accordionOpen, setAccordionOpen] = React.useState(false);

  const Columns: GridColDef[] = [
    {
      field: 'fileName',
      headerName: 'FILE NAME',
      disableColumnMenu: true,
      flex: 2,
    },
    {
      field: 'publishedDescription',
      headerName: 'PUBLISHED',
      disableColumnMenu: true,
      flex: 2,
    },
    {
      field: 'download',
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'right',
      flex: 1,
      renderCell: (params: GridRenderCellParams<FactsheetRow>) => (
        <Box sx={{ mr: '0.9375rem' }}>
          <IconActionButton
            variant="open"
            onClick={() => downloadDocument(params.row)}
          />
        </Box>
      ),
    },
  ];

  const downloadDocument = (row: FactsheetRow | undefined) => {
    ReactGA.event({
      category: 'Open Factsheet',
      action: 'Open Factsheet: ' + row?.fileName,
      label: 'Opened Factsheet: ' + row?.fileName,
    });
    if (row !== undefined) {
      window.open(row.storageUrl);
    }
  };

  const tableBody = (
    <TableContainer component={Paper} className={classes.tableRoot}>
      <DataGrid
        rows={props.data}
        columns={Columns}
        autoHeight
        hideFooter={true}
        rowHeight={84}
        headerHeight={64}
        sortingOrder={['desc', 'asc']}
        initialState={{
          sorting: {
            sortModel: [{ field: 'fileName', sort: 'asc' }],
          },
        }}
        classes={{
          columnHeader: 'factsheetTable_header',
          columnSeparator: 'factsheetTable_seperator',
          columnHeaders: 'factsheetTable_header_background',
          row: 'factsheetTable_row',
          cell: 'factsheetTable_cell_padding',
          cellContent: 'documentTable_cell_content',
        }}
        components={{
          NoRowsOverlay: () => (
            <Stack
              height="100%"
              color="#000000"
              alignItems="center"
              justifyContent="center"
            >
              This section has no content yet.
            </Stack>
          ),
        }}
      />
    </TableContainer>
  );

  return (
    <Accordion
      expanded={accordionOpen}
      className="factsheetTable_accordion_box"
    >
      <AccordionSummary
        classes={{
          root: 'factsheetTable_accordion',
          content: accordionOpen
            ? 'factsheetTable_accordion_rootExpanded'
            : 'factsheetTable_accordion_root',
        }}
        onClick={() => setAccordionOpen(!accordionOpen)}
      >
        <Typography
          variant="h5"
          className={
            accordionOpen
              ? 'factsheetTable_Subheader'
              : 'factsheetTable_SubheaderCollapsed'
          }
        >
          Factsheets
        </Typography>
        <IconButton className={'factsheetTable_closeIcon'}>
          {accordionOpen ? (
            <KeyboardArrowUpIcon className="factsheetTable_closeIconImg" />
          ) : (
            <KeyboardArrowDownIcon className="factsheetTable_closeIconImg" />
          )}
        </IconButton>
      </AccordionSummary>
      <AccordionDetails className="factsheetTable_accordion_details">
        {tableBody}
      </AccordionDetails>
    </Accordion>
  );
};

export default FactsheetTable;
