import React, { useContext, useEffect, useState } from 'react';
import AccordionGroup from '../Shared/AccordionGroup';
import PageHeader from '../Shared/PageHeader';
import './FAQs.css';
import NewTabLink from '../Shared/NewTabLink';
import config from '../../config/config.json';
import merchant from '../../config/MerchantPortalURL.json';
import { Box, Button } from '@mui/material';
import '../../css/App.css';
import ModalLink from '../Shared/ModalLink';
import { CashEquivalentsDialog } from './CashEquivalentsDialog';
import { ProviderListDialog } from './ProviderListDialog';
import { FAQitems } from './FAQList';
import ReactDOMServer from 'react-dom/server';
import { elementItems } from './FAQTags';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { AuthContext } from '../../context/AuthContext';
import AuthService from '../../services/AuthService';
import { tokenRequest } from '../../config/AuthConfig';
import PublicService from '../../services/PublicService';
import { PageTitles } from '../../utils/PageTitles';
import { Helmet } from 'react-helmet';

export interface FAQItem {
  index?: number;
  heading: string;
  body: any;
  expanded?: boolean;
}

export interface FAQHead {
  faqIndex?: number;
  faqHeader: string;
  expanded?: boolean;
  faqList: FAQItem[];
}

export interface ElementItems {
  label: string;
  path: string;
  isLink?: boolean;
  isExternalLink?: boolean;
  isFactSheet?: boolean;
  isDialog?: boolean;
  dialog?: string;
}

FAQitems.forEach((item, index) => {
  item.faqIndex = index + 1;
  item.expanded = false;
  item.faqList.forEach((subItem, subIndex) => {
    subItem.index = subIndex + 1;
    subItem.expanded = false;
  });
});

const useStyles = makeStyles()((theme) => {
  return {
    faq_search_result_msg: {
      color: '#222222',
      textAlign: 'left',
      font: 'normal normal bold 1rem/1.625rem Open Sans',
      letterSpacing: '0.0125rem',
    },
    faq_link_text: {
      textAlign: 'left',
      textDecoration: 'none',
      borderBottom: '0.0625rem solid var(--primary-purple)',
      font: 'normal normal normal 1.125rem/2rem Open Sans',
      letterSpacing: '0rem',
      color: 'var(--primary-purple)',
      cursor: 'pointer',
    },
    faq_new_line: {
      margin: '1rem',
      lineHeight: '0.4rem',
    },
    ListItem: {
      display: 'list-item',
      marginLeft: '1.4375rem',
      paddingLeft: '0.25rem',
    },
    SubListItem: {
      display: 'list-item',
      listStyle: 'circle',
      marginLeft: '3.75rem',
      paddingLeft: '0.25rem',
    },
  };
});

const FAQs = () => {
  const authContextValue = useContext(AuthContext);
  const [openCashDialog, setOpenCashDialog] = useState(false);
  const [openProviderDialog, setOpenProviderDialog] = useState(false);
  const [restrictedItemsFactsheetUrl, setRestrictedItemsFactsheetUrl] =
    useState('');
  const [definitionsFactsheetUrl, setDefinitionsItemsFactsheetUrl] =
    useState('');
  const [faqItems, setFaqItems] = useState(() => getFAQ());
  const [searchValue, setSearchValue] = useState(() => getSearchTerm());
  const [searchResultLength, setSearchResultLength] = useState(() =>
    getSearchResultLength(),
  );
  const [globalExpand, setGlobalExpand] = useState(() => getGlobalExpand());

  const { classes } = useStyles();
  const navigate = useNavigate();
  const splitters: string[] = [
    'sub-dotpoint',
    'dotpoint',
    'email',
    'i',
    'newLine',
  ];

  const handleOpenCashDialog = () => {
    setOpenCashDialog(true);
  };

  const handleCloseCashDialog = () => {
    setOpenCashDialog(false);
  };

  const handleOpenProviderDialog = () => {
    setOpenProviderDialog(true);
  };

  const handleCloseProviderDialog = () => {
    setOpenProviderDialog(false);
  };

  const goToLocation = (location: string, isExternalLink?: boolean) => {
    if (isExternalLink === true) {
      window.open(location, '_blank');
    } else {
      localStorage.setItem('storeDataFlag', 'true');
      localStorage.setItem('storedSearchTerm', searchValue);
      localStorage.setItem(
        'storedSearchResultLength',
        searchResultLength ? searchResultLength : '',
      );
      localStorage.setItem('storedFAQ', JSON.stringify(faqItems));
      localStorage.setItem('storedGlobalExpand', globalExpand.toString());
      localStorage.setItem('contentYOffset', String(window.pageYOffset));
      window.scrollTo(0, 0);
      navigate(location);
    }
  };

  const getFactsheetUrl = (factsheetName: string) => {
    PublicService.getFactsheet(factsheetName)
      .then((x) => {
        if (factsheetName === 'Restricted_Items') {
          setRestrictedItemsFactsheetUrl(x.data.value[0].StorageUrl);
        } else if (factsheetName === 'Definitions') {
          setDefinitionsItemsFactsheetUrl(x.data.value[0].StorageUrl);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function getFAQ() {
    let storedFAQString = localStorage.getItem('storedFAQ');
    if (storedFAQString) {
      let storedFAQ: FAQHead[] = JSON.parse(storedFAQString);
      if (storedFAQ) {
        return storedFAQ;
      }
    }
    return FAQitems;
  }

  function getSearchTerm() {
    let storedSearchTermString = localStorage.getItem('storedSearchTerm');
    return storedSearchTermString ? storedSearchTermString : '';
  }

  function getSearchResultLength() {
    let storedSearchResultLength = localStorage.getItem(
      'storedSearchResultLength',
    );
    return storedSearchResultLength ? storedSearchResultLength : null;
  }

  function getGlobalExpand() {
    let storedGlobalExpand = localStorage.getItem('storedGlobalExpand');
    return storedGlobalExpand ? storedGlobalExpand : false;
  }

  const handleSearch = (value: string) => {
    setGlobalExpand(false);
    setSearchValue(value);
  };

  const handleToggle = (status: boolean) => {
    if (searchValue.length > 0) {
      let updatedAccGroups = faqItems.map((item) => {
        let updatedAccordions = item.faqList.map((sub) => {
          return { ...sub, expanded: status };
        });

        return {
          ...item,
          expanded: true,
          faqList: updatedAccordions,
        };
      });

      setFaqItems(updatedAccGroups);
    } else {
      let updatedAccGroups = faqItems.map((item) => {
        let updatedAccordions;
        updatedAccordions = item.faqList.map((sub) => {
          return { ...sub, expanded: status };
        });
        return {
          ...item,
          expanded: status,
          faqList: updatedAccordions,
        };
      });

      setFaqItems(updatedAccGroups);
    }
    setGlobalExpand(status);
  };

  const handleFAQChange = (faqIndex?: number) => {
    let updatedAccGroups = faqItems.map((item) => {
      if (item.faqIndex === faqIndex) {
        const updatedAccordions = item.faqList.map((sub) => {
          return { ...sub, expanded: false };
        });
        return {
          ...item,
          expanded: !item.expanded,
          faqList: updatedAccordions,
        };
      }
      return item;
    });
    setFaqItems(updatedAccGroups);
  };

  const handleFAQSubItemChange = (faqIndex?: number, faqSubIndex?: number) => {
    let updatedAccGroups = faqItems.map((item) => {
      if (item.faqIndex === faqIndex) {
        let updatedAccordions = item.faqList.map((subItem) => {
          if (subItem.index === faqSubIndex) {
            return { ...subItem, expanded: !subItem.expanded };
          }
          return subItem;
        });
        return { ...item, faqList: updatedAccordions };
      }
      return item;
    });
    setFaqItems(updatedAccGroups);
  };

  const renderFAQ = (faqList: FAQItem[]) => {
    return faqList.map((item) => ({ ...item, body: renderFAQText(item.body) }));
  };

  const renderFAQText = (text: string) => {
    return renderFAQTextEle(text, splitters[0], 0);
  };

  const performAuthentication = async () => {
    await AuthService.getInstance().getToken(tokenRequest);
  };

  const renderFAQTextEle = (
    text: string,
    splitter: string,
    ind: number,
  ): any[] => {
    switch (splitter) {
      case 'sub-dotpoint':
        return text
          .split(/<sub-dotpoint>(.*?)<\/sub-dotpoint>/g)
          .map((line, index) => {
            if (index % 2) {
              return (
                <span className={classes.SubListItem} key={'sublist' + index}>
                  {replaceTags(line)}
                </span>
              );
            } else {
              return renderNextTag(line, ind);
            }
          });
      case 'dotpoint':
        return text.split(/<dotpoint>(.*?)<\/dotpoint>/g).map((line, index) => {
          if (index % 2) {
            return (
              <span className={classes.ListItem} key={'list' + index}>
                {replaceTags(line)}
              </span>
            );
          } else {
            return renderNextTag(line, ind);
          }
        });

      case 'email':
        return text.split(/<email>(.+?)<\/email>/g).map((line, index) => {
          if (index % 2) {
            return (
              <a
                className={classes.faq_link_text}
                key={'email' + index}
                href={'mailto:' + config.SERVICES_AUS_EMAIL}
              >
                {config.SERVICES_AUS_EMAIL}
              </a>
            );
          } else {
            return renderNextTag(line, ind);
          }
        });
      case 'i':
        return text.split(/<i>(.+?)<\/i>/g).map((line, index) => {
          if (index % 2) {
            return <i key={'i' + index}>{replaceTags(line)}</i>;
          } else {
            return renderNextTag(line, ind);
          }
        });
      case 'newLine':
        return text.split(/(\n{2,})/g).map((line, index) => {
          if (index % 2) {
            return (
              <p key={'newLine' + index} className={classes.faq_new_line}>
                {replaceTags(line)}
              </p>
            );
          } else {
            return renderNextTag(line, ind);
          }
        });
      default:
        return replaceTags(text);
    }
  };

  const renderNextTag = (line: string, ind: number): any[] => {
    if (splitters.length - 1 > ind) {
      return renderFAQTextEle(line, splitters[ind + 1], ind + 1);
    } else {
      return replaceTags(line);
    }
  };

  const replaceTags = (sourceText: string) => {
    return sourceText
      .split(/<clickable>(.*?)<\/clickable>/g)
      .map((text, index) => {
        const result = elementItems.find((i) => i.label === text);
        if (result) {
          if (result.isFactSheet === true) {
            if (result.label === 'restricted items factsheet') {
              return (
                <span key={'clickable' + index}>
                  <span
                    tabIndex={0}
                    className={classes.faq_link_text}
                    onKeyPress={(e) =>
                      e.key === 'Enter' &&
                      goToLocation(restrictedItemsFactsheetUrl)
                    }
                    onClick={() =>
                      goToLocation(
                        restrictedItemsFactsheetUrl,
                        result.isExternalLink,
                      )
                    }
                  >
                    {text}
                  </span>
                </span>
              );
            } else if (result.label === 'list of terms') {
              return (
                <span key={'clickable' + index}>
                  <span
                    tabIndex={0}
                    className={classes.faq_link_text}
                    onKeyPress={(e) =>
                      e.key === 'Enter' && goToLocation(definitionsFactsheetUrl)
                    }
                    onClick={() =>
                      goToLocation(
                        definitionsFactsheetUrl,
                        result.isExternalLink,
                      )
                    }
                  >
                    {text}
                  </span>
                </span>
              );
            }
          } else if (result.isLink === true) {
            return (
              <span key={'clickable' + index}>
                <span
                  tabIndex={0}
                  className={classes.faq_link_text}
                  onKeyPress={(e) =>
                    e.key === 'Enter' && goToLocation(result.path)
                  }
                  onClick={() =>
                    goToLocation(result.path, result.isExternalLink)
                  }
                >
                  {text}
                </span>
              </span>
            );
          } else if (result.isDialog === true && result.dialog) {
            if (result.dialog === 'CashEquivalentsDialog') {
              return (
                <ModalLink
                  open={openCashDialog}
                  handleOpen={handleOpenCashDialog}
                  handleClose={handleCloseCashDialog}
                  dialog={
                    <CashEquivalentsDialog
                      closeFunction={handleCloseCashDialog}
                    />
                  }
                >
                  {text}
                </ModalLink>
              );
            } else if (result.dialog === 'ProviderListDialog') {
              return (
                <ModalLink
                  open={openProviderDialog}
                  handleOpen={handleOpenProviderDialog}
                  handleClose={handleCloseProviderDialog}
                  dialog={
                    <ProviderListDialog
                      closeFunction={handleCloseProviderDialog}
                    />
                  }
                >
                  {text}{' '}
                </ModalLink>
              );
            }
          } else {
            return (
              <span
                role="link"
                tabIndex={0}
                className={classes.faq_link_text}
                key={'clickable' + index}
                onKeyPress={(e) =>
                  e.key === 'Enter' && goToLocation(result.path)
                }
                onClick={() => goToLocation(result.path, result.isExternalLink)}
              >
                {text}
              </span>
            );
          }
        }
        return text;
      });
  };

  const resetStoredData = () => {
    localStorage.removeItem('storeDataFlag');
    localStorage.removeItem('storedSearchTerm');
    localStorage.removeItem('storedSearchResultLength');
    localStorage.removeItem('storedGlobalExpand');
    localStorage.removeItem('storedFAQ');
    localStorage.removeItem('contentYOffset');
  };

  useEffect(() => {
    if (restrictedItemsFactsheetUrl === '') {
      getFactsheetUrl('Restricted_Items');
    }
    if (definitionsFactsheetUrl === '') {
      getFactsheetUrl('Definitions');
    }
  });

  useEffect(() => {
    performAuthentication();
    let searchResults: FAQHead[] = [];
    const storedStatus = localStorage.getItem('storeDataFlag');

    if (storedStatus !== 'true') {
      if (searchValue.length > 0) {
        const resItems = FAQitems.reduce((resArray: FAQItem[], f) => {
          const filteredFAQ = f.faqList.filter(
            (x) =>
              x.heading.toLowerCase().includes(searchValue.toLowerCase()) ||
              ReactDOMServer.renderToStaticMarkup(x.body)
                .toLowerCase()
                .includes(searchValue.toLowerCase()),
          );
          resArray.push(...filteredFAQ);
          return resArray;
        }, []);
        setSearchResultLength(resItems.length.toString());
        setGlobalExpand(getGlobalExpand());

        const formattedResSet = resItems.map((item, ind) => {
          return {
            ...item,
            index: ind + 1,
            expanded: false,
          };
        });

        searchResults.push({
          faqHeader: '',
          faqIndex: 1,
          faqList: formattedResSet,
          expanded: true,
        });
      } else {
        setSearchResultLength(null);
        setGlobalExpand(false);
        searchResults = [...FAQitems];
      }

      setFaqItems(searchResults);
    } else {
      const storedcontentYOffset = localStorage.getItem('contentYOffset');
      if (storedcontentYOffset) {
        const scrollTimeout = setTimeout(() => {
          console.log(storedcontentYOffset);
          window.scrollTo({
            behavior: 'smooth',
            top: storedcontentYOffset ? Number(storedcontentYOffset) : 0,
            left: 0,
          });
          resetStoredData();
        }, 100);
        return () => clearTimeout(scrollTimeout);
      }
    }
  }, [searchValue]);

  return (
    <>
      <Helmet>
        <title>{PageTitles.FAQ}</title>
      </Helmet>
      <PageHeader
        headerTitle="Frequently asked questions (FAQs)"
        hasSearchBar={true}
        searchValue={searchValue}
        setSearchValue={handleSearch}
        searchLabel="Search frequently asked questions"
        isAdmin={authContextValue.isAdmin()}
      >
        {
          <>
            To see the merchant FAQs, please visit the{' '}
            <NewTabLink href={merchant.FAQ} className="inheritColor">
              PLB Merchant Portal
            </NewTabLink>
            .
          </>
        }
      </PageHeader>
      <Box className="faq_background_box">
        <Button
          variant="secondary"
          className="faq_toggleAllButton"
          onClick={() => handleToggle(!globalExpand)}
          disabled={searchResultLength === '0'}
        >
          {globalExpand ? <HorizontalRuleIcon /> : <AddIcon />}
          &nbsp;
          {globalExpand ? 'Contract all FAQs' : 'Expand all FAQs'}
        </Button>

        {searchValue && (
          <span className={classes.faq_search_result_msg}>
            {'Showing '} <u>{searchResultLength}</u>{' '}
            {'  results for "' + searchValue + '"'}
          </span>
        )}

        {faqItems.map((item) => (
          <AccordionGroup
            groupIndex={item.faqIndex}
            groupHeading={item.faqHeader}
            expanded={item.expanded}
            accordionProps={renderFAQ(item.faqList)}
            handleAGChange={searchValue ? undefined : handleFAQChange}
            handleAChange={handleFAQSubItemChange}
          />
        ))}
      </Box>
    </>
  );
};

export default FAQs;
