import { AccountInfo } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import AuthService from '../services/AuthService';
import { userType } from '../models/mainnav';

interface IAuthContext {
  isAuthenticated: boolean;
  currentUserType: userType | null | undefined;
  currentUserStakeholderName: string | null | undefined;
  user: AccountInfo | null;
  login: () => void;
  logout: () => void;
  isAdmin: () => boolean;
  isPOS: () => boolean;
  isDXCAdmin: () => boolean;
}

const defaultValue: IAuthContext = {
  isAuthenticated: false,
  currentUserType: null,
  currentUserStakeholderName: null,
  user: null,
  login: () => {
    // This is intentional
  },
  logout: () => {
    // This is intentional
  },
  isAdmin: () => false,
  isPOS: () => false,
  isDXCAdmin: () => false,
};

export const AuthContext = createContext(defaultValue);

export type Props = {
  children: ReactNode;
};

export const AuthProvider = (props: Props) => {
  const [userState, setUserState] = useState<AccountInfo | null>(null);
  const [userTypeState, setUserTypeState] = useState<userType | null>(
    userType.Unknown,
  );
  const [currentUserStakeholderNameState, setCurrentUserStakeholderNameState] =
    useState<string | null>(null);

  const { instance, accounts, inProgress } = useMsal();

  // AuthProvider holds single instance of AuthService
  // to pass the hook instance to the AuthService class.
  const service = AuthService.createInstance(instance);
  const IsAuthenticatedHook = useIsAuthenticated();

  const determineUserAccount = () => {
    if (accounts !== null) {
      if (accounts && accounts.length > 0) {
        return accounts[0];
      }
    }
    return null;
  };

  useEffect(() => {
    if (IsAuthenticatedHook) {
      const doEffect = async () => {
        // determine account of authenticated user
        const userAccount = determineUserAccount();
        setUserState(userAccount);
        service.setLocalId(userAccount?.localAccountId);
      };

      doEffect();
    }
    // determine userType
    if (
      IsAuthenticatedHook &&
      userTypeState !== undefined &&
      (userTypeState == null || userTypeState === userType.Unknown)
    ) {
      service
        .getUserInfo()
        .then((x) => {
          setUserTypeState(x.userType);
          setCurrentUserStakeholderNameState(x.stakeholderName);
        })
        .catch((e) => {
          console.log(e);
          console.log('User Role Update failed');
        });
    }
  }, [IsAuthenticatedHook, accounts]);

  const login = async () => {
    try {
      localStorage.removeItem('lastInputTime');
      await service.signIn();
    } catch (error) {
      console.log('Login failed', error);
    }
  };

  const logout = async () => {
    setUserTypeState(null);
    setCurrentUserStakeholderNameState(null);
    service.signOut();
  };

  const isAdmin = () => {
    if (
      userTypeState === userType.Services_Australia_Admin ||
      userTypeState === userType.DXC_Admin
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isPOS = () => {
    if (
      userTypeState === userType.POS_Developer ||
      userTypeState === userType.POS_Operations ||
      userTypeState === userType.POS_Developer_Operations ||
      userTypeState === userType.PIorAcquirer
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isDXCAdmin = () => {
    if (userTypeState === userType.DXC_Admin) {
      return true;
    } else {
      return false;
    }
  };

  const authProviderValue = {
    isAuthenticated: IsAuthenticatedHook,
    currentUserType: userTypeState,
    currentUserStakeholderName: currentUserStakeholderNameState,
    user: userState,
    login,
    logout,
    isAdmin,
    isPOS,
    isDXCAdmin,
  };

  return (
    <AuthContext.Provider value={authProviderValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
