export const StateOptions = [
  { name: '', value: '' },
  { name: 'ACT', value: 'ACT' },
  { name: 'NSW', value: 'NSW' },
  { name: 'NT', value: 'NT' },
  { name: 'QLD', value: 'QLD' },
  { name: 'SA', value: 'SA' },
  { name: 'TAS', value: 'TAS' },
  { name: 'VIC', value: 'VIC' },
  { name: 'WA', value: 'WA' },
];

export const CountryOptions = [
  { name: 'ANDORRA', value: '1', abr: 'AD' },
  { name: 'UNITED ARAB EMIRATES', value: '2', abr: 'AE' },
  { name: 'AFGHANISTAN', value: '3', abr: 'AF' },
  { name: 'ANTIGUA AND BARBUDA', value: '4', abr: 'AG' },
  { name: 'ANGUILLA', value: '5', abr: 'AI' },
  { name: 'ALBANIA', value: '6', abr: 'AL' },
  { name: 'ARMENIA', value: '7', abr: 'AM' },
  { name: 'NETHERLANDS ANTILLES', value: '8', abr: 'AN' },
  { name: 'ANGOLA', value: '9', abr: 'AO' },
  { name: 'ANTARCTICA', value: '10', abr: 'AQ' },
  { name: 'ARGENTINA', value: '11', abr: 'AR' },
  { name: 'AMERICAN SAMOA', value: '12', abr: 'AS' },
  { name: 'AUSTRIA', value: '13', abr: 'AT' },
  { name: 'AUSTRALIA', value: '14', abr: 'AU' },
  { name: 'ARUBA', value: '15', abr: 'AW' },
  { name: 'AZERBAIJAN', value: '16', abr: 'AZ' },
  { name: 'BOSNIA AND HERZEGOVINA', value: '17', abr: 'BA' },
  { name: 'BARBADOS', value: '18', abr: 'BB' },
  { name: 'BANGLADESH', value: '19', abr: 'BD' },
  { name: 'BELGIUM', value: '20', abr: 'BE' },
  { name: 'BURKINA FASO', value: '21', abr: 'BF' },
  { name: 'BULGARIA', value: '22', abr: 'BG' },
  { name: 'BAHRAIN', value: '23', abr: 'BH' },
  { name: 'BURUNDI', value: '24', abr: 'BI' },
  { name: 'BENIN', value: '25', abr: 'BJ' },
  { name: 'BERMUDA', value: '26', abr: 'BM' },
  { name: 'BRUNEI', value: '27', abr: 'BN' },
  { name: 'BOLIVIA', value: '28', abr: 'BO' },
  { name: 'BRAZIL', value: '29', abr: 'BR' },
  { name: 'BAHAMAS', value: '30', abr: 'BS' },
  { name: 'BHUTAN', value: '31', abr: 'BT' },
  { name: 'BOUVET ISLAND', value: '32', abr: 'BV' },
  { name: 'BOTSWANA', value: '33', abr: 'BW' },
  { name: 'BELARUS', value: '34', abr: 'BY' },
  { name: 'BELIZE', value: '35', abr: 'BZ' },
  { name: 'CANADA', value: '36', abr: 'CA' },
  { name: 'COCOS [KEELING] ISLANDS', value: '37', abr: 'CC' },
  { name: 'CONGO [DRC]', value: '38', abr: 'CD' },
  { name: 'CENTRAL AFRICAN REPUBLIC', value: '39', abr: 'CF' },
  { name: 'CONGO [REPUBLIC]', value: '40', abr: 'CG' },
  { name: 'SWITZERLAND', value: '41', abr: 'CH' },
  { name: "CÔTE D'IVOIRE", value: '42', abr: 'CI' },
  { name: 'COOK ISLANDS', value: '43', abr: 'CK' },
  { name: 'CHILE', value: '44', abr: 'CL' },
  { name: 'CAMEROON', value: '45', abr: 'CM' },
  { name: 'CHINA', value: '46', abr: 'CN' },
  { name: 'COLOMBIA', value: '47', abr: 'CO' },
  { name: 'COSTA RICA', value: '48', abr: 'CR' },
  { name: 'CUBA', value: '49', abr: 'CU' },
  { name: 'CAPE VERDE', value: '50', abr: 'CV' },
  { name: 'CHRISTMAS ISLAND', value: '51', abr: 'CX' },
  { name: 'CYPRUS', value: '52', abr: 'CY' },
  { name: 'CZECH REPUBLIC', value: '53', abr: 'CZ' },
  { name: 'GERMANY', value: '54', abr: 'DE' },
  { name: 'DJIBOUTI', value: '55', abr: 'DJ' },
  { name: 'DENMARK', value: '56', abr: 'DK' },
  { name: 'DOMINICA', value: '57', abr: 'DM' },
  { name: 'DOMINICAN REPUBLIC', value: '58', abr: 'DO' },
  { name: 'ALGERIA', value: '59', abr: 'DZ' },
  { name: 'ECUADOR', value: '60', abr: 'EC' },
  { name: 'ESTONIA', value: '61', abr: 'EE' },
  { name: 'EGYPT', value: '62', abr: 'EG' },
  { name: 'WESTERN SAHARA', value: '63', abr: 'EH' },
  { name: 'ERITREA', value: '64', abr: 'ER' },
  { name: 'SPAIN', value: '65', abr: 'ES' },
  { name: 'ETHIOPIA', value: '66', abr: 'ET' },
  { name: 'FINLAND', value: '67', abr: 'FI' },
  { name: 'FIJI', value: '68', abr: 'FJ' },
  { name: 'FALKLAND ISLANDS [ISLAS MALVINAS]', value: '69', abr: 'FK' },
  { name: 'MICRONESIA', value: '70', abr: 'FM' },
  { name: 'FAROE ISLANDS', value: '71', abr: 'FO' },
  { name: 'FRANCE', value: '72', abr: 'FR' },
  { name: 'GABON', value: '73', abr: 'GA' },
  { name: 'UNITED KINGDOM', value: '74', abr: 'GB' },
  { name: 'GRENADA', value: '75', abr: 'GD' },
  { name: 'GEORGIA', value: '76', abr: 'GE' },
  { name: 'FRENCH GUIANA', value: '77', abr: 'GF' },
  { name: 'GUERNSEY', value: '78', abr: 'GG' },
  { name: 'GHANA', value: '79', abr: 'GH' },
  { name: 'GIBRALTAR', value: '80', abr: 'GI' },
  { name: 'GREENLAND', value: '81', abr: 'GL' },
  { name: 'GAMBIA', value: '82', abr: 'GM' },
  { name: 'GUINEA', value: '83', abr: 'GN' },
  { name: 'GUADELOUPE', value: '84', abr: 'GP' },
  { name: 'EQUATORIAL GUINEA', value: '85', abr: 'GQ' },
  { name: 'GREECE', value: '86', abr: 'GR' },
  {
    name: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
    value: '87',
    abr: 'GS',
  },
  { name: 'GUATEMALA', value: '88', abr: 'GT' },
  { name: 'GUAM', value: '89', abr: 'GU' },
  { name: 'GUINEA-BISSAU', value: '90', abr: 'GW' },
  { name: 'GUYANA', value: '91', abr: 'GY' },
  { name: 'GAZA STRIP', value: '92', abr: 'GZ' },
  { name: 'HONG KONG', value: '93', abr: 'HK' },
  { name: 'HEARD ISLAND AND MCDONALD ISLANDS', value: '94', abr: 'HM' },
  { name: 'HONDURAS', value: '95', abr: 'HN' },
  { name: 'CROATIA', value: '96', abr: 'HR' },
  { name: 'HAITI', value: '97', abr: 'HT' },
  { name: 'HUNGARY', value: '98', abr: 'HU' },
  { name: 'INDONESIA', value: '99', abr: 'ID' },
  { name: 'IRELAND', value: '100', abr: 'IE' },
  { name: 'ISRAEL', value: '101', abr: 'IL' },
  { name: 'ISLE OF MAN', value: '102', abr: 'IM' },
  { name: 'INDIA', value: '103', abr: 'IN' },
  { name: 'BRITISH INDIAN OCEAN TERRITORY', value: '104', abr: 'IO' },
  { name: 'IRAQ', value: '105', abr: 'IQ' },
  { name: 'IRAN', value: '106', abr: 'IR' },
  { name: 'ICELAND', value: '107', abr: 'IS' },
  { name: 'ITALY', value: '108', abr: 'IT' },
  { name: 'JERSEY', value: '109', abr: 'JE' },
  { name: 'JAMAICA', value: '110', abr: 'JM' },
  { name: 'JORDAN', value: '111', abr: 'JO' },
  { name: 'JAPAN', value: '112', abr: 'JP' },
  { name: 'KENYA', value: '113', abr: 'KE' },
  { name: 'KYRGYZSTAN', value: '114', abr: 'KG' },
  { name: 'CAMBODIA', value: '115', abr: 'KH' },
  { name: 'KIRIBATI', value: '116', abr: 'KI' },
  { name: 'COMOROS', value: '117', abr: 'KM' },
  { name: 'SAINT KITTS AND NEVIS', value: '118', abr: 'KN' },
  { name: 'NORTH KOREA', value: '119', abr: 'KP' },
  { name: 'SOUTH KOREA', value: '120', abr: 'KR' },
  { name: 'KUWAIT', value: '121', abr: 'KW' },
  { name: 'CAYMAN ISLANDS', value: '122', abr: 'KY' },
  { name: 'KAZAKHSTAN', value: '123', abr: 'KZ' },
  { name: 'LAOS', value: '124', abr: 'LA' },
  { name: 'LEBANON', value: '125', abr: 'LB' },
  { name: 'SAINT LUCIA', value: '126', abr: 'LC' },
  { name: 'LIECHTENSTEIN', value: '127', abr: 'LI' },
  { name: 'SRI LANKA', value: '128', abr: 'LK' },
  { name: 'LIBERIA', value: '129', abr: 'LR' },
  { name: 'LESOTHO', value: '130', abr: 'LS' },
  { name: 'LITHUANIA', value: '131', abr: 'LT' },
  { name: 'LUXEMBOURG', value: '132', abr: 'LU' },
  { name: 'LATVIA', value: '133', abr: 'LV' },
  { name: 'LIBYA', value: '134', abr: 'LY' },
  { name: 'MOROCCO', value: '135', abr: 'MA' },
  { name: 'MONACO', value: '136', abr: 'MC' },
  { name: 'MOLDOVA', value: '137', abr: 'MD' },
  { name: 'MONTENEGRO', value: '138', abr: 'ME' },
  { name: 'MADAGASCAR', value: '139', abr: 'MG' },
  { name: 'MARSHALL ISLANDS', value: '140', abr: 'MH' },
  { name: 'NORTH MACEDONIA', value: '141', abr: 'MK' },
  { name: 'MALI', value: '142', abr: 'ML' },
  { name: 'MYANMAR [BURMA]', value: '143', abr: 'MM' },
  { name: 'MONGOLIA', value: '144', abr: 'MN' },
  { name: 'MACAU', value: '145', abr: 'MO' },
  { name: 'NORTHERN MARIANA ISLANDS', value: '146', abr: 'MP' },
  { name: 'MARTINIQUE', value: '147', abr: 'MQ' },
  { name: 'MAURITANIA', value: '148', abr: 'MR' },
  { name: 'MONTSERRAT', value: '149', abr: 'MS' },
  { name: 'MALTA', value: '150', abr: 'MT' },
  { name: 'MAURITIUS', value: '151', abr: 'MU' },
  { name: 'MALDIVES', value: '152', abr: 'MV' },
  { name: 'MALAWI', value: '153', abr: 'MW' },
  { name: 'MEXICO', value: '154', abr: 'MX' },
  { name: 'MALAYSIA', value: '155', abr: 'MY' },
  { name: 'MOZAMBIQUE', value: '156', abr: 'MZ' },
  { name: 'NAMIBIA', value: '157', abr: 'NA' },
  { name: 'NEW CALEDONIA', value: '158', abr: 'NC' },
  { name: 'NIGER', value: '159', abr: 'NE' },
  { name: 'NORFOLK ISLAND', value: '160', abr: 'NF' },
  { name: 'NIGERIA', value: '161', abr: 'NG' },
  { name: 'NICARAGUA', value: '162', abr: 'NI' },
  { name: 'NETHERLANDS', value: '163', abr: 'NL' },
  { name: 'NORWAY', value: '164', abr: 'NO' },
  { name: 'NEPAL', value: '165', abr: 'NP' },
  { name: 'NAURU', value: '166', abr: 'NR' },
  { name: 'NIUE', value: '167', abr: 'NU' },
  { name: 'NEW ZEALAND', value: '168', abr: 'NZ' },
  { name: 'OMAN', value: '169', abr: 'OM' },
  { name: 'PANAMA', value: '170', abr: 'PA' },
  { name: 'PERU', value: '171', abr: 'PE' },
  { name: 'FRENCH POLYNESIA', value: '172', abr: 'PF' },
  { name: 'PAPUA NEW GUINEA', value: '173', abr: 'PG' },
  { name: 'PHILIPPINES', value: '174', abr: 'PH' },
  { name: 'PAKISTAN', value: '175', abr: 'PK' },
  { name: 'POLAND', value: '176', abr: 'PL' },
  { name: 'SAINT PIERRE AND MIQUELON', value: '177', abr: 'PM' },
  { name: 'PITCAIRN ISLANDS', value: '178', abr: 'PN' },
  { name: 'PUERTO RICO', value: '179', abr: 'PR' },
  { name: 'PALESTINIAN TERRITORIES', value: '180', abr: 'PS' },
  { name: 'PORTUGAL', value: '181', abr: 'PT' },
  { name: 'PALAU', value: '182', abr: 'PW' },
  { name: 'PARAGUAY', value: '183', abr: 'PY' },
  { name: 'QATAR', value: '184', abr: 'QA' },
  { name: 'RÉUNION', value: '185', abr: 'RE' },
  { name: 'ROMANIA', value: '186', abr: 'RO' },
  { name: 'SERBIA', value: '187', abr: 'RS' },
  { name: 'RUSSIA', value: '188', abr: 'RU' },
  { name: 'RWANDA', value: '189', abr: 'RW' },
  { name: 'SAUDI ARABIA', value: '190', abr: 'SA' },
  { name: 'SOLOMON ISLANDS', value: '191', abr: 'SB' },
  { name: 'SEYCHELLES', value: '192', abr: 'SC' },
  { name: 'SUDAN', value: '193', abr: 'SD' },
  { name: 'SWEDEN', value: '194', abr: 'SE' },
  { name: 'SINGAPORE', value: '195', abr: 'SG' },
  { name: 'SAINT HELENA', value: '196', abr: 'SH' },
  { name: 'SLOVENIA', value: '197', abr: 'SI' },
  { name: 'SVALBARD AND JAN MAYEN', value: '198', abr: 'SJ' },
  { name: 'SLOVAKIA', value: '199', abr: 'SK' },
  { name: 'SIERRA LEONE', value: '200', abr: 'SL' },
  { name: 'SAN MARINO', value: '201', abr: 'SM' },
  { name: 'SENEGAL', value: '202', abr: 'SN' },
  { name: 'SOMALIA', value: '203', abr: 'SO' },
  { name: 'SURINAME', value: '204', abr: 'SR' },
  { name: 'SÃO TOMÉ AND PRÍNCIPE', value: '205', abr: 'ST' },
  { name: 'EL SALVADOR', value: '206', abr: 'SV' },
  { name: 'SYRIA', value: '207', abr: 'SY' },
  { name: 'SWAZILAND', value: '208', abr: 'SZ' },
  { name: 'TURKS AND CAICOS ISLANDS', value: '209', abr: 'TC' },
  { name: 'CHAD', value: '210', abr: 'TD' },
  { name: 'FRENCH SOUTHERN TERRITORIES', value: '211', abr: 'TF' },
  { name: 'TOGO', value: '212', abr: 'TG' },
  { name: 'THAILAND', value: '213', abr: 'TH' },
  { name: 'TAJIKISTAN', value: '214', abr: 'TJ' },
  { name: 'TOKELAU', value: '215', abr: 'TK' },
  { name: 'TIMOR-LESTE', value: '216', abr: 'TL' },
  { name: 'TURKMENISTAN', value: '217', abr: 'TM' },
  { name: 'TUNISIA', value: '218', abr: 'TN' },
  { name: 'TONGA', value: '219', abr: 'TO' },
  { name: 'TURKEY', value: '220', abr: 'TR' },
  { name: 'TRINIDAD AND TOBAGO', value: '221', abr: 'TT' },
  { name: 'TUVALU', value: '222', abr: 'TV' },
  { name: 'TAIWAN', value: '223', abr: 'TW' },
  { name: 'TANZANIA', value: '224', abr: 'TZ' },
  { name: 'UKRAINE', value: '225', abr: 'UA' },
  { name: 'UGANDA', value: '226', abr: 'UG' },
  { name: 'U.S. MINOR OUTLYING ISLANDS', value: '227', abr: 'UM' },
  { name: 'UNITED STATES', value: '228', abr: 'US' },
  { name: 'URUGUAY', value: '229', abr: 'UY' },
  { name: 'UZBEKISTAN', value: '230', abr: 'UZ' },
  { name: 'VATICAN CITY', value: '231', abr: 'VA' },
  { name: 'SAINT VINCENT AND THE GRENADINES', value: '232', abr: 'VC' },
  { name: 'VENEZUELA', value: '233', abr: 'VE' },
  { name: 'BRITISH VIRGIN ISLANDS', value: '234', abr: 'VG' },
  { name: 'U.S. VIRGIN ISLANDS', value: '235', abr: 'VI' },
  { name: 'VIETNAM', value: '236', abr: 'VN' },
  { name: 'VANUATU', value: '237', abr: 'VU' },
  { name: 'WALLIS AND FUTUNA', value: '238', abr: 'WF' },
  { name: 'SAMOA', value: '239', abr: 'WS' },
  { name: 'KOSOVO', value: '240', abr: 'XK' },
  { name: 'YEMEN', value: '241', abr: 'YE' },
  { name: 'MAYOTTE', value: '242', abr: 'YT' },
  { name: 'SOUTH AFRICA', value: '243', abr: 'ZA' },
  { name: 'ZAMBIA', value: '244', abr: 'ZM' },
  { name: 'ZIMBABWE', value: '245', abr: 'ZW' },
  { name: 'SOUTH SUDAN', value: '246', abr: 'SS' },
];

export const RoleOptions = [
  { name: '', value: '' },
  { name: 'POS Developer ', value: 'POS Developer ' },
  {
    name: 'POS Operational Support',
    value: 'POS Operational Support',
  },
  {
    name: 'Payment Integrator or Acquirer',
    value: 'Payment Integrator or Acquirer',
  },
  {
    name: 'Other',
    value: 'Other',
  },
];

export enum ABNStatus {
  InitialState = 'InitialState',
  BackPageVadidState = 'BackPageVadidState',
  Valid = 'Valid',
  Invalid = 'Invalid',
  APIError = 'APIError',
}

export const DocList = [
  {
    name: 'Document Name',
    summary: 'Summary of document text goes here.',
    fileName: 'Test Download PDF.pdf',
  },
  {
    name: 'Document Name',
    summary: 'Summary of document text goes here.',
    fileName: 'Test Download PDF.pdf',
  },
  {
    name: 'Document Name',
    summary: 'Summary of document text goes here.',
    fileName: 'Test Download PDF.pdf',
  },
];
