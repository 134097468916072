import axios, { AxiosHeaders } from 'axios';
import { DocumentMeta, DocumentMetaData } from '../models/documentmeta';
import { FactsheetDocumentMeta } from '../models/FactsheetDocumentMeta';
import { StakeholderDocumentMeta } from '../models/stakeholderDocumentMeta';
import { Merchant, MerchantCredentials, NewMerchant } from '../models/merchant';
import { UserType } from '../models/usertype';
import { UserInfo } from '../models/UserInfo';

import { tokenRequest } from '../config/AuthConfig';

import AuthService from './AuthService';

axios.defaults.timeout = 120000;

axios.interceptors.request.use(
  async (req) => {
    const tokenResponse = await AuthService.getInstance().getToken(
      tokenRequest,
    );
    if (tokenResponse && tokenResponse?.accessToken) {
      req.headers = new AxiosHeaders({
        Authorization: `Bearer ${tokenResponse?.accessToken}`,
      });
    }
    if (req.headers && req.headers['Authorization']) return req;
    throw new Error('the token is not available');
  },
  (error) => {
    return Promise.reject(error);
  },
);

const ApiService = {
  getMerchants: () => {
    return axios.get<MerchantCredentials[]>(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/csm/merchants`,
    );
  },
  getMerchant: (id: number) => {
    return axios.get<MerchantCredentials>(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/csm/merchant/${id}`,
    );
  },
  createMerchant: (merchant: NewMerchant) => {
    return axios.post<MerchantCredentials>(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/csm/merchant`,
      merchant,
    );
  },
  generateCredentials: (merchantId: number) => {
    return axios.post<MerchantCredentials>(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/csm/merchant/${merchantId}/credential`,
    );
  },
  editMerchant: (merchantId: number, merchant: Merchant) => {
    return axios.patch<Merchant>(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/csm/merchant/${merchantId}`,
      merchant,
    );
  },

  getDocuments: (filepath: string) => {
    return axios.get<DocumentMeta[]>(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/genericdocument/${filepath}`,
    );
  },
  getDocument: (container: string, filepath: string) => {
    return axios.get<DocumentMeta>(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/genericdocument/${container}/${filepath}`,
    );
  },
  getFactsheetDocuments: (userType: string) => {
    return axios.get<FactsheetDocumentMeta[]>(
      `${process.env.REACT_APP_API}/odata/factsheetUserType?$filter=UserType/UserTypeName eq '${userType}'&$expand=FactSheet,UserType`,
    );
  },
  deleteDocument: (container: string, filepath: string) => {
    return axios.delete<DocumentMeta>(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/genericdocument/${filepath}`,
    );
  },
  postDocument: (filepath: string, document: DocumentMeta) => {
    return axios
      .post(
        `${process.env.REACT_APP_API}/stakeholderportal/v1/genericdocument/${filepath}`,
        document,
        { timeout: 220000 },
      )
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },

  getUserInfo: () => {
    return axios.get<UserInfo>(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/userInfo`,
    );
  },
  getUserType: () => {
    return axios.get<UserType>(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/userType`,
    );
  },

  patchDocument: (filepath: string, document: DocumentMetaData) => {
    return axios.patch(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/genericdocument/${filepath}`,
      document,
    );
  },
  getStakeholderDocuments: () => {
    return axios.get<StakeholderDocumentMeta[]>(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/stakeholderspecificdocument`,
    );
  },
  getStakeholderDocument: (filepath: string) => {
    return axios.get<StakeholderDocumentMeta>(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/stakeholderspecificdocument/document/${filepath}`,
    );
  },
  deleteStakeholderDocument: (filepath: string) => {
    return axios.delete<StakeholderDocumentMeta>(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/stakeholderspecificdocument/${filepath}`,
    );
  },
  deleteStakeholderDocumentAdmin: (filepath: string) => {
    return axios.delete<StakeholderDocumentMeta>(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/stakeholderspecificdocument/${filepath}`,
    );
  },
  postStakeholderDocument: (document: StakeholderDocumentMeta) => {
    return axios
      .post(
        `${process.env.REACT_APP_API}/stakeholderportal/v1/stakeholderspecificdocument`,
        document,
        { timeout: 220000 },
      )
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  postStakeholderDocumentAdmin: (
    document: StakeholderDocumentMeta,
    container?: string,
  ) => {
    return axios
      .post(
        `${process.env.REACT_APP_API}/stakeholderportal/v1/stakeholderspecificdocument/${container}`,
        document,
        { timeout: 220000 },
      )
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getStakeholderContainers: () => {
    return axios.get<{ [key: string]: string }>(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/stakeholderspecificdocument/containernames`,
    );
  },
  getStakeholderDocumentsByContainer: (container: string) => {
    return axios.get<StakeholderDocumentMeta[]>(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/stakeholderspecificdocument/${container}`,
    );
  },
};

export default ApiService;
