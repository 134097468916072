import { createTheme, ThemeProvider } from '@mui/material/styles';
import { UnsupportedPage } from './components/UnsupportedPage';
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { appInsights } from './AppInsights';
import PublicFooter from './components/PublicFooter';
import PublicHeader from './components/PublicHeader';
import ResponsiveDrawer from './components/ResponsiveDrawer';
import LoadingPage from './components/LoadingPage';
import { AuthConsumer, AuthProvider } from './context/AuthContext';
import { UAParser } from 'ua-parser-js';
import PrivateRoutes from './privateRoutes';
import PublicRoutes from './publicRoutes';
import RouteChangeTracker from './RouteChangeTracker';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { userType } from './models/mainnav';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import { PageTitles } from './utils/PageTitles';

const TRACKING_ID = `${process.env.REACT_APP_TRACKING_ID}`;
ReactGA.initialize(TRACKING_ID);

const KEYWORDS = `${process.env.REACT_APP_KEYWORDS}`;
const ROBOT_KEYWORDS = `${process.env.REACT_APP_ROBOTS_KEYWORDS}`;

export const theme = createTheme({
  palette: {
    primary: {
      main: '#500A78',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          borderRadius: '3.125rem',
          opacity: '1',
          boxShadow: '0 0.125rem 0.25rem #60617029',
          textTransform: 'none',
          minWidth: '9.375rem',
          paddingLeft: '1.875rem',
          paddingRight: '1.875rem',
          paddingTop: '0.5625rem',
          paddingBottom: '0.5625rem',
          font: 'normal normal 600 1rem/1.75rem Open Sans',
          letterSpacing: '0.01875rem',
          background: 'var(--primary-purple)',
          color: 'var(--primary-white)',
          border: '0.125rem solid var(--primary-purple)',
          '&:hover': {
            background: 'var(--primary-white)',
            color: 'var(--primary-purple)',
            border: '0.125rem solid var(--primary-purple)',
          },
          '&:disabled': {
            color: 'var(--primary-white)',
            background: 'var(--disabled-button-gray)',
            border: '0.125rem solid var(--disabled-button-gray)',
          },
        },
      },
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            background: 'var(--primary-purple) ',
            color: 'var(--primary-white)',
            borderRadius: '1.5625rem',
            '&:hover': {
              background: 'var(--primary-purple-hover)',
              color: 'var(--primary-white)',
              border: '0.125rem solid var(--primary-purple-hover)',
            },
            '&:disabled': {
              background: 'var(--disabled-button-gray)',
              color: 'var(--primary-white)',
              border: '0.125rem solid var(--disabled-button-gray)',
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            background: 'var(--primary-white)',
            color: 'var(--primary-purple)',
            border: '0.125rem solid var(--primary-purple)',
            '&:hover': {
              background: 'var(--secondary-purple-hover)',
              color: 'var(--primary-purple)',
              border: '0.125rem solid var(--primary-purple)',
            },
            '&:disabled': {
              background: 'var(--primary-white)',
              color: 'var(--disabled-button-gray)',
              border: '0.125rem solid var(--disabled-button-gray)',
            },
          },
        },
        {
          props: { variant: 'action' },
          style: {
            background: 'var(--primary-white)',
            color: 'var(--action-primary)',
            border: '0.0625rem solid var(--action-primary)',
            borderRadius: '0.25rem',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            height: '3.125rem',
            '&:hover': {
              background: 'var(--action-hover-background)',
              color: 'var(--action-hover)',
              border: '0.0625rem solid var(--action-hover)',
            },
            '&:disabled': {
              background: 'var(--disabled-button-gray)',
              color: 'var(--primary-white)',
              border: '0.0625rem solid var(--disabled-button-gray)',
            },
          },
        },
        {
          props: { variant: 'whiteYellow' },
          style: {
            background: 'var(--primary-white)',
            color: 'var(--primary-black)',
            border: '0.1875rem solid var(--dark-yellow)',
            boxShadow: '0px 3px 6px #60617029',
            paddingLeft: '3.125rem',
            paddingRight: '3.125rem',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            '&:hover': {
              background: 'var(--dark-yellow)',
              color: 'var(--primary-black)',
              border: '3px solid var(--dark-yellow)',
            },
          },
        },
        {
          props: { variant: 'yellow' },
          style: {
            background: 'var(--primary-yellow)',
            color: 'var(--primary-black)',
            boxShadow: '0px 3px 6px #60617029',
            border: '0.1875rem solid var(--dark-yellow)',
            borderRadius: '1.5625rem',
            paddingLeft: '3.125rem',
            paddingRight: '3.125rem',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            '&:hover': {
              background: 'var(--dark-yellow)',
              border: '0.1875rem solid var(--dark-yellow)',
              color: 'var(--primary-black)',
            },
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          opacity: '0.3',
          width: '100%',
          backgroundColor: '#707070',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          textAlign: 'left',
          fontFamily: 'Open Sans',
          fontSize: '0.625rem',
          fontWeight: 'normal',
          lineHeight: '1.0625rem',
          letterSpacing: '0.0206rem',
          color: '#000000DE',
          '&.Mui-active': {
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: '1.1875rem',
          height: '1.1875rem',
          '&.Mui-completed': {
            color: '#178639',
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          height: '0.0625rem',
          background: '#BBBABB',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          fontFamily: 'Open Sans',
          fontSize: '1rem',
          fontWeight: 'normal',
          letterSpacing: '0.033rem',
          color: '#505050',
          '&.Mui-disabled': {
            color: '#636363',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          textAlign: 'left',
        },
        input: {
          '&.Mui-disabled': {
            WebkitTextFillColor: '#636363',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-error:before': {
            borderBottomColor: '#d32f2f !important',
            borderBottomWidth: '2px',
          },
          '&:before': {
            borderBottomColor: 'transparent !important',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          lineHeight: '0.9375rem',
          '&.Mui-error': {
            color: 'var(--error-red)',
            opacity: '1',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontWeight: '500',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '0.625rem 0.625rem 0 0',
          maxWidth: '54.375rem',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          alignItems: 'center',
        },
      },
    },
  },
  typography: {
    h1: {
      fontFamily: 'Open Sans',
      fontSize: '2rem',
      fontWeight: '400',
      lineHeight: '2.5rem',
      letterSpacing: '0',
      color: 'var(--primary-white)',
    },
    h2: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: '1.875rem',
      fontWeight: 'bold',
      lineHeight: '4.187rem',
      letterSpacing: '0',
      color: 'var(--primary-black)',
    },
    h3: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: '1.687rem',
      fontWeight: '600',
      lineHeight: '2rem',
      letterSpacing: '-0.0425rem',
      color: 'var(--dss-dark-green-text)',
    },
    h4: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      lineHeight: '1.375rem',
      letterSpacing: '0.0112rem',
      color: 'var(--primary-purple)',
    },
    h5: {
      fontFamily: 'Open Sans',
      fontSize: '1.125rem',
      fontWeight: '400',
      lineHeight: '1.75rem',
      letterSpacing: '0',
      color: 'var(--primary-black)',
    },
    body1: {
      // Body text on forms
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: '1rem',
      fontWeight: 'normal',
      lineHeight: '1.5rem',
      letterSpacing: '0.031rem',
      color: 'var(--primary-black)',
    },
    body2: {
      fontFamily: 'Open Sans',
      fontSize: '1.062rem',
      fontWeight: '200',
      lineHeight: '1.375rem',
      letterSpacing: '0',
      color: 'var(--primary-black)',
    },
    caption: {
      justifySelf: 'flex-start',
      fontFamily: 'Open Sans',
      fontSize: '0.75rem',
      fontWeight: 'normal',
      lineHeight: '0.3125rem',
      letterSpacing: '0',
      color: 'var(--primary-black)',
      opacity: '0.72',
    },
  },
});

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: boolean;
    secondary: boolean;
    action: boolean;
    whiteYellow: boolean;
    yellow: boolean;
  }
}

appInsights.loadAppInsights();
appInsights.trackPageView();

const unsupportedBrowsers = ['IE'];

export const App = () => {
  const [isSupported, setIsSupported] = React.useState(true);
  const { inProgress } = useMsal();

  useEffect(() => {
    try {
      const parser = new UAParser();
      const result = parser.getBrowser();
      if (unsupportedBrowsers.find((e) => e === result.name)) {
        setIsSupported(false);
      } else {
        setIsSupported(true);
      }
    } catch {
      setIsSupported(true);
    }
  }, []);

  return (
    <div className="root">
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>{PageTitles.Home}</title>
          <meta name="author" content="Services Australia" />
          <meta
            name="description"
            content="Portal supporting acquirers, payment integrators, POS providers and resellers for Product Level Blocking PLB, an Australian government enhanced Income Management initiative."
          />
          <meta name="keywords" content={KEYWORDS} />
          <meta name="robots" content={ROBOT_KEYWORDS} />
        </Helmet>
        {isSupported && (
          <BrowserRouter>
            <RouteChangeTracker />
            <AuthProvider>
              <AuthConsumer>
                {({ isAuthenticated, currentUserType }) => {
                  if (isAuthenticated) {
                    if (
                      inProgress !== InteractionStatus.None ||
                      currentUserType === null ||
                      currentUserType === undefined ||
                      currentUserType === userType.Unknown
                    ) {
                      return <LoadingPage />;
                    } else {
                      return (
                        <ResponsiveDrawer>
                          <PrivateRoutes />
                        </ResponsiveDrawer>
                      );
                    }
                  } else if (inProgress === InteractionStatus.None) {
                    return (
                      <>
                        <PublicHeader />
                        <PublicRoutes />
                        <PublicFooter />
                      </>
                    );
                  }
                }}
              </AuthConsumer>
            </AuthProvider>
          </BrowserRouter>
        )}
        {!isSupported && <UnsupportedPage />}
      </ThemeProvider>
    </div>
  );
};

export default App;
