import { makeStyles } from 'tss-react/mui';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { Box } from '@mui/material';
import SecretExpiry from '../Shared/Secret Expiry';
import { Merchant } from '../../models/merchant';
import { MerchantCredentials } from '../../models/merchant';
import { formatDateTime } from '../../utils/FormatDateTime';
import * as React from 'react';
import { IconActionButton } from '../Shared/IconActionButton';

const useStyles = makeStyles()((theme) => {
  return {
    tableRoot: {
      '& .MuiDataGrid-colCellWrapper': {
        backgroundColor: '#f7f9fc',
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontSize: '0.813rem',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
      },
      '& .MuiDataGrid-cell': {
        fontSize: '0.813rem',
        outline: 'none !important',
      },
      '& .MuiTablePagination-selectLabel': {
        color: '#9A9A9A',
        textTransform: 'uppercase',
        font: 'normal normal 600 8px/11px Open Sans',
        fontSize: '0.688rem',
      },
      '& .MuiTablePagination-displayedRows': {
        font: 'normal normal medium 10px/14px Open Sans',
        fontSize: '0.875rem',
        fontWeight: '400',
      },
    },
    actionButton: {
      alignSelf: 'center',
      marginRight: '0.75rem !important',
      maxWidth: '9.375rem',
    },
    actionButtonText: {
      paddingLeft: '0.75rem',
      font: 'normal normal normal 14px / 13px Open Sans',
      letterSpacing: '0',
      textAlign: 'center',
    },
    merchantsDataTableIcon: {
      width: '1.2rem',
      height: '1.2rem',
    },
  };
});

export interface MerchantsDataTableProps {
  merchantData: MerchantCredentials[];
  viewCredentialsAction: (merchant: Merchant) => void;
  editMerchantAction: (merchant: Merchant) => void;
  isUser?: boolean;
}

const MerchantsDataTable = (props: MerchantsDataTableProps) => {
  const { classes } = useStyles();
  const {
    merchantData,
    viewCredentialsAction,
    editMerchantAction,
    isUser = false,
  } = props;
  const [pageSize, setPageSize] = React.useState<number>(100);

  const rows = merchantData.map((merchantCredentials: MerchantCredentials) => ({
    ...merchantCredentials,
    id: merchantCredentials.merchantId,
    name: merchantCredentials.merchantName,
    location: merchantCredentials.siteLocation,
    expiry: merchantCredentials.credential.expiryDateTime,
  }));

  const formatSiteAddress = (addressString: string) => {
    var maxLength = 170;
    var formattedAddress = addressString.substring(0, maxLength);
    return formattedAddress;
  };

  const columnsUser: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Merchant Site',
      disableColumnMenu: true,
      flex: 1.3,
    },
    {
      field: 'location',
      headerName: 'Site Address',
      disableColumnMenu: true,
      flex: 1.5,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        return formatSiteAddress(params.value);
      },
    },
    {
      field: 'expiry',
      headerName: 'Secret Expiry Date',
      type: 'date',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (params.value == null) {
          return '';
        }
        return formatDateTime(new Date(params.value));
      },
      flex: 0.65,
      renderCell: (params: GridRenderCellParams<string>) => (
        <>
          <SecretExpiry secretExpiryDate={params.value!} />
        </>
      ),
    },
    {
      field: 'editMerchants',
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: () => <></>,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ mr: '0.9375rem' }}>
          <IconActionButton
            variant="updateInfo"
            onClick={() =>
              editMerchantAction(params.row as unknown as Merchant)
            }
          />
          <IconActionButton
            variant="manageSecret"
            onClick={() =>
              viewCredentialsAction(params.row as unknown as Merchant)
            }
          />
        </Box>
      ),
    },
  ];

  const getStakeholder = (params: any) => {
    var stakeholderArray = `${params.row.managedByStakeholder[0]}`;
    stakeholderArray.toString();
    return stakeholderArray;
  };

  const columnsAdmin: GridColDef[] = [
    {
      field: 'merchantName',
      headerName: 'Merchant Site',
      disableColumnMenu: true,
      flex: 0.5,
    },
    {
      field: 'siteLocation',
      headerName: 'Site Address',
      disableColumnMenu: true,
      flex: 1.25,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        return formatSiteAddress(params.value);
      },
    },
    {
      field: 'managedByStakeholder',
      headerName: 'Organisation',
      disableColumnMenu: true,
      flex: 0.5,
      valueGetter: getStakeholder,
    },
    {
      field: 'expiry',
      headerName: 'Secret Expiry Date',
      type: 'date',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (params.value == null) {
          return '';
        }
        return formatDateTime(new Date(params.value));
      },
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<string>) => (
        <>
          <SecretExpiry secretExpiryDate={params.value!} />
        </>
      ),
    },
    {
      field: 'editMerchants',
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: () => <></>,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ mr: '0.9375rem' }}>
          <IconActionButton
            variant="viewCredentials"
            onClick={() =>
              viewCredentialsAction(params.row as unknown as Merchant)
            }
          />
        </Box>
      ),
    },
  ];

  const getColumnName = () => {
    if (isUser) {
      return columnsUser;
    } else {
      return columnsAdmin;
    }
  };

  return (
    <div className={classes.tableRoot}>
      <DataGrid
        rows={rows}
        columns={getColumnName()}
        autoHeight
        pagination
        pageSize={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
        rowHeight={84}
        headerHeight={64}
        disableSelectionOnClick={true}
        disableColumnSelector={true} // This will disable the entire show column function
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        classes={{
          columnHeader: 'documentTable_header',
          columnSeparator: 'documentTable_seperator',
          columnHeaders: 'documentTable_header_background',
          row: 'documentTable_row',
          cell: 'documentTable_cell_padding',
          cellContent: 'documentTable_cell_content',
        }}
        sortingOrder={['desc', 'asc']}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'expiry',
                sort: 'asc',
              },
            ],
          },
        }}
      />
    </div>
  );
};

export default MerchantsDataTable;
