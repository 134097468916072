import { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect } from 'react';
import { StakeholderDocumentRow } from '../../models/stakeholderDocumentRow';
import {
  getStakeholderContainersApi,
  getStakeholderDocumentsByContainerApi,
} from '../../utils/StakeholderDocumentUtil';
import PageHeader from '../Shared/PageHeader';
import PageOverlayContent from '../Shared/PageOverlayContent';
import StakeholderDocumentTable from '../Shared/StakeholderDocumentTable';
import { Helmet } from 'react-helmet';
import { PageTitles } from '../../utils/PageTitles';
import './Deliverables.css';

const AdminDeliverables = () => {
  const [allData, setAllData] = React.useState<StakeholderDocumentRow[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [stakeholderNames, setStakeholderNames] = React.useState<string[]>([
    '',
  ]);
  const [stakeholderContainers, setStakeholderContainers] = React.useState<
    string[]
  >([]);
  const [stakeholderIndex, setStakeholderIndex] = React.useState(-1);

  const getStakeholderContainers = async (
    callback: () => Promise<Map<string, string>>,
  ) => {
    // Return the list
    await callback().then((mappedContainers) => {
      // Set state of containers and names using return list
      let containers = Array.from(mappedContainers.keys());
      let names = Array.from(mappedContainers.values());
      setStakeholderContainers(containers);
      setStakeholderNames(names);
    });
  };

  const getStakeholderDocumentsByContainer = (
    callback: (
      container: string,
      fileCategory: string,
    ) => Promise<StakeholderDocumentRow[]>,
    containerName: string,
  ) => {
    // Reset the list for new stakeholder query
    setAllData([]);

    // Return the document row
    callback(containerName, 'Deliverable')
      .then((documentTable) => {
        // Set state for new documents
        setAllData(documentTable);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  // useEffect to initialise documents
  useEffect(() => {
    getStakeholderContainers(getStakeholderContainersApi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect to monitor change of stakeholders in drop down
  useEffect(() => {
    if (stakeholderIndex > -1) {
      getStakeholderDocumentsByContainer(
        getStakeholderDocumentsByContainerApi,
        stakeholderContainers[stakeholderIndex],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stakeholderIndex]);

  // Handle the changing of drop down stakeholder selector
  const handleChange = (event: SelectChangeEvent) => {
    setIsLoading(true);
    setStakeholderIndex(stakeholderNames.indexOf(event.target.value));
  };

  return (
    <>
      <Helmet>
        <title>{PageTitles.Deliverables}</title>
      </Helmet>
      <PageHeader headerTitle="Deliverables" hasOverlay={true} isAdmin={true}>
        {
          'See deliverables uploaded by stakeholders here.\nUse the dropdown menu below to filter by organisation.'
        }
      </PageHeader>
      <PageOverlayContent>
        <StakeholderDocumentTable
          data={allData}
          tableName={stakeholderNames[stakeholderIndex]}
          isLoading={isLoading}
          canSeeUploader={true}
          canUpload={false}
          canDelete={false}
          isAccordion={false}
          canSelectStakeholder={true}
          selectedStakeholderIndex={stakeholderIndex}
          handleStakeholderChange={handleChange}
          stakeholderNames={stakeholderNames}
          fileCategory="Deliverables"
        />
      </PageOverlayContent>
    </>
  );
};

export default AdminDeliverables;
