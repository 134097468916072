import { Dialog } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { DocumentRow } from '../../models/documentRow';
import ApiService from '../../services/ApiService';
import ActionDialog from '../Shared/ActionDialog';
import DocumentTable from '../Shared/DocumentTable';
import PageHeader from '../Shared/PageHeader';
import UploadDialog from '../Shared/UploadDialog';
import VisibilityDialog from '../Shared/VisibilityDialog';
import { PageTitles } from '../../utils/PageTitles';
import { Helmet } from 'react-helmet';
import './NoticesBriefs.css';

const AdminNoticesBriefs = () => {
  const authContextValue = useContext(AuthContext);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteFileName, setDeleteFileName] = React.useState('');
  const [visibilityFileName, setVisibilityFileName] = React.useState('');
  const [deleteLink, setDeleteLink] = React.useState('');
  const [visibilityLink, setVisibilityLink] = React.useState('');
  const [visibilityUserType, setVisibilityUserType] = React.useState('');
  const [uploadOpen, setUploadOpen] = React.useState(false);
  const [visibilityOpen, setVisibilityOpen] = React.useState(false);
  const [allData, setAllData] = React.useState<DocumentRow[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const openDeleteDialog = (row: DocumentRow) => {
    setDeleteFileName(row.name);
    setDeleteLink(row.link);
    setDeleteOpen(true);
  };

  const openVisibilityDialog = (row: DocumentRow) => {
    setVisibilityFileName(row.name);
    setVisibilityLink(row.link);
    setVisibilityUserType(row.role);
    setVisibilityOpen(true);
  };

  const refreshDocuments = () => {
    setUploadOpen(false);
    getDocuments();
    setVisibilityOpen(false);
  };

  const confirmDelete = () => {
    ApiService.deleteDocument('notice', deleteLink)
      .then(() => {
        let removeFromData = allData.filter((x) => x.link !== deleteLink);
        setAllData(removeFromData);
        setDeleteOpen(false);
        refreshDocuments();
      })
      .catch((e) => {
        console.log(e);
        setDeleteOpen(false);
      });
  };

  const getDocuments = () => {
    ApiService.getDocuments('notice')
      .then((x) => {
        let data = x.data;
        let newTable: DocumentRow[] = [];
        for (let dataItem of data) {
          let newRow: DocumentRow = {
            id: newTable.length,
            name: dataItem.fileName,
            date: new Date(dataItem.createdOn),
            link: dataItem.containerName + '/' + dataItem.fileName,
            role: dataItem.userType,
            type: 'notice',
            createdBy: dataItem.createdBy,
            isDownloading: false,
          };
          newTable.push(newRow);
        }
        setAllData(newTable);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDocuments();
  }, []);

  return (
    <>
      <Helmet>
        <title>{PageTitles.NoticesAndBriefs}</title>
      </Helmet>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={deleteOpen}
        aria-describedby="scroll-dialog-description"
      >
        <ActionDialog
          headingActionName="delete this file"
          actionName="delete file"
          fileName={deleteFileName}
          noFunction={() => setDeleteOpen(false)}
          yesFunction={() => confirmDelete()}
        />
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={uploadOpen}
        aria-describedby="scroll-dialog-description"
      >
        <UploadDialog
          closeDialog={() => setUploadOpen(false)}
          confirmDialog={() => refreshDocuments()}
          fileCategory="Notice"
          fileContainer={'notice'}
          userName={authContextValue.user?.username as string}
          fileName={visibilityFileName}
          rowLink={visibilityLink}
          cancelFunction={() => setVisibilityOpen(false)}
          saveFunction={() => refreshDocuments()}
        />
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={visibilityOpen}
        aria-describedby="scroll-dialog-description"
      >
        <VisibilityDialog
          fileName={visibilityFileName}
          rowLink={visibilityLink}
          rowUserType={visibilityUserType}
          cancelFunction={() => setVisibilityOpen(false)}
          saveFunction={() => refreshDocuments()}
        />
      </Dialog>
      <PageHeader headerTitle="Notices and Briefs" isAdmin={true} hasOverlay>
        {
          'See and manage Notices and Briefs here.\nNotices and Briefs announce important updates and changes to the PLB program.'
        }
      </PageHeader>
      <div className="NoticeBriefs_adminPadding">
        <DocumentTable
          data={allData}
          tableName={'Published Notices and Briefs'}
          isLoading={isLoading}
          canSeeUploader={true}
          canSeeVisibility={true}
          canEditVisibility={true}
          canUpload={true}
          canDelete={true}
          isAccordion={false}
          deleteFunction={openDeleteDialog}
          uploadFunction={() => setUploadOpen(true)}
          visibilityFunction={openVisibilityDialog}
        />
      </div>
    </>
  );
};

export default AdminNoticesBriefs;
