import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthConsumer } from '../../context/AuthContext';
import {
  ExpressInterestLocation,
  HomeLocation,
} from '../../publicRouteConstants';
import './PublicHeader.css';

const PublicHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = React.useState('');

  const goToLocation = (location: string) => {
    window.scrollTo(0, 0);
    navigate(location);
  };

  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <AppBar position="sticky">
        <Toolbar className="header_gutter headerPadding">
          <Typography
            onClick={() => goToLocation(HomeLocation.path)}
            onKeyPress={(e) =>
              e.key === 'Enter' && goToLocation(HomeLocation.path)
            }
            tabIndex={0}
            className="headerTitle"
          >
            {'PLB Portal'}
          </Typography>
          <Typography
            onClick={() => goToLocation(ExpressInterestLocation.path)}
            onKeyPress={(e) =>
              e.key === 'Enter' && goToLocation(ExpressInterestLocation.path)
            }
            tabIndex={0}
            className={
              currentPage === ExpressInterestLocation.path
                ? 'headerOption headerOptionSelected'
                : 'headerOption headerOptionRegular'
            }
          >
            Express Interest
          </Typography>
          <AuthConsumer>
            {({ login }) => {
              return (
                <>
                  <Button
                    onClick={login}
                    className="headerButton"
                    variant="primary"
                  >
                    <p className="headerButtonText">Sign In</p>
                  </Button>
                </>
              );
            }}
          </AuthConsumer>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default PublicHeader;
