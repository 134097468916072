export enum PageTitles {
  Home = 'PLB Portal Home',
  EoI = 'Expression of Interest',
  NoticesAndBriefs = 'Notices and Briefs',
  Deliverables = 'Deliverables',
  FAQ = 'Frequently Asked Questions',
  PLBResources = 'Resources',
  TestEvidence = 'Test Evidence',
  MerchantSiteCredentials = 'Merchant Site Credentials',
  Accreditation = 'Accreditation',
  TestResults = 'Test Results',
  Contact = 'Contact',
}
