import {
  EndSessionRequest,
  InteractionRequiredAuthError,
  IPublicClientApplication,
  SilentRequest,
  BrowserAuthError,
} from '@azure/msal-browser';

import { userType } from '../models/mainnav';
import ApiService from './ApiService';
import { redirectReq } from '../config/AuthConfig';
import { UserInfoDto } from '../models/UserInfo';

export class AuthService {
  localId: string | null | undefined;
  msalApp: IPublicClientApplication;
  static instance: AuthService;

  private constructor(instance: IPublicClientApplication) {
    this.localId = null;
    this.msalApp = instance;
  }

  // single instance instantiation method
  public static createInstance(
    msalApp: IPublicClientApplication | null,
  ): AuthService {
    if (AuthService.instance === undefined && msalApp !== null) {
      AuthService.instance = new AuthService(msalApp);
    }
    return AuthService.instance;
  }

  // single instance access method
  public static getInstance(): AuthService {
    return AuthService.instance;
  }

  setLocalId = (localId: string | null | undefined) => {
    this.localId = localId;
  };

  signIn = () => {
    this.msalApp.loginRedirect(redirectReq);
  };

  signOut = async () => {
    const logoutRequest: EndSessionRequest = {
      account: this.msalApp.getAccountByLocalId(this.localId!) || undefined,
    };
    await this.msalApp.logout(logoutRequest);
  };

  getUserRole = async () => {
    return await ApiService.getUserType()
      .then((x) => {
        let currentType = x.data.userType;
        if (currentType === 'ServicesAustralia') {
          return userType.Services_Australia_Admin;
        }
        if (currentType === 'DXC') {
          return userType.DXC_Admin;
        }
        if (currentType === 'POSDeveloper') {
          return userType.POS_Developer;
        }
        if (currentType === 'POSOperations') {
          return userType.POS_Operations;
        }
        if (currentType === 'POSDeveloper+Operations') {
          return userType.POS_Developer_Operations;
        }
        if (currentType === 'AcquirerPaymentIntegrator') {
          return userType.PIorAcquirer;
        }
        return userType.Unknown;
      })
      .catch((e) => {
        console.log(e);
        return userType.Unknown;
      });
  };

  getUserInfo = async () => {
    return await ApiService.getUserInfo()
      .then((x) => {
        let dto = new UserInfoDto();
        dto.userId = x.data.userId;
        dto.stakeholderName = x.data.stakeholderName;
        dto.userTypeName = x.data.userTypeName;
        let currentType = x.data.userTypeName;
        if (currentType === 'ServicesAustralia') {
          dto.userType = userType.Services_Australia_Admin;
        }
        if (currentType === 'DXC') {
          dto.userType = userType.DXC_Admin;
        }
        if (currentType === 'POSDeveloper') {
          dto.userType = userType.POS_Developer;
        }
        if (currentType === 'POSOperations') {
          dto.userType = userType.POS_Operations;
        }
        if (currentType === 'POSDeveloper+Operations') {
          dto.userType = userType.POS_Developer_Operations;
        }
        if (currentType === 'AcquirerPaymentIntegrator') {
          dto.userType = userType.PIorAcquirer;
        }
        if (dto.userType === null) {
          dto.userType = userType.Unknown;
        }
        return dto;
      })
      .catch((e) => {
        console.log(e);
        let dto = new UserInfoDto();
        dto.userType = userType.Unknown;
        return dto;
      });
  };

  getToken = async (request: any) => {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    request.account =
      this.msalApp.getAccountByLocalId(this.localId!) || undefined;
    let result = await this.msalApp
      .acquireTokenSilent(request as SilentRequest)
      .catch((error: any) => {
        console.log(
          'silent token acquisition fails. acquiring token using redirect',
        );
        if (error instanceof InteractionRequiredAuthError) {
          // Do not attempt to reqauire token - just sign out
          this.signOut();
        } else if (error instanceof BrowserAuthError) {
          this.signOut();
        } else {
          console.warn(error);
        }
      });
    return result;
  };
}

export default AuthService;
