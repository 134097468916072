import React from 'react';

export interface NewTabProps {
  href: string;
  children: React.ReactNode;
  className?: string;
}

const NewTabLink = (props: NewTabProps) => {
  const { href, children, className } = props;

  return (
    <a
      href={href}
      rel="noopener noreferrer"
      target="_blank"
      className={className}
    >
      {children}
    </a>
  );
};

export default NewTabLink;
