import './VisibilityDialog.css';
import {
  Button,
  IconButton,
  DialogContent,
  DialogActions,
  Checkbox,
  FormGroup,
  FormControlLabel,
  SelectChangeEvent,
  Alert,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useEffect } from 'react';
import POChip from '../POChip';
import PDChip from '../PDChip';
import PIAChip from '../PIAChip';
import { DocumentMetaData } from '../../../models/documentmeta';
import ApiService from '../../../services/ApiService';

export interface VisibilityDialogProps {
  fileName: string;
  rowLink: string;
  rowUserType: string;
  saveFunction(): void;
  cancelFunction(): void;
}

const VisibilityDialog = (props: VisibilityDialogProps) => {
  const { fileName, rowLink, rowUserType, saveFunction, cancelFunction } =
    props;
  const [users, setUsers] = React.useState<string[]>(rowUserType.split(','));
  const handleChange = (event: SelectChangeEvent) => {
    const index = users.indexOf(event.target.value);
    if (index === -1) {
      setUsers([...users, event.target.value]);
    } else {
      setUsers(users.filter((user: any) => user !== event.target.value));
    }
  };

  const [dialogHeight, setDialogHeight] = React.useState<string>('7.1875rem');
  useEffect(() => {
    if (users.length > 0) {
      setDialogHeight('7.1875rem');
    } else {
      setDialogHeight('10.8125rem');
    }
  }, [users.length]);

  const useStyles = makeStyles<{ setHeight: string }>()((theme) => {
    return {
      root: {
        '& .MuiDialogActions-root': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          paddingTop: '0.5rem',
          paddingBot: '1rem',
          height: dialogHeight,
        },
        '& .MuiAlert-root': {
          width: '100%',
          font: 'normal normal normal 0.8125rem/1.5rem Open Sans',
          letterSpacing: '0.0075rem',
          color: '#8A1010',
          opacity: '1',
          maxHeight: '6.25rem',
          padding: '0.625rem 1rem 0.75rem',
        },
        '& .MuiAlert-message': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '0rem',
          '& p': {
            margin: '0rem',
          },
        },
        '& .MuiTypography-root': {
          textAlign: 'left',
        },
        '& .MuiCheckbox-root': {
          color: '#515151',
        },
        '& .MuiDialogContent-root': {
          padding: '0rem',
        },
      },
    };
  });

  const { classes } = useStyles({ setHeight: dialogHeight });

  const editUsers = () => {
    let usersString = users.toString();
    const editUserDoc: DocumentMetaData = {
      userType: usersString,
    };
    ApiService.patchDocument(rowLink, editUserDoc)
      .then(() => {
        saveFunction();
      })
      .catch((e) => {
        console.log(e);
        saveFunction();
      });
  };

  return (
    <div className={classes.root}>
      <DialogContent className="visibilityDialog_Header">
        <div className="visibilityDialog_CloseContainer">
          <IconButton
            className="visibilityDialog_CloseIcon"
            onClick={() => cancelFunction()}
          >
            <CancelIcon />
          </IconButton>
        </div>
        <p className="visibilityDialog_Text">Who should see this document?</p>
        <p className="visibilityDialog_smallText">{fileName}</p>
      </DialogContent>
      <DialogContent dividers={true} className="visibilityDialog_container">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value="POSDeveloper"
                color="default"
                aria-label="Checkbox POS Developer"
                checked={users.includes('POSDeveloper')}
                onChange={handleChange}
              />
            }
            label={
              <div>
                <span className="visibilityDialog_checkBoxText">
                  POS Developer
                </span>
                <PDChip />
              </div>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                value="POSOperations"
                color="default"
                aria-label="Checkbox POS Operations"
                checked={users.includes('POSOperations')}
                onChange={handleChange}
              />
            }
            label={
              <div>
                <span className="visibilityDialog_checkBoxText">
                  POS Operations
                </span>
                <POChip />
              </div>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                value="AcquirerPaymentIntegrator"
                color="default"
                aria-label="Checkbox Payment Integrator/Acquirer"
                checked={users.includes('AcquirerPaymentIntegrator')}
                onChange={handleChange}
              />
            }
            label={
              <div>
                <span className="visibilityDialog_checkBoxText">
                  Payment Integrator/Acquirer
                </span>
                <PIAChip />
              </div>
            }
          />
        </FormGroup>
      </DialogContent>
      <DialogActions className="visibilityDialog_ButtonContainer">
        {/* Error messaging */}
        {users.length === 0 && (
          <Alert severity="error">
            <p>Please select at least one user type to see this document.</p>
          </Alert>
        )}
        <div className="visibilityDialog_InnerButtonContainer">
          <Button
            className="visibilityDialog_Button"
            variant="secondary"
            onClick={() => cancelFunction()}
          >
            <p className="actionDialog_ButtonText">Cancel</p>
          </Button>
          <Button
            disabled={users.length === 0}
            className="visibilityDialog_Button"
            variant="primary"
            onClick={() => editUsers()}
          >
            <p className="actionDialog_ButtonText">Save changes</p>
          </Button>
        </div>
      </DialogActions>
    </div>
  );
};

export default VisibilityDialog;
