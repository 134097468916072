import { Dialog } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { DocumentRow } from '../../models/documentRow';
import { FactsheetRow } from '../../models/FactsheetRow';
import {
  userTypeKeyToUserTypeName,
  userTypeToString,
} from '../../models/mainnav';
import ApiService from '../../services/ApiService';
import ActionDialog from '../Shared/ActionDialog';
import DocumentTable from '../Shared/DocumentTable';
import FactsheetTable from '../Shared/FactsheetTable';
import PageHeader from '../Shared/PageHeader';
import PageOverlayContent from '../Shared/PageOverlayContent';
import UploadDialog from '../Shared/UploadDialog';
import VisibilityDialog from '../Shared/VisibilityDialog';
import { AxiosResponse } from 'axios';
import './PLBResources.css';
import { PageTitles } from '../../utils/PageTitles';
import { Helmet } from 'react-helmet';

const AdminPLBResources = () => {
  const authContextValue = useContext(AuthContext);
  const [deleteFileName, setDeleteFileName] = React.useState('');
  const [visibilityFileName, setVisibilityFileName] = React.useState('');
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [uploadOpen, setUploadOpen] = React.useState(0);
  const [visibilityOpen, setVisibilityOpen] = React.useState(false);
  const [deleteLink, setDeleteLink] = React.useState('');
  const [visibilityLink, setVisibilityLink] = React.useState('');
  const [visibilityUserType, setVisibilityUserType] = React.useState('');
  const [containerName, setContainerName] = React.useState('');
  const [allData, setAllData] = React.useState<DocumentRow[]>([]);
  const [specificationLoading, specificationIsLoading] = React.useState(true);
  const [templatesLoading, templatesIsLoading] = React.useState(true);
  const [testingLoading, testingIsLoading] = React.useState(true);
  const [factSheetLoading, factSheetIsLoading] = React.useState(true);
  const [specificationTableData, setSpecificationTableData] = React.useState<
    DocumentRow[]
  >([]);
  const [templateTableData, setTemplateTableData] = React.useState<
    DocumentRow[]
  >([]);
  const [testingTableData, setTestingTableData] = React.useState<DocumentRow[]>(
    [],
  );
  const [factSheetData, setFactSheetData] = React.useState<FactsheetRow[]>([]);

  const openDeleteDialog = (row: DocumentRow) => {
    setContainerName(row.type);
    setDeleteFileName(row.name);
    setDeleteLink(row.link);
    setDeleteOpen(true);
  };

  const openVisibilityDialog = (row: DocumentRow) => {
    setVisibilityFileName(row.name);
    setVisibilityLink(row.link);
    setVisibilityUserType(row.role);
    setVisibilityOpen(true);
  };

  const confirmDelete = () => {
    ApiService.deleteDocument(containerName, deleteLink)
      .then(() => {
        let removeFromData = allData.filter((x) => x.link !== deleteLink);
        setAllData(removeFromData);
        setDeleteOpen(false);
        refreshDocuments();
      })
      .catch((e) => {
        console.log(e);
        setDeleteOpen(false);
      });
  };

  const refreshDocuments = () => {
    setUploadOpen(0);
    setVisibilityOpen(false);
    getSpecificationDocuments();
    getTemplateDocuments();
    getTestingDocuments();
    let userTypeName =
      userTypeKeyToUserTypeName[authContextValue.currentUserType as number];
    getFactSheetDocuments(userTypeName);
  };

  const getSpecificationDocuments = () => {
    ApiService.getDocuments('specification')
      .then((x) => {
        let data = x.data;
        let newTable: DocumentRow[] = [];
        for (let dataItem of data) {
          let newRow: DocumentRow = {
            id: newTable.length,
            name: dataItem.fileName,
            date: new Date(dataItem.createdOn),
            link: dataItem.containerName + '/' + dataItem.fileName,
            role: dataItem.userType,
            type: 'specification',
            createdBy: dataItem.createdBy,
            isDownloading: false,
          };
          newTable.push(newRow);
        }
        setSpecificationTableData(newTable);
        specificationIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        specificationIsLoading(false);
      });
  };

  const getTemplateDocuments = () => {
    ApiService.getDocuments('template')
      .then((x) => {
        let data = x.data;
        let newTable: DocumentRow[] = [];
        for (let dataItem of data) {
          let newRow: DocumentRow = {
            id: newTable.length,
            name: dataItem.fileName,
            date: new Date(dataItem.createdOn),
            link: dataItem.containerName + '/' + dataItem.fileName,
            role: dataItem.userType,
            type: 'template',
            createdBy: dataItem.createdBy,
            isDownloading: false,
          };
          newTable.push(newRow);
        }
        setTemplateTableData(newTable);
        templatesIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        templatesIsLoading(false);
      });
  };

  const getTestingDocuments = () => {
    ApiService.getDocuments('testing')
      .then((x) => {
        let data = x.data;
        let newTable: DocumentRow[] = [];
        for (let dataItem of data) {
          let newRow: DocumentRow = {
            id: newTable.length,
            name: dataItem.fileName,
            date: new Date(dataItem.createdOn),
            link: dataItem.containerName + '/' + dataItem.fileName,
            role: dataItem.userType,
            type: 'testing',
            createdBy: dataItem.createdBy,
            isDownloading: false,
          };
          newTable.push(newRow);
        }
        setTestingTableData(newTable);
        testingIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        testingIsLoading(false);
      });
  };

  const getFactSheetDocuments = (userType: any) => {
    ApiService.getFactsheetDocuments(userType)
      .then((x: AxiosResponse) => {
        let data = x.data;
        let newTable: FactsheetRow[] = [];
        for (let dataItem of data.value) {
          let newRow: FactsheetRow = {
            id: newTable.length,
            fileName: dataItem.FactSheet.PLBPortalDisplayName,
            publishedDescription: dataItem.FactSheet.UpdatedDateDescription,
            storageUrl: dataItem.FactSheet.StorageUrl,
          };
          newTable.push(newRow);
        }
        setFactSheetData(newTable);
        factSheetIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        factSheetIsLoading(false);
      });
  };

  useEffect(() => {
    getSpecificationDocuments();
    getTemplateDocuments();
    getTestingDocuments();
    let userTypeName =
      userTypeKeyToUserTypeName[authContextValue.currentUserType as number];
    getFactSheetDocuments(userTypeName);
  }, [authContextValue.currentUserType]);

  return (
    <>
      <Helmet>
        <title>{PageTitles.PLBResources}</title>
      </Helmet>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={deleteOpen}
        aria-describedby="scroll-dialog-description"
      >
        <ActionDialog
          headingActionName="delete this file"
          actionName="delete file"
          fileName={deleteFileName}
          noFunction={() => setDeleteOpen(false)}
          yesFunction={() => confirmDelete()}
        />
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={uploadOpen === 1}
        aria-describedby="scroll-dialog-description"
      >
        <UploadDialog
          closeDialog={() => setUploadOpen(0)}
          confirmDialog={() => refreshDocuments()}
          fileCategory="Specification Document"
          fileContainer={'specification'}
          userName={authContextValue.user?.username as string}
          fileName={visibilityFileName}
          rowLink={visibilityLink}
          cancelFunction={() => setVisibilityOpen(false)}
          saveFunction={() => refreshDocuments()}
        />
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={uploadOpen === 2}
        aria-describedby="scroll-dialog-description"
      >
        <UploadDialog
          closeDialog={() => setUploadOpen(0)}
          confirmDialog={() => refreshDocuments()}
          fileCategory="Template Document"
          fileContainer={'template'}
          userName={authContextValue.user?.username as string}
          fileName={visibilityFileName}
          rowLink={visibilityLink}
          cancelFunction={() => setVisibilityOpen(false)}
          saveFunction={() => refreshDocuments()}
        />
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={uploadOpen === 3}
        aria-describedby="scroll-dialog-description"
      >
        <UploadDialog
          closeDialog={() => setUploadOpen(0)}
          confirmDialog={() => refreshDocuments()}
          fileCategory="Testing Document"
          fileContainer={'testing'}
          userName={authContextValue.user?.username as string}
          fileName={visibilityFileName}
          rowLink={visibilityLink}
          cancelFunction={() => setVisibilityOpen(false)}
          saveFunction={() => refreshDocuments()}
        />
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={visibilityOpen}
        aria-describedby="scroll-dialog-description"
      >
        <VisibilityDialog
          fileName={visibilityFileName}
          rowLink={visibilityLink}
          rowUserType={visibilityUserType}
          cancelFunction={() => setVisibilityOpen(false)}
          saveFunction={() => refreshDocuments()}
        />
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={visibilityOpen}
        aria-describedby="scroll-dialog-description"
      >
        <VisibilityDialog
          fileName={visibilityFileName}
          rowLink={visibilityLink}
          rowUserType={visibilityUserType}
          cancelFunction={() => setVisibilityOpen(false)}
          saveFunction={() => refreshDocuments()}
        />
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={visibilityOpen}
        aria-describedby="scroll-dialog-description"
      >
        <VisibilityDialog
          fileName={visibilityFileName}
          rowLink={visibilityLink}
          rowUserType={visibilityUserType}
          cancelFunction={() => setVisibilityOpen(false)}
          saveFunction={() => refreshDocuments()}
        />
      </Dialog>
      <>
        <PageHeader
          headerTitle="PLB Resources"
          isAdmin={true}
          hasOverlay={true}
        >
          {'See and manage PLB Resources here.\n\n'}
        </PageHeader>
        <PageOverlayContent>
          <div className="resources_padding">
            <DocumentTable
              data={specificationTableData}
              isAccordion={true}
              tableName={'Specifications'}
              isLoading={specificationLoading}
              canSeeUploader={true}
              canSeeVisibility={true}
              canEditVisibility={true}
              canUpload={true}
              canDelete={true}
              deleteFunction={openDeleteDialog}
              uploadFunction={() => setUploadOpen(1)}
              visibilityFunction={openVisibilityDialog}
            />
          </div>
          <div className="resources_padding">
            <DocumentTable
              data={templateTableData}
              isAccordion={true}
              tableName={'Templates'}
              isLoading={templatesLoading}
              canSeeUploader={true}
              canSeeVisibility={true}
              canEditVisibility={true}
              canUpload={true}
              canDelete={true}
              deleteFunction={openDeleteDialog}
              uploadFunction={() => setUploadOpen(2)}
              visibilityFunction={openVisibilityDialog}
            />
          </div>
          <div className="resources_padding">
            <DocumentTable
              data={testingTableData}
              isAccordion={true}
              tableName={'Testing'}
              isLoading={testingLoading}
              canSeeUploader={true}
              canSeeVisibility={true}
              canEditVisibility={true}
              canUpload={true}
              canDelete={true}
              deleteFunction={openDeleteDialog}
              uploadFunction={() => setUploadOpen(3)}
              visibilityFunction={openVisibilityDialog}
            />
          </div>
          <div className="resources_padding">
            <div className="resources_box">
              <FactsheetTable
                currentUserType={authContextValue.currentUserType}
                data={factSheetData}
              />
            </div>
          </div>
        </PageOverlayContent>
      </>
    </>
  );
};

export default AdminPLBResources;
