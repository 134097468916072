import { SetStateAction, useCallback, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ContactDetails } from '../../../models/contactDetails';
import { makeStyles } from 'tss-react/mui';
import {
  CountryOptions,
  StateOptions,
  RoleOptions,
  ABNStatus,
} from '../constants/constant-variables';
import PublicService from '../../../services/PublicService';
import NumberFormat from 'react-number-format';
import config from '../../../config/config.json';
import lodash from 'lodash';
import ModalLink from '../../Shared/ModalLink';
import { RolesDialog } from './RolesDialog';

const useStyles = makeStyles()((theme) => {
  return {
    form_header: {
      textAlign: 'left',
      font: 'normal normal normal 0.8125rem/1.125rem Open Sans',
      letterSpacing: '0',
      color: '#6F7179',
      paddingBottom: '0.937rem',
      paddingLeft: '0.375rem',
    },
    details_title: {
      textAlign: 'center',
      font: 'normal normal bold 2.25rem/4.25rem Open Sans',
      letterSpacing: '0.0081rem',
      color: '#000000',
    },
    details_sub_title: {
      textAlign: 'center',
      font: 'normal normal normal 1.25rem/2.25rem Open Sans',
      letterSpacing: '0',
      padding: '1.3125rem 25% 2.875rem',
    },
    details_inputfield: {
      marginBottom: '0.9375rem',
    },
    details_instruction: {
      textAlign: 'left',
      font: 'normal normal normal 0.75rem/3.4375rem Open Sans',
      letterSpacing: '0',
      color: '#363636',
      opacity: '0.72',
    },
    details_policy: {
      textAlign: 'left',
      letterSpacing: '0',
      color: '#5A7184',
      opacity: '1',
      marginBottom: '0.9375rem',
    },
    details_alertDesc: {
      fontSize: '0.8rem',
    },
    details_errorText: {
      marginLeft: '0.875rem',
      marginTop: '-0.5rem',
      marginBottom: '0.937rem',
      color: '#d32f2f',
    },
    abn_result_box: {
      marginTop: '-0.625rem',
      marginBottom: '0.9375rem',
    },
    details_privatePolicyHighlight: {
      color: 'var(--primary-purple)',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    content_panel: {
      background: '#FFFFFF',
      boxShadow: '0 0.625rem 2.1875rem  #00000008',
      borderRadius: '0.5rem',
      opacity: '1',
      padding: '4.562rem 2.8125rem 6rem',
      maxWidth: '62.5rem',
    },
    details_nextButton: {
      paddingLeft: '3.5rem',
      paddingRight: '3.5rem',
      marginTop: '2.5rem',
    },
    details_flexFormRow: {
      display: 'flex',
    },
    details_flexRow: {
      flex: 1,
    },
    details_errorContainer: {
      display: 'flex',
      marginTop: '1.5rem',
    },
    details_submitError: {
      color: 'var(--primary-black)',
      whiteSpace: 'pre-wrap',
      lineHeight: '1.25rem',
      textAlign: 'left',
    },
    details_errorLinkHighlight: {
      color: '#0000EE',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    details_modalLinkContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  };
});

export interface DetailsProps {
  handleNext: (emailName: string) => void;
  abnStatus: SetStateAction<ABNStatus>;
  setAbnStatus: (abnStatus: SetStateAction<ABNStatus>) => void;
}

const maxlen = config.INPUT_FIELD_MAXLEN;

const requireABN = {
  is: 'AUSTRALIA',
  then: yup
    .string()
    .max(maxlen.ABN, 'Please enter a valid ABN.')
    .required('Please enter your ABN.')
    .transform((value) => {
      return value !== null ? value.replace(/ /g, '') : value;
    })
    .matches(/^[0-9]{11}$/, 'Please enter a valid ABN.'),
};

const initialDetails: ContactDetails = {
  abn: '',
  tradingName: '',
  legalName: '',
  parentCompanyName: '',
  businessPhoneNumber: '',
  businessAddressLine1: '',
  businessAddressLine2: '',
  suburb: '',
  state: '',
  postcode: '',
  country: '',
  primaryContactName: '',
  primaryContactPhone: '',
  primaryContactEmail: '',
  confirmContactEmail: '',
  role: '',
  roleDetails: '',
};

export const Details = (props: DetailsProps) => {
  const { handleNext, abnStatus, setAbnStatus } = props;
  const { classes } = useStyles();
  const contactDetails = initialDetails;
  const [errorSubmitting, setErrorSubmitting] = useState(false);
  const [rolesDialogIsOpen, setRolesDialogIsOpen] = useState(false);

  const isAustralian = () => {
    return formik.values.country === 'AUSTRALIA';
  };

  const handleOpenRolesDialog = () => {
    setRolesDialogIsOpen(true);
  };

  const handleCloseRolesDialog = () => {
    setRolesDialogIsOpen(false);
  };

  const validate = (values: ContactDetails) => {
    const errors: any = {};

    // Show error immediately if user exceeds max length
    const checkLength = (
      fieldLabel: string,
      fieldName: string,
      length: number,
    ) => {
      if (lodash.get(values, fieldName).length > length) {
        lodash.set(formik.touched, fieldName, true);
        errors.fieldName = `${fieldLabel} cannot exceed ${length} characters in length.`;
      }
    };

    if (!isAustralian())
      checkLength('Legal entity name', 'legalName', maxlen.LEGAL_NAME);
    checkLength('Trading name', 'tradingName', maxlen.TRADING_NAME);
    checkLength('ABN', 'abn', maxlen.ABN);
    checkLength(
      'Parent company name',
      'parentCompanyName',
      maxlen.PARENT_COMPANY_NAME,
    );
    checkLength(
      'Business phone number',
      'businessPhoneNumber',
      maxlen.BUSINESS_PHONE,
    );
    checkLength(
      'Business address (line one)',
      'businessAddressLine1',
      maxlen.BUSINESS_ADDRESS_1,
    );
    checkLength(
      'Business address (line two)',
      'businessAddressLine2',
      maxlen.BUSINESS_ADDRESS_2,
    );
    checkLength('Suburb', 'suburb', maxlen.SUBURB);
    checkLength('State', 'state', maxlen.STATE);
    checkLength('Postcode', 'postcode', maxlen.POSTCODE);
    checkLength('Contact name', 'primaryContactName', maxlen.CONTACT_NAME);
    checkLength(
      'Contact phone number',
      'primaryContactPhone',
      maxlen.CONTACT_PHONE,
    );
    checkLength('Contact email', 'primaryContactEmail', maxlen.CONTACT_EMAIL);
    checkLength('Role details', 'roleDetails', maxlen.ROLE_DETAILS);

    // Show errors immediately if illegal character is found
    const checkIllegalChars = (fieldName: string) => {
      const regexIllegal = new RegExp('[<>;&]');

      if (regexIllegal.test(lodash.get(values, fieldName))) {
        lodash.set(formik.touched, fieldName, true);
        errors[fieldName] =
          'The following characters are not allowed in this field: < > ; &';
      }
    };

    const checkIllegalCharsBusiness = (fieldName: string) => {
      const regexIllegalLegalName = new RegExp('[<>;]');

      if (regexIllegalLegalName.test(lodash.get(values, fieldName))) {
        lodash.set(formik.touched, fieldName, true);
        errors[fieldName] =
          'The following characters are not allowed in this field: < > ;';
      }
    };

    if (!isAustralian()) checkIllegalCharsBusiness('legalName');
    checkIllegalCharsBusiness('tradingName');
    checkIllegalChars('abn');
    checkIllegalCharsBusiness('parentCompanyName');
    checkIllegalChars('businessPhoneNumber');
    checkIllegalChars('businessAddressLine1');
    checkIllegalChars('businessAddressLine2');
    checkIllegalChars('suburb');
    checkIllegalChars('state');
    checkIllegalChars('postcode');
    checkIllegalChars('primaryContactName');
    checkIllegalChars('primaryContactPhone');
    checkIllegalChars('primaryContactEmail');
    checkIllegalChars('confirmContactEmail');
    checkIllegalChars('roleDetails');

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      abn: contactDetails.abn,
      tradingName: contactDetails.tradingName,
      legalName: contactDetails.legalName,
      parentCompanyName: contactDetails.parentCompanyName,
      businessPhoneNumber: contactDetails.businessPhoneNumber,
      businessAddressLine1: contactDetails.businessAddressLine1,
      businessAddressLine2: contactDetails.businessAddressLine2,
      suburb: contactDetails.suburb,
      state: contactDetails.state,
      postcode: contactDetails.postcode,
      country: contactDetails.country || 'AUSTRALIA',
      primaryContactName: contactDetails.primaryContactName,
      primaryContactPhone: contactDetails.primaryContactPhone,
      primaryContactEmail: contactDetails.primaryContactEmail,
      confirmContactEmail: contactDetails.confirmContactEmail,
      role: contactDetails.role,
      roleDetails: contactDetails.roleDetails,
    },
    validationSchema: yup.object({
      legalName: yup.string().when('country', {
        is: (country: string) => country !== 'AUSTRALIA',
        then: yup
          .string()
          .max(
            maxlen.LEGAL_NAME,
            'Legal entity name cannot exceed 200 characters in length.',
          )
          .required('Please enter your legal entity name.'),
      }),
      tradingName: yup
        .string()
        .max(
          maxlen.TRADING_NAME,
          'Trading name cannot exceed 100 characters in length.',
        ),
      abn: yup.string().when('country', requireABN),
      parentCompanyName: yup
        .string()
        .max(
          maxlen.PARENT_COMPANY_NAME,
          'Parent company name cannot exceed 100 characters in length.',
        ),
      businessPhoneNumber: yup
        .string()
        .max(
          maxlen.BUSINESS_PHONE,
          'Phone number cannot exceed 20 characters in length.',
        )
        .matches(
          /^[+]?[0-9 ]{0,20}$/,
          'Please enter a valid phone number using digits, spaces and + only.',
        ),
      businessAddressLine1: yup
        .string()
        .max(
          maxlen.BUSINESS_ADDRESS_1,
          'Address cannot exceed 100 characters in length.',
        )
        .required('Please enter your business address.'),
      businessAddressLine2: yup
        .string()
        .max(
          maxlen.BUSINESS_ADDRESS_2,
          'Address cannot exceed 100 characters in length.',
        ),
      suburb: yup
        .string()
        .max(
          maxlen.SUBURB,
          'Town/suburb cannot exceed 50 characters in length.',
        )
        .required('Please enter your town/suburb.'),
      state: yup
        .string()
        .max(
          maxlen.STATE,
          'State/territory cannot exceed 100 characters in length.',
        )
        .required('Please enter your state/territory.'),
      postcode: yup
        .string()
        .max(maxlen.POSTCODE, 'Postcode cannot exceed 12 characters in length.')
        .required('Please enter your postcode.'),
      country: yup.string().required('Please select your Country.'),
      primaryContactName: yup
        .string()
        .max(
          maxlen.CONTACT_NAME,
          'Contact name cannot exceed 50 characters in length.',
        )
        .required('Please enter your contact name.'),
      primaryContactPhone: yup
        .string()
        .required('Please enter your contact phone number.')
        .max(
          maxlen.CONTACT_PHONE,
          'Contact phone number cannot exceed 20 characters in length.',
        )
        .matches(
          /^[+]?[0-9 ]{1,20}$/,
          'Please enter a valid phone number using digits, spaces and + only.',
        ),
      primaryContactEmail: yup
        .string()
        .max(
          maxlen.CONTACT_EMAIL,
          'Email address cannot exceed 320 characters in length.',
        )
        .required('Please enter your contact email address.')
        .matches(/^.+@{1}.+[.].+$/, 'Please enter a valid email address.'),
      confirmContactEmail: yup
        .string()
        .required('Please re-enter your contact email address.')
        .oneOf(
          [yup.ref('primaryContactEmail'), null],
          'Your contact email address does not match.',
        ),
      roleDetails: yup
        .string()
        .max(
          maxlen.ROLE_DETAILS,
          'Role details cannot exceed 100 characters in length.',
        ),
    }),
    validate,
    onSubmit: (values, { setSubmitting }) => {
      if (
        formik.values.country !== 'AUSTRALIA' ||
        abnStatus === ABNStatus.Valid ||
        abnStatus === ABNStatus.APIError
      ) {
        handleSubmit(values, setSubmitting);
      } else {
        window.scrollTo(0, 0);
        setSubmitting(false);
      }
    },
  });

  const handleSubmit = (
    values: ContactDetails,
    setSubmit: (isSubmitting: boolean) => void,
  ) => {
    setSubmit(true);
    formik.submitCount = formik.submitCount + 1;
    setErrorSubmitting(false);
    PublicService.sendEOIContactDetails(values)
      .then(() => {
        window.scrollTo(0, 0);
        handleNext(formik.values.primaryContactEmail);
      })
      .catch(() => {
        console.log('Catch Submit Failure');
        setErrorSubmitting(true);
        setSubmit(false);
        formik.isValidating = false;
      });
  };

  const handleCountryChange = useCallback(
    (e: SelectChangeEvent) => {
      var prevCountry = formik.values.country.trim().toUpperCase();
      var newCountry = e.target.value.trim().toUpperCase();

      formik.setFieldValue('state', '');

      if (newCountry === 'AUSTRALIA') {
        formik.setFieldValue('legalName', '');
        lodash.set(formik.touched, 'abn', false);
      } else {
        formik.setFieldValue('abn', '');
        setAbnStatus(ABNStatus.InitialState);

        // Only reset legalName if changing from AUSTRALIA to international
        if (prevCountry === 'AUSTRALIA') {
          formik.setFieldValue('legalName', '');
          lodash.set(formik.touched, 'legalName', false);
        }
      }

      formik.handleChange(e);
    },
    [formik.values.country],
  );

  const handleRoleChange = (selectedRole: string) => {
    if (selectedRole !== 'Other') {
      formik.setFieldValue('roleDetails', '');
    }
  };

  const resetLegalName = () => {
    formik.setFieldValue('legalName', '');
  };

  const openPrivatePolicy = () => {
    window.open(config.PRIVACY_POLICY);
  };

  const openEmailLink = () => {
    window.open('mailto:' + config.SERVICES_AUS_EMAIL);
  };

  const removeSpaces = (string: string) => {
    return string.replace(/ /g, '');
  };

  // Will format ABN to correct format: 18008476944 -> 18 008 476 944
  const formatAbn = (rawAbn: string) => {
    const strippedAbn = removeSpaces(rawAbn);
    return `${strippedAbn.substring(0, 2)}${
      strippedAbn.substring(2, 5) ? ' ' + strippedAbn.substring(2, 5) : ''
    }${strippedAbn.substring(5, 8) ? ' ' + strippedAbn.substring(5, 8) : ''}${
      strippedAbn.substring(8, 11) ? ' ' + strippedAbn.substring(8, 11) : ''
    }${strippedAbn.substring(11)}`;
  };

  const validateAbn = (abn: string) => {
    resetLegalName();

    if (abn.length > 0) {
      PublicService.getABNDetails(abn).then(
        (response) => {
          const xmlDoc = new DOMParser().parseFromString(
            response.data,
            'text/xml',
          );
          if (!xmlDoc.getElementsByTagName('exception')[0]) {
            setAbnStatus(ABNStatus.Valid);
            getLegalName(xmlDoc);
          } else {
            setAbnStatus(ABNStatus.Invalid);
          }
        },
        (error) => {
          console.log(error);
          setAbnStatus(ABNStatus.APIError);
        },
      );
    }
  };

  const getLegalName = useCallback((xmlDoc: Document) => {
    if (
      xmlDoc.getElementsByTagName('entityTypeCode')[0].textContent === 'IND'
    ) {
      const givenName = xmlDoc.getElementsByTagName('givenName')[0].textContent;
      const otherGivenName =
        xmlDoc.getElementsByTagName('otherGivenName')[0].textContent;
      const familyName =
        xmlDoc.getElementsByTagName('familyName')[0].textContent;

      formik.setFieldValue(
        'legalName',
        `${familyName ? familyName : ''}${
          familyName && (givenName || otherGivenName) ? ', ' : ''
        }${givenName ? givenName : ''}${
          givenName && otherGivenName ? ' ' : ''
        }${otherGivenName ? otherGivenName : ''}`,
      );
    } else {
      const organisationName = xmlDoc
        .getElementsByTagName('mainName')[0]
        .getElementsByTagName('organisationName')[0].textContent;
      formik.setFieldValue(
        'legalName',
        organisationName ? organisationName : '',
      );
    }
  }, []);

  return (
    <Box className={classes.content_panel}>
      <Typography className={classes.details_title}>
        Expression of interest
      </Typography>
      <Typography className={classes.details_sub_title}>
        {
          'Please provide your details and a Services Australia PLB team member will be in touch.'
        }
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Typography className={classes.form_header}>
          ORGANISATION DETAILS
        </Typography>

        <FormControl
          variant="filled"
          fullWidth
          className={classes.details_inputfield}
        >
          <InputLabel
            id="details_country_label"
            error={formik.touched.country && Boolean(formik.errors.country)}
          >
            Country*
          </InputLabel>
          <Select
            labelId="details_country_label"
            label="Country*"
            id="country"
            name="country"
            value={formik.values.country}
            onChange={(e) => {
              handleCountryChange(e);
            }}
            error={formik.touched.country && Boolean(formik.errors.country)}
            inputProps={{ 'data-testid': 'eoi-select-country' }}
          >
            {CountryOptions.sort((a, b) => (a.name > b.name ? 1 : -1)).map(
              (el, index) => (
                <MenuItem value={el.name} key={'country' + index}>
                  {el.name}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
        {formik.touched.country && formik.errors.country && (
          <FormHelperText className={classes.details_errorText}>
            formik.errors.country
          </FormHelperText>
        )}

        {isAustralian() && (
          <TextField
            className={classes.details_inputfield}
            fullWidth
            id="abn"
            name="abn"
            label="ABN*"
            variant="filled"
            value={formik.values.abn}
            onChange={(e) => {
              setAbnStatus(ABNStatus.InitialState);
              resetLegalName();
              formik.handleChange(e);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              if (!Boolean(formik.errors.abn)) {
                validateAbn(e.target.value);
              }
            }}
            onBlurCapture={(e) => {
              formik.values.abn = formatAbn(formik.values.abn);
              formik.handleBlur(e);
            }}
            error={formik.touched.abn && Boolean(formik.errors.abn)}
            inputProps={{ 'data-testid': 'eoi-abn' }}
            helperText={
              formik.touched.abn && Boolean(formik.errors.abn)
                ? formik.errors.abn
                : ''
            }
          />
        )}
        {isAustralian() &&
          abnStatus === ABNStatus.Valid &&
          formik.values.legalName && (
            <Alert className={classes.abn_result_box}>
              ABN found
              <br />
              <span className={classes.details_alertDesc}>
                Your legal entity name has been automatically entered below.
              </span>
            </Alert>
          )}
        {isAustralian() &&
          abnStatus === ABNStatus.Valid &&
          formik.values.legalName === '' && (
            <Alert className={classes.abn_result_box}>
              ABN found
              <br />
              <span className={classes.details_alertDesc}>
                Legal entity name data unavailable. The following field will be
                left blank. Please proceed to the 'Trading name' field.
              </span>
            </Alert>
          )}
        {isAustralian() && abnStatus === ABNStatus.Invalid && (
          <Alert severity="error" className={classes.abn_result_box}>
            ABN not found
            <br />
            <span className={classes.details_alertDesc}>
              <NumberFormat
                value={formik.values.abn}
                displayType={'text'}
                format="## ### ### ####"
              />
              &nbsp;is not a valid ABN.
            </span>
          </Alert>
        )}
        {isAustralian() && abnStatus === ABNStatus.APIError && (
          <Alert severity="warning" className={classes.abn_result_box}>
            An unknown error occured.
            <br />
            <span className={classes.details_alertDesc}>
              Legal entity name data unavailable. The following field will be
              left blank. Please proceed to the 'Trading name' field.
            </span>
          </Alert>
        )}

        <TextField
          fullWidth
          disabled={isAustralian()}
          id="legalName"
          label={isAustralian() ? 'Legal entity name' : 'Legal entity name*'}
          className={classes.details_inputfield}
          variant="filled"
          onChange={formik.handleChange}
          value={formik.values.legalName}
          onBlur={formik.handleBlur}
          error={
            formik.touched.legalName &&
            Boolean(formik.errors.legalName) &&
            !isAustralian()
          }
          inputProps={{ 'data-testid': 'eoi-legalName' }}
          helperText={
            formik.touched.legalName &&
            formik.errors.legalName &&
            !isAustralian()
              ? formik.errors.legalName
              : ''
          }
        />

        <TextField
          className={classes.details_inputfield}
          fullWidth
          id="tradingName"
          label="Trading name"
          variant="filled"
          value={formik.values.tradingName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.tradingName && Boolean(formik.errors.tradingName)
          }
          inputProps={{ 'data-testid': 'eoi-tradingName' }}
          helperText={
            formik.touched.tradingName && formik.errors.tradingName
              ? formik.errors.tradingName
              : ''
          }
        />

        <TextField
          className={classes.details_inputfield}
          fullWidth
          id="parentCompanyName"
          label="Parent company name"
          variant="filled"
          value={formik.values.parentCompanyName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.parentCompanyName &&
            Boolean(formik.errors.parentCompanyName)
          }
          inputProps={{ 'data-testid': 'eoi-parentCompanyName' }}
          helperText={
            formik.touched.parentCompanyName && formik.errors.parentCompanyName
              ? formik.errors.parentCompanyName
              : ''
          }
        />
        <TextField
          className={classes.details_inputfield}
          fullWidth
          id="businessPhoneNumber"
          label="Business phone number"
          variant="filled"
          value={formik.values.businessPhoneNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.businessPhoneNumber &&
            Boolean(formik.errors.businessPhoneNumber)
          }
          inputProps={{ 'data-testid': 'eoi-businessPhoneNumber' }}
          helperText={
            formik.touched.businessPhoneNumber &&
            formik.errors.businessPhoneNumber
              ? formik.errors.businessPhoneNumber
              : ''
          }
        />
        <TextField
          className={classes.details_inputfield}
          fullWidth
          id="businessAddressLine1"
          label="Business address (line one)*"
          variant="filled"
          value={formik.values.businessAddressLine1}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.businessAddressLine1 &&
            Boolean(formik.errors.businessAddressLine1)
          }
          inputProps={{ 'data-testid': 'eoi-businessAddressLine1' }}
          helperText={
            formik.touched.businessAddressLine1 &&
            formik.errors.businessAddressLine1
              ? formik.errors.businessAddressLine1
              : ''
          }
        />
        <TextField
          className={classes.details_inputfield}
          fullWidth
          id="businessAddressLine2"
          label="Business address (line two)"
          variant="filled"
          value={formik.values.businessAddressLine2}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.businessAddressLine2 &&
            Boolean(formik.errors.businessAddressLine2)
          }
          inputProps={{ 'data-testid': 'eoi-businessAddressLine2' }}
          helperText={
            formik.touched.businessAddressLine2 &&
            formik.errors.businessAddressLine2
              ? formik.errors.businessAddressLine2
              : ''
          }
        />

        <TextField
          className={classes.details_inputfield}
          fullWidth
          id="suburb"
          label="Town/suburb*"
          variant="filled"
          value={formik.values.suburb}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.suburb && Boolean(formik.errors.suburb)}
          inputProps={{ 'data-testid': 'eoi-suburb' }}
          helperText={
            formik.touched.suburb && formik.errors.suburb
              ? formik.errors.suburb
              : ''
          }
        />

        <div className={classes.details_flexFormRow}>
          {!isAustralian() ? (
            <TextField
              className={classes.details_inputfield}
              id="state"
              label="State/territory*"
              variant="filled"
              sx={{ flex: 1 }}
              fullWidth
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.state && Boolean(formik.errors.state)}
              inputProps={{ 'data-testid': 'eoi-state-textfield' }}
              helperText={
                formik.touched.state && formik.errors.state
                  ? formik.errors.state
                  : ''
              }
            />
          ) : (
            <div className={classes.details_flexRow}>
              <FormControl
                variant="filled"
                fullWidth
                className={classes.details_inputfield}
              >
                <InputLabel
                  id="details_stateTerritory_label"
                  error={formik.touched.state && Boolean(formik.errors.state)}
                >
                  State/territory*
                </InputLabel>
                <Select
                  labelId="details_stateTerritory_label"
                  label="State/territory*"
                  id="state"
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  inputProps={{ 'data-testid': 'eoi-state-select' }}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                >
                  {StateOptions.map((el, index) => (
                    <MenuItem value={el.name} key={'state' + index}>
                      {el.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormHelperText className={classes.details_errorText}>
                {formik.touched.state && formik.errors.state
                  ? formik.errors.state
                  : ''}
              </FormHelperText>
            </div>
          )}
          <TextField
            className={classes.details_inputfield}
            id="postcode"
            label="Postcode*"
            variant="filled"
            fullWidth
            sx={{ flex: 1, marginLeft: '1rem' }}
            value={formik.values.postcode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.postcode && Boolean(formik.errors.postcode)}
            inputProps={{ 'data-testid': 'eoi-postcode' }}
            helperText={
              formik.touched.postcode && formik.errors.postcode
                ? formik.errors.postcode
                : ''
            }
          />
        </div>

        <Typography className={classes.form_header}>PRIMARY CONTACT</Typography>
        <TextField
          className={classes.details_inputfield}
          id="primaryContactName"
          label="Contact name*"
          variant="filled"
          fullWidth
          value={formik.values.primaryContactName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.primaryContactName &&
            Boolean(formik.errors.primaryContactName)
          }
          inputProps={{ 'data-testid': 'eoi-primaryContactName' }}
          helperText={
            formik.touched.primaryContactName &&
            formik.errors.primaryContactName
              ? formik.errors.primaryContactName
              : ''
          }
        />
        <TextField
          className={classes.details_inputfield}
          id="primaryContactPhone"
          name="primaryContactPhone"
          fullWidth
          label="Contact phone number*"
          variant="filled"
          value={formik.values.primaryContactPhone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.primaryContactPhone &&
            Boolean(formik.errors.primaryContactPhone)
          }
          inputProps={{ 'data-testid': 'eoi-primaryContactPhone' }}
          helperText={
            formik.touched.primaryContactPhone &&
            formik.errors.primaryContactPhone
              ? formik.errors.primaryContactPhone
              : ''
          }
        />
        <TextField
          className={classes.details_inputfield}
          id="primaryContactEmail"
          label="Contact email address*"
          fullWidth
          variant="filled"
          value={formik.values.primaryContactEmail}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.primaryContactEmail &&
            Boolean(formik.errors.primaryContactEmail)
          }
          inputProps={{ 'data-testid': 'eoi-primaryContactEmail' }}
          helperText={
            formik.touched.primaryContactEmail &&
            formik.errors.primaryContactEmail
              ? formik.errors.primaryContactEmail
              : ''
          }
        />
        <TextField
          className={classes.details_inputfield}
          id="confirmContactEmail"
          label="Confirm email address*"
          fullWidth
          variant="filled"
          value={formik.values.confirmContactEmail}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.confirmContactEmail &&
            Boolean(formik.errors.confirmContactEmail)
          }
          inputProps={{ 'data-testid': 'eoi-confirmContactEmail' }}
          helperText={
            formik.touched.confirmContactEmail &&
            formik.errors.confirmContactEmail
              ? formik.errors.confirmContactEmail
              : ''
          }
        />

        <Box className={classes.details_modalLinkContainer}>
          <ModalLink
            open={rolesDialogIsOpen}
            handleOpen={handleOpenRolesDialog}
            handleClose={handleCloseRolesDialog}
            dialog={<RolesDialog closeFunction={handleCloseRolesDialog} />}
            form
          >
            More information
          </ModalLink>
        </Box>
        <FormControl
          variant="filled"
          fullWidth
          className={classes.details_inputfield}
        >
          <InputLabel
            id="details_role_label"
            error={formik.touched.role && Boolean(formik.errors.role)}
          >
            Contact role
          </InputLabel>
          <Select
            labelId="details_role_label"
            label="Contact role"
            id="role"
            name="role"
            value={formik.values.role}
            onChange={(e) => {
              formik.handleChange(e);
              handleRoleChange(e.target.value);
            }}
            inputProps={{ 'data-testid': 'eoi-role' }}
            error={formik.touched.role && Boolean(formik.errors.role)}
          >
            {RoleOptions.map((el, index) => (
              <MenuItem value={el.name} key={'role' + index}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {formik.touched.role && formik.errors.role && (
          <FormHelperText className={classes.details_errorText}>
            formik.errors.role
          </FormHelperText>
        )}

        {formik.values.role === 'Other' && (
          <TextField
            className={classes.details_inputfield}
            id="roleDetails"
            label="Role Details"
            fullWidth
            variant="filled"
            value={formik.values.roleDetails}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.roleDetails && Boolean(formik.errors.roleDetails)
            }
            inputProps={{ 'data-testid': 'eoi-roleDetails' }}
            helperText={
              formik.touched.roleDetails && formik.errors.roleDetails
                ? formik.errors.roleDetails
                : ''
            }
          />
        )}

        <Typography className={classes.details_instruction}>
          * indicates mandatory fields
        </Typography>
        <Typography className={classes.details_policy}>
          By submitting this form you agree to our{' '}
          <span
            className={classes.details_privatePolicyHighlight}
            onKeyPress={(e) => e.key === 'Enter' && openPrivatePolicy()}
            tabIndex={0}
            onClick={() => openPrivatePolicy()}
          >
            Privacy Policy
          </span>{' '}
          which explains how we may collect, use and disclose your personal
          information.
        </Typography>

        {errorSubmitting && (
          <Alert severity="error" className={classes.details_errorContainer}>
            <Typography className={classes.details_submitError}>
              <b>{'Something went wrong'}</b>
              {
                '\n\nAn error occurred, and your form could not be submitted at this time. Please try again.\n\nIf this error continues to occur, please contact us at '
              }
              <span
                className={classes.details_errorLinkHighlight}
                onKeyPress={(e) => e.key === 'Enter' && openEmailLink()}
                tabIndex={0}
                onClick={() => openEmailLink()}
              >
                {config.SERVICES_AUS_EMAIL}
              </span>
              {'.'}
            </Typography>
          </Alert>
        )}

        <div>
          <Button
            className={classes.details_nextButton}
            disabled={!formik.isValid || formik.isSubmitting}
            type="submit"
            variant="primary"
          >
            Submit
          </Button>
        </div>
      </form>
    </Box>
  );
};
