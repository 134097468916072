import React, {
  FunctionComponent,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export interface LoaderProps {
  isLoading: boolean;
  loadingContent?: ReactNode;
  size?: number | string;
  children: ReactNode;
}

const useStyles = makeStyles()((theme) => {
  return {
    centerFlex: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      paddingTop: '3rem',
      paddingBottom: '3rem',
    },
    purpleLoader: {
      '&.MuiCircularProgress-colorPrimary': {
        color: 'var(--primary-purple)',
      },
    },
  };
});

const loadingTimeout = 1000;

export const Loader: FunctionComponent<LoaderProps> = (props) => {
  const { classes } = useStyles();
  const { isLoading, loadingContent, size, children } = props;

  const [debounceDelay, setdebounceDelay] = useState(true);

  useEffect(() => {
    const handler = setTimeout(() => {
      setdebounceDelay(false);
    }, loadingTimeout);
    return () => {
      clearTimeout(handler);
    };
  }, [isLoading]);

  if (isLoading) {
    if (debounceDelay) {
      return (
        <div className={classes.centerFlex}>
          <CircularProgress
            size={size !== null ? size : 1}
            className={classes.purpleLoader}
          />
        </div>
      );
    }
    if (loadingContent !== undefined) {
      return <>{loadingContent}</>;
    }
    return (
      <div className={classes.centerFlex}>
        <CircularProgress
          size={size !== null ? size : 1}
          className={classes.purpleLoader}
        />
      </div>
    );
  }
  if (debounceDelay !== true) {
    setdebounceDelay(true);
  }
  return <>{children}</>;
};
