import React, { useEffect } from 'react';
import {
  Button,
  IconButton,
  DialogContent,
  DialogActions,
  DialogTitle,
  Alert,
  Link,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import Dropzone from 'react-dropzone';
import { StakeholderDocumentMeta } from '../../../models/stakeholderDocumentMeta';
import ApiService from '../../../services/ApiService';
import { makeStyles } from 'tss-react/mui';
import action_button_upload from '../../../assets/icons/action_button_upload.svg';
import './StakeholderUploadDialog.css';
import {
  fileValidation,
  getStakeholderContainerName,
} from '../../../utils/StakeholderDocumentUtil';
import { Loader } from '../../../utils/Loader';
import config from '../../../config/config.json';

export interface StakeholderUploadDialogProps {
  closeDialog(): void;
  confirmDialog(): void;
  fileCategory: string;
  stakeholderName?: string;
  userName: string;
  admin: boolean;
}

export const StakeholderUploadDialog = (
  props: StakeholderUploadDialogProps,
) => {
  const {
    closeDialog,
    userName,
    fileCategory,
    stakeholderName,
    admin,
    confirmDialog,
  } = props;
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [newFileName, setFileName] = React.useState<string | null>(null);
  const [newFileType, setFileType] = React.useState<string>('');
  const [newFileContent, setFileContent] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string[]>([]);
  const [timedOut, setTimedOut] = React.useState<boolean>(false);
  const [genericError, setGenericError] = React.useState<boolean>(false);
  const [fileTypeError, setFileTypeError] = React.useState<boolean>(false);
  const [noFileExtensionError, setNoFileExtensionError] =
    React.useState<boolean>(false);
  const [zipFileError, setZipFileError] = React.useState<boolean>(false);
  const [zipFileErrorMessage, setZipFileErrorMessage] =
    React.useState<string>('');

  const useStyles = makeStyles<{ setHeight: string }>()((theme) => {
    return {
      root: {
        '& .MuiDialogContent-root': {
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 0',
          height: dialogHeight,
          justifyContent: 'center',
        },
        '& .MuiDialogActions-root': {
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          minHeight: '7.8125rem',
          paddingTop: '2.1875rem',
          paddingBottom: '2.5rem',
        },
        '& .MuiAlert-root': {
          width: '100%',
          font: 'normal normal normal 0.8125rem/1.5rem Open Sans',
          letterSpacing: '0.0075rem',
          color: '#8A1010',
          opacity: '1',
          maxHeight: '6.25rem',
        },
        '& .MuiAlert-message': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'left',
          padding: '0rem',
          '& p': {
            margin: '0rem',
          },
        },
        '& .MuiFormGroup-root': {
          paddingTop: '1.25rem',
          paddingBottom: '3.5rem',
          flex: '1 1 0',
        },
        '& .MuiIconButton-root': {
          padding: '0rem',
        },
        '& .MuiDialogTitle-root': {
          padding: '1rem 1rem 0rem',
          lineHeight: '0rem',
        },
      },
      uploadFileAlert: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '0.5rem',
        padding: '0rem 0rem 0rem',
        background: 'none',
        '& p': {
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          font: 'normal normal normal 0.75rem/1.0625rem Open Sans',
          color: '#606060',
          backgroundColor: '#F8F8F8',
          padding: '0.25rem 1rem',
          overflowWrap: 'anywhere',
        },
      },
      errorAlertPadding: {
        padding: '0.625rem 1rem 0.75rem',
        marginBottom: '0.8125rem',
        whiteSpace: 'break-spaces',
      },
    };
  });

  const [dialogHeight, setDialogHeight] = React.useState<string>('13.6875rem');
  useEffect(() => {
    if (newFileName === null) {
      setDialogHeight('13.6875rem');
    } else {
      setDialogHeight('16.4375rem');
    }
  }, [newFileName]);

  const { classes } = useStyles({ setHeight: dialogHeight });
  const isReadyToUpload = () => {
    if (newFileName !== null && errorMessage.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const onDrop = async (acceptedFiles: any[]) => {
    let availableFiles: string[] = [];
    if (admin) {
      let containerName = getStakeholderContainerName(stakeholderName || '');

      await ApiService.getStakeholderDocumentsByContainer(containerName).then(
        (x) => {
          let data = x.data;
          for (let document of data) {
            availableFiles.push(document.fileName);
          }
        },
      );
    } else {
      await ApiService.getStakeholderDocuments().then((x) => {
        let data = x.data;
        for (let document of data) {
          availableFiles.push(document.fileName);
        }
      });
    }

    setTimedOut(false);
    setGenericError(false);
    setFileTypeError(false);
    setNoFileExtensionError(false);
    setZipFileError(false);
    setZipFileErrorMessage('');

    let fileName = acceptedFiles[0].name;
    let fileType = acceptedFiles[0].type;
    let fileSize = acceptedFiles[0].size;

    // If Dropzone fails to detect file type, attempt to attain extension from file name.
    if (fileType === '') {
      var fileNameContent = fileName.split('.');
      if (fileNameContent.length > 1) {
        var fileNameExtension = fileNameContent[fileNameContent.length - 1];
        if (fileNameExtension !== null) {
          fileType = fileNameExtension;
        }
      }
    }

    let validationErrorMessages = fileValidation(
      'Stakeholder Document',
      availableFiles,
      fileName,
      fileType,
      fileSize,
      props.stakeholderName ?? '',
    );
    setErrorMessage(validationErrorMessages);

    setFileName(fileName);
    setFileType(fileType);
    let reader = new FileReader();
    reader.onload = function (e) {
      if (e.target !== null) {
        let contents = e.target.result as string;
        setFileContent(contents);
      }
    };
    reader.readAsDataURL(acceptedFiles[0]);
  };

  const uploadFile = () => {
    setIsUploading(true);
    const uploadDoc: StakeholderDocumentMeta = {
      fileName: newFileName as string,
      fileExtension: newFileType.split('/')[1],
      data: newFileContent.split(',')[1],
      createdBy: userName,
      category: fileCategory,
      purpose: 'Portal Upload',
      lastModified: new Date().toJSON(),
      createdOn: new Date().toJSON(),
    };

    let getExtension = uploadDoc.fileName.split('.');
    if (getExtension.length === 1) {
      setIsUploading(false);
      setNoFileExtensionError(true);
      return;
    }

    let fileExtension = getExtension[getExtension.length - 1];
    if (fileExtension !== null) {
      let getFileWhitelist = `${process.env.REACT_APP_FILE_EXTENSION_WHITELIST}`;
      let fileWhitelist = getFileWhitelist.split(',');
      if (!fileWhitelist.includes(fileExtension.toLowerCase())) {
        setIsUploading(false);
        setFileTypeError(true);
        return;
      }
    }

    if (admin === false) {
      ApiService.postStakeholderDocument(uploadDoc)
        .then(() => {
          confirmDialog();
        })
        .catch((e) => {
          setIsUploading(false);
          handleUploadError(e);
        });
    } else {
      ApiService.postStakeholderDocumentAdmin(uploadDoc, stakeholderName)
        .then(() => {
          confirmDialog();
        })
        .catch((e) => {
          setIsUploading(false);
          handleUploadError(e);
        });
    }
  };

  // if there's a response, check if it's the file type error
  // other error responses will display the generic error
  // if there's no response, but there's a request, it means it's timed out
  // catch all if the error doesn't contain a request or response, provide the generic error
  const handleUploadError = (e: any) => {
    if (e.response) {
      if (e.response.status === 400) {
        let desc = e.response.data.Detail.Reasons[0].Description as string;
        if (desc.includes('File type is not supported')) {
          setFileTypeError(true);
        } else if (
          desc.includes('Unsupported file types detected within zip file')
        ) {
          setZipFileError(true);
          setZipFileErrorMessage(desc);
        } else {
          setGenericError(true);
        }
      } else {
        setGenericError(true);
      }
    } else if (e.request) {
      setTimedOut(true);
    } else {
      setGenericError(true);
    }
  };

  return (
    <div className={classes.root}>
      <DialogTitle className="stakeholderUploadDialog_Header">
        <div className="stakeholderUploadDialog_CloseContainer">
          <IconButton
            disabled={isUploading}
            className="stakeholderUploadDialog_CloseIcon"
            onClick={() => closeDialog()}
          >
            <CancelIcon />
          </IconButton>
        </div>
        <p className="stakeholderUploadDialog_Text">Upload document</p>
      </DialogTitle>
      <DialogContent
        dividers={true}
        className="stakeholderUploadDialog_container"
      >
        {!isUploading && (
          <Dropzone onDrop={(e) => onDrop(e)} multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                className="stakeholderUploadDialog_Dropzone"
              >
                <input {...getInputProps()} />
                <img
                  className="stakeholderUploadDialog_uploadIcon"
                  src={action_button_upload}
                  alt="Upload button"
                />
                {newFileName !== null && (
                  <Alert className={classes.uploadFileAlert} icon={false}>
                    <p>{newFileName}</p>
                  </Alert>
                )}
                <p
                  id="scroll-dialog-description"
                  className="stakeholderUploadDialog_smallText"
                >
                  {`Drop a single file to ${
                    newFileName === null ? 'select' : 'replace'
                  }\nOr `}
                  <span className="stakeholderUploadDialog_highlight">
                    browse
                  </span>
                </p>
              </div>
            )}
          </Dropzone>
        )}
        {isUploading && <Loader isLoading={isUploading} children={undefined} />}
      </DialogContent>
      <DialogActions
        className="stakeholderUploadDialog_ButtonContainer"
        disableSpacing={true}
      >
        {errorMessage.length !== 0 && (
          <Alert className={classes.errorAlertPadding} severity="error">
            {errorMessage.map((message) => (
              <p>{message}</p>
            ))}
          </Alert>
        )}
        {noFileExtensionError && (
          <Alert className={classes.errorAlertPadding} severity="error">
            <p>
              Files without a file extension are not allowed. Please contact
              Services Australia at{' '}
              <Link href={'mailto:' + config.SERVICES_AUS_EMAIL}>
                {config.SERVICES_AUS_EMAIL}
              </Link>{' '}
              if you need to submit this file.
            </p>
          </Alert>
        )}
        {fileTypeError && (
          <Alert className={classes.errorAlertPadding} severity="error">
            <p>
              File type is not supported. File cannot be uploaded. Please
              contact Services Australia at{' '}
              <Link href={'mailto:' + config.SERVICES_AUS_EMAIL}>
                {config.SERVICES_AUS_EMAIL}
              </Link>{' '}
              if you need to submit this file.
            </p>
          </Alert>
        )}
        {zipFileError && (
          <Alert className={classes.errorAlertPadding} severity="error">
            <p>
              {zipFileErrorMessage + ' '}
              Please contact Services Australia at{' '}
              <Link href={'mailto:' + config.SERVICES_AUS_EMAIL}>
                {config.SERVICES_AUS_EMAIL}
              </Link>{' '}
              if you need to submit this file.
            </p>
          </Alert>
        )}
        {genericError && (
          <Alert className={classes.errorAlertPadding} severity="error">
            <p>
              An error occurred, and your file could not be uploaded at this
              time. Please try again. If this error continues to occur, please
              contact PLB Portal administrators at{' '}
              <Link href={'mailto:' + config.DXC_EMAIL}>
                {config.DXC_EMAIL}
              </Link>
              .
            </p>
          </Alert>
        )}
        {timedOut && (
          <Alert className={classes.errorAlertPadding} severity="error">
            <p>
              File upload timed out. Upload time cannot exceed 220 seconds.
              Please contact PLB portal administrators at{' '}
              <Link href={'mailto:' + config.DXC_EMAIL}>
                {config.DXC_EMAIL}
              </Link>{' '}
              if you continue to have trouble uploading your file within the
              time limit.
            </p>
          </Alert>
        )}
        <Button
          disabled={
            !isReadyToUpload() ||
            isUploading ||
            timedOut ||
            genericError ||
            fileTypeError ||
            zipFileError
          }
          variant="primary"
          onClick={() => uploadFile()}
          className="stakeholderUploadDialog_uploadButton"
        >
          Upload
        </Button>
      </DialogActions>
    </div>
  );
};
