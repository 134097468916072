import { MainNavSection } from '../../../models/mainnav';
import {
  menuItem_NoticesAndBriefs,
  menuItem_Resources,
  menuItem_FAQ,
  menuItem_Deliverables,
  menuItem_TestEvidence,
  menuItem_TestResults,
  menuItem_Accreditation,
  menuItem_Contact,
} from '../MenuItems/MenuItems';
import { MenuGroupLabels } from './MenuGroupLabels';

export const PI_Acquirer_Menu: MainNavSection[] = [
  {
    label: MenuGroupLabels.INFO,
    navItems: [
      menuItem_NoticesAndBriefs,
      menuItem_Resources,
      menuItem_FAQ,
      menuItem_Contact,
    ],
  },
  {
    label: MenuGroupLabels.TASKS,
    navItems: [
      menuItem_Deliverables,
      menuItem_TestEvidence,
      menuItem_TestResults,
      menuItem_Accreditation,
    ],
  },
];
