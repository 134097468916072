import { makeStyles } from 'tss-react/mui';
import { Typography, IconButton, DialogTitle } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';

const styles = makeStyles()((theme) => {
  return {
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    headerText: {
      paddingTop: '1.875rem',
      paddingBottom: '1.875rem',
    },
  };
});

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitleClose = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  const { classes } = styles();

  return (
    <DialogTitle className={classes.headerText}>
      <Typography variant={'h4'}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CancelIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default DialogTitleClose;
