import './ActionDialog.css';
import {
  Button,
  IconButton,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

export interface ActionDialogProps {
  headingActionName: string;
  actionName: string;
  fileName?: string;
  yesFunction(): void;
  noFunction(): void;
}

const ActionDialog = (props: ActionDialogProps) => {
  const { headingActionName, actionName, fileName, yesFunction, noFunction } =
    props;

  return (
    <div>
      <DialogContent dividers={true} className="actionDialog_container">
        <div className="actionDialog_CloseContainer">
          <IconButton
            className="actionDialog_CloseIcon"
            onClick={() => noFunction()}
          >
            <CancelIcon />
          </IconButton>
        </div>
        <p id="scroll-dialog-description" className="actionDialog_Text">
          Are you sure you want to {headingActionName}?
        </p>
        <p id="scroll-dialog-description" className="actionDialog_smallText">
          {fileName}
        </p>
      </DialogContent>
      <DialogActions className="actionDialog_ButtonContainer">
        <Button
          className="actionDialog_Button"
          variant="secondary"
          onClick={() => yesFunction()}
        >
          <p className="actionDialog_ButtonText">Yes, {actionName}</p>
        </Button>
        <Button
          className="actionDialog_Button"
          variant="primary"
          onClick={() => noFunction()}
        >
          <p className="actionDialog_ButtonText">No, go back</p>
        </Button>
      </DialogActions>
    </div>
  );
};

export default ActionDialog;
