import './UnsupportedPage.css';

export const UnsupportedPage = () => {
  const openBrowserDownload = (location: string) => {
    window.open(location);
  };

  return (
    <div className="unsupported_background">
      <div className="unsupported_panel">
        <p className="unsupported_title">PLB Portal</p>
        <p className="unsupported_text">
          The browser you are using is no longer supported and cannot be used to
          access the PLB Portal. We apologise for the inconvenience, and
          recommend you to use one of the supported browsers listed below to
          visit the PLB Portal instead.
        </p>
        <p className="unsupported_subheader">Recommended Browsers</p>
        <p
          onClick={() =>
            openBrowserDownload('https://www.google.com.au/chrome/')
          }
          className="unsupported_recommends"
        >
          Google Chrome
        </p>
        <p
          onClick={() =>
            openBrowserDownload('https://www.microsoft.com/en-us/edge')
          }
          className="unsupported_recommends"
        >
          Microsoft Edge
        </p>
        <p
          onClick={() =>
            openBrowserDownload('https://www.apple.com/au/safari/')
          }
          className="unsupported_recommends"
        >
          Safari
        </p>
        <p
          onClick={() =>
            openBrowserDownload('https://www.mozilla.org/en-US/firefox/new/')
          }
          className="unsupported_recommends"
        >
          Mozilla Firefox
        </p>
      </div>
    </div>
  );
};
