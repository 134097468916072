import React, { useEffect } from 'react';
import { DocumentRow } from '../../models/documentRow';
import ApiService from '../../services/ApiService';
import DocumentTable from '../Shared/DocumentTable';
import PageHeader from '../Shared/PageHeader';
import PageOverlayContent from '../Shared/PageOverlayContent';
import { PageTitles } from '../../utils/PageTitles';
import { Helmet } from 'react-helmet';
import './NoticesBriefs.css';

const NoticesBriefs = () => {
  const [allData, setAllData] = React.useState<DocumentRow[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const getDocuments = () => {
    ApiService.getDocuments('notice')
      .then((x) => {
        let data = x.data;
        let newTable: DocumentRow[] = [];
        for (let dataItem of data) {
          let newRow: DocumentRow = {
            id: newTable.length,
            name: dataItem.fileName,
            date: new Date(dataItem.createdOn),
            link: dataItem.containerName + '/' + dataItem.fileName,
            role: dataItem.userType,
            type: 'notice',
            createdBy: dataItem.createdBy,
            isDownloading: false,
          };
          newTable.push(newRow);
        }
        setAllData(newTable);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDocuments();
  }, []);

  return (
    <>
      <Helmet>
        <title>{PageTitles.NoticesAndBriefs}</title>
      </Helmet>
      <PageHeader headerTitle="Notices and Briefs" hasOverlay={true}>
        {
          'See all PLB Notices and Briefs relevant to you here.\nNotices and Briefs announce important updates and changes to the PLB program.'
        }
      </PageHeader>
      <PageOverlayContent>
        <DocumentTable
          data={allData}
          tableName={'Notices and Briefs'}
          isLoading={isLoading}
        />
      </PageOverlayContent>
    </>
  );
};

export default NoticesBriefs;
