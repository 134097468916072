import '../ActionDialog/ActionDialog.css';
import {
  Button,
  IconButton,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

export interface IdleTimeoutDialogProps {
  closeFunction(): void;
}

const IdleTimeoutDialog = (props: IdleTimeoutDialogProps) => {
  const { closeFunction } = props;

  return (
    <div>
      <DialogContent dividers={true} className="actionDialog_container">
        <div className="actionDialog_CloseContainer">
          <IconButton
            className="actionDialog_CloseIcon"
            onClick={() => closeFunction()}
          >
            <CancelIcon />
          </IconButton>
        </div>
        <p className="timeoutDialog_Text">
          {
            'You have been signed out due to prolonged inactivity.\nPlease sign in again.'
          }
        </p>
      </DialogContent>
      <DialogActions className="timeoutDialog_ButtonContainer">
        <Button variant="primary" onClick={() => closeFunction()}>
          <p className="actionDialog_ButtonText">Close</p>
        </Button>
      </DialogActions>
    </div>
  );
};

export default IdleTimeoutDialog;
