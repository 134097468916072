export interface MainNavSection {
  label: string;
  navItems: MainNavItem[];
}

export interface MainNavItem {
  label: string;
  icon: React.ReactNode;
  color: string;
  to: string;
}

export enum userType {
  Services_Australia_Admin,
  DXC_Admin,
  POS_Developer,
  POS_Operations,
  POS_Developer_Operations,
  PIorAcquirer,
  Unknown,
}

export enum userTypeToString {
  'Services Australia Admin',
  'DXC Admin',
  'POS Developer',
  'POS Operations',
  'POS Developer & Operations',
  'Payment Integrator or Acquirer',
}

export enum userTabToType {
  'POSDeveloper',
  'POSOperations',
  'POSDeveloper+Operations',
  'AcquirerPaymentIntegrator',
}

export enum userTypeString {
  'Services_Australia_Admin',
  'DXC_Admin',
  'POS_Developer',
  'POS_Operations',
  'POS_Developer_Operations',
  'PIorAcquirer',
  'Unknown',
}

// matches the user type name URL encoded values stored in the database
export enum userTypeKeyToUserTypeName {
  'ServicesAustralia',
  'DXC',
  'POSDeveloper',
  'POSOperations',
  'POSDeveloper%2BOperations',
  'AcquirerPaymentIntegrator',
  'Unknown',
}

export enum userTabToContainer {
  'pos-developer',
  'pos-operations',
  'pos-developer.pos-operations',
  'acquirer-payment-integrator',
}
