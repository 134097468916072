import { Chip } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  root: {
    backgroundColor: '#F3CEF8',
    color: '#561143',
    height: '1.188rem',
    width: '2.5rem',
    marginRight: '0.313rem',
    '& .MuiChip-label': {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
});

export const POChip = () => {
  const { classes } = useStyles();

  return (
    <>
      <Chip className={classes.root} label="PO" title="POS Operations" />
    </>
  );
};

export default POChip;
