import { Box, Typography, Grid } from '@mui/material';
import React from 'react';
import './HomePageCard.css';
import '../HomePage.css';

export interface HomePageCardProps {
  img: string;
  number: string;
  heading: string;
  body: JSX.Element;
}

export const HomePageCard = (props: HomePageCardProps) => {
  const { img, number, heading, body } = props;

  return (
    <>
      <Grid item xs={12} sm={12} md={4}>
        <Box sx={{ height: '100%' }}>
          <Box className="homePageCard_box">
            <Box className="homePageCard_image_container">
              <img src={img} className="homePageCard_image" alt="" />
            </Box>
            <Typography className="homePageCard_header_container homepage_body2">
              <span className="homePageCard_info">{number}</span>
              <span className="homePageCard_header">{heading}</span>
            </Typography>
            <Typography className="alignLeft">{body}</Typography>
          </Box>
        </Box>
      </Grid>
    </>
  );
};
