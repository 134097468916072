import { FAQHead } from './FAQs';
export const FAQitems: FAQHead[] = [
  {
    faqHeader: 'SmartCard and Restricted Items',
    faqList: [
      {
        heading: 'What is the SmartCard?',
        body:
          'The SmartCard is a Visa/eftpos debit card issued under the enhanced Income Management (eIM) program to help recipients of certain welfare payments manage their money and protect them from financial abuse.\n\n' +
          'The participant receives their income support payment in two parts. One part goes into their normal bank account and the balance goes into their eIM account which is linked to a SmartCard. The SmartCard cannot be used to buy alcohol, gambling services, tobacco products, pornography, cash-like products or to withdraw cash.\n\n' +
          'Most cardholders are located in the Northern Territory, Queensland, Western Australia and South Australia.\n\n' +
          'Services Australia delivers eIM on behalf of government. To find out more about the program, visit the Services Australia <clickable>website</clickable>.',
      },
      {
        heading: 'Where can the SmartCard be used?',
        body: 'The SmartCard can be used anywhere that accepts eftpos or Visa card payments, including online, phone and via mail order, unless the business primarily sells restricted items.',
      },
      {
        heading: 'What are the restricted items?',
        body:
          'There are some items or products that cannot be purchased with a SmartCard. These are called <clickable>restricted items</clickable> and include:\n' +
          '<dotpoint>alcohol</dotpoint>' +
          '<dotpoint>gambling services</dotpoint>' +
          '<dotpoint>tobacco products</dotpoint>' +
          '<dotpoint>pornographic material</dotpoint>' +
          '<dotpoint>cash and <clickable>cash-like products</clickable></dotpoint>\n\n' +
          'Refer to the <clickable>restricted items factsheet</clickable> for more details.',
      },
    ],
  },
  {
    faqHeader: 'About Product Level Blocking (PLB)',
    faqList: [
      {
        heading: 'What is Product Level Blocking?',
        body:
          '<clickable>Product Level Blocking</clickable> (PLB) is an automated method to ensure restricted items cannot be purchased with a SmartCard.\n\n' +
          'The point of sale (POS) system and EFTPOS terminal work together to identify when a SmartCard is presented by a participant, and if there are any restricted items in the sale.',
      },
      {
        heading: 'Which merchants are in scope for PLB?',
        body:
          'The merchants (retailers) that are in scope for PLB are those that sell both restricted and unrestricted items including supermarkets, newsagents, service stations, pubs, clubs, and general retailers.\n\n' +
          'Merchants that sell only unrestricted items (such as food, clothing etc.) do not need to implement PLB.  Merchants that primarily sell restricted items (alcohol, gambling services, tobacco products or pornography) such as bars, liquor stores or TAB outlets are blocked from accepting the SmartCard.\n\n' +
          'For more information, visit the <clickable>PLB Merchant Portal</clickable>.',
      },
      {
        heading: 'How does PLB work?',
        body:
          'With PLB, if there is an attempt to purchase a restricted item with a SmartCard, the EFTPOS terminal cancels the payment transaction and displays ‘Cancelled - Restricted Item’.\n\n' +
          'The point of sale (POS) system displays a message for the POS operator that the purchase of the highlighted restricted item is not allowed with this card type.\n\n' +
          'The cardholder can choose to:\n' +
          '<dotpoint>purchase the restricted items with a different card or cash, or</dotpoint>' +
          '<dotpoint>remove the restricted items from the sale, and</dotpoint>' +
          '<dotpoint>pay for the unrestricted items with the SmartCard.</dotpoint>',
      },
      {
        heading:
          'What are the benefits of having PLB in my point of sale or payment solution?',
        body:
          'By having PLB in your solution:\n' +
          '<dotpoint>you can support initiatives that aim to help recipients manage their money and protect them from financial abuse.</dotpoint>' +
          '<dotpoint>you can offer your solution to merchants wishing to accept the SmartCard in their business.</dotpoint>' +
          '<dotpoint>you have an additional feature that will help your merchant clients meet their responsibilities in relation to the SmartCard in a convenient and highly automated process.</dotpoint>' +
          '<dotpoint>merchant staff do not need to recognise the card or remember which items are restricted.</dotpoint>' +
          '<dotpoint>your merchant clients can continue to accept the SmartCard, without having to take other measures to ensure restricted items are not purchased using a SmartCard.</dotpoint>' +
          '<dotpoint>your merchant clients do not risk having the SmartCard turned off at their business.</dotpoint>',
      },
      {
        heading: 'Do I have to implement PLB in my solution?',
        body: 'It is the responsibility of merchants not to sell restricted items to SmartCard participants.  Merchants that sell restricted items have the choice to remove those items from their product range, implement PLB in their business or not accept the SmartCard for payment. By supporting the program, you can help your merchant customers get PLB in their business.',
      },
      {
        heading:
          'If I choose to implement PLB in my solution, do I need to work with all my merchant clients to get PLB live in their stores?',
        body: 'No. Only merchants who sell restricted items – alcohol, gambling services, tobacco products, pornographic material, open-loop gift cards and cash-like products – and have signed up to join the PLB program would need to go live with PLB.',
      },
      {
        heading: 'What government agency is responsible for PLB?',
        body:
          'Services Australia delivers the enhanced Income Management (eIM) program across Australia on behalf of government. Services Australia is an executive agency responsible for delivering a range of welfare, health, child support payments and other services to eligible Australian citizens and permanent residents.\n\n' +
          'Services Australia works with companies to implement PLB functionality, including:\n' +
          '<dotpoint>merchants (retailers)</dotpoint>' +
          '<dotpoint>acquiring banks who enable PLB in their EFTPOS terminals</dotpoint>' +
          '<dotpoint>point of sale (POS) system providers</dotpoint>' +
          '<dotpoint>POS resellers</dotpoint>' +
          '<dotpoint>payment integrators.</dotpoint>\n\n' +
          'DXC Technology is the PLB system integration partner working with Services Australia to deliver PLB.',
      },
      {
        heading: 'Which merchants (retailers) have implemented PLB?',
        body: 'Major corporate merchants such as Coles supermarkets, Woolworths supermarkets, ALDI, Australia Post, Euro Garages, Kmart, Target and Big W have implemented PLB.  Many other merchants are developing or piloting PLB.  The number of individual merchants that have implemented PLB is growing.',
      },
      {
        heading: 'Which POS providers have implemented PLB?',
        body: 'More than 20 POS providers have implemented PLB and a list is provided <clickable>here</clickable>',
      },
      {
        heading: 'Which bank/acquirers have implemented PLB?',
        body: 'The major banks and other acquirers who together cover more than 90% of merchants, have implemented PLB in their integrated EFTPOS terminals.',
      },
    ],
  },
  {
    faqHeader: 'Implementing Product Level Blocking (PLB)',
    faqList: [
      {
        heading: 'How can I implement PLB in my solution?',
        body: 'Specifications and supporting documentation are available for you to download from the <clickable>Resources</clickable> section of this portal.',
      },
      {
        heading: 'Does the PLB development have to be certified / accredited?',
        body: 'Yes. DXC Technology will accredit solutions after completion of Configuration Acceptance Testing (CAT).',
      },
      {
        heading: 'Will the PLB development be funded?',
        body: 'In some cases, funding is available from DXC Technology for POS providers to complete PLB development.  The offer is based on the POS provider’s number of merchant clients that sell both restricted and unrestricted items.',
      },
      {
        heading: 'What is the role of DXC Technology?',
        body: 'DXC Technology is the system integration partner working with Services Australia to deliver PLB.  They are responsible for registering organisations into the program, guiding their development, supporting the implementations, and communicating with all relevant parties (including POS providers, POS resellers, payment integrators and acquirers).',
      },
    ],
  },
  {
    faqHeader: 'PLB in your solution',
    faqList: [
      {
        heading: 'I am a POS provider, what changes will I need to make?',
        body:
          ' At a high level:\n' +
          '<dotpoint>add a new stock management function so that merchants can identify restricted items.</dotpoint>' +
          '<dotpoint>integrate PLB with the payment integrators you normally use with your solution.</dotpoint>' +
          '<dotpoint>create a simple user interface for POS operators to manage restricted items when a SmartCard payment is cancelled. This user interface should avoid identifying SmartCard participants to minimise concerns about stigma.</dotpoint>' +
          '<dotpoint>enable reporting of de-identified SmartCard sale data.</dotpoint>' +
          '<dotpoint>develop PLB training materials for your merchant clients, operations staff and any resellers you may have.</dotpoint>' +
          'Detailed specifications are available from the <clickable>Resources</clickable> section of this portal.',
      },
      {
        heading: 'Are all the PLB payment integrations the same?',
        body: 'No. The payment integrators have developed PLB in similar ways, but there are some differences. If you are a POS provider, you will need to consult each of the payment integrators with whom you normally work to implement PLB.',
      },
      {
        heading: 'How can I test PLB?',
        body:
          'A virtual terminal emulator that supports testing of PLB by POS providers is available for some payment integrations. For other integrations, DXC Technology will coordinate between the POS provider, payment integrator and acquirer to provide physical test PIN pads.\n\n' +
          'A PLB testing handbook is available for download from the <clickable>Resources</clickable> section of this portal.',
      },
    ],
  },

  {
    faqHeader: 'PLB deployment',
    faqList: [
      {
        heading: 'How is PLB deployed to merchant organisations?',
        body:
          'The steps are as follows:\n' +
          '<dotpoint>Services Australia identifies merchants seeking to adopt PLB</dotpoint>' +
          '<dotpoint>The merchant signs a PLB merchant participation agreement</dotpoint>' +
          '<dotpoint>DXC Technology checks with the merchant’s acquiring bank to confirm that their PIN pads are PLB-ready</dotpoint>' +
          '<dotpoint>The POS provider or POS reseller works with the merchant to:</dotpoint>' +
          '<sub-dotpoint>update the merchant’s POS software if required</sub-dotpoint>' +
          '<sub-dotpoint>identify any restricted items in the merchant’s stock file</sub-dotpoint>' +
          '<sub-dotpoint>train the staff.</sub-dotpoint>',
      },
    ],
  },

  {
    faqHeader: 'PLB operations',
    faqList: [
      {
        heading: 'What may I need to do once I have sites live with PLB?',
        body: 'You will be required to provide support to your merchants and DXC Technology to resolve any problems with product level blocking or SmartCard sales reporting – including managing credentials.',
      },
      {
        heading: 'What is credential management?',
        body: 'If you provide PLB support to a merchant, you will need to load access credentials in the merchant POS system to maintain a valid connection for uploading SmartCard sale data.  Credentials are valid for 12 months and need to be replaced before they expire. If relevant to your role, you can generate new credentials in the Credential section of this portal.',
      },
      {
        heading:
          'Will I be notified when there are updates to Product Level Blocking?',
        body: 'As a user of this portal, you will get notifications of any incidents, updates or program changes via the <clickable>Notices and Briefs</clickable> section of this portal.',
      },
    ],
  },

  {
    faqHeader: 'I have feedback or need more information',
    faqList: [
      {
        heading: "I'm not sure what all these terms mean. Can you help?",
        body: 'Here is a <clickable>list of terms</clickable>.',
      },
      {
        heading: 'Who can I contact regarding PLB?',
        body:
          ' If you: \n' +
          '<dotpoint>have any questions or would like some more information,</dotpoint>' +
          '<dotpoint>have feedback regarding your experience with PLB, or</dotpoint>' +
          '<dotpoint>need to give an update on changes in your business,</dotpoint>\n\n' +
          'you can contact the Services Australia PLB Team via email at <email>OVERWRITTEN_BY_CONFIG</email>.',
      },
    ],
  },
];
