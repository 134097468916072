import {
  Button,
  Paper,
  TableContainer,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  Box,
  Dialog,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import action_button_upload from '../../../assets/icons/action_button_upload.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { DocumentRow } from '../../../models/documentRow';
import './DocumentTable.css';
import { formatDateTime } from '../../../utils/FormatDateTime';
import React, { useEffect } from 'react';
import POChip from '../POChip';
import PDChip from '../PDChip';
import PIAChip from '../PIAChip';
import { IconActionButton } from '../IconActionButton';
import { Loader } from '../../../utils/Loader';
import { makeStyles } from 'tss-react/mui';
import DownloadDialog from '../DownloadDialog';

const useStyles = makeStyles()((theme) => {
  return {
    tableRoot: {
      '& .MuiDataGrid-cell': {
        outline: 'none !important',
      },
    },
    columnHeaderTitle: {
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: '600',
      },
    },
  };
});

export interface DocumentTableProps {
  data: DocumentRow[];
  tableName: string;
  isLoading?: boolean;
  isAccordion?: boolean;
  canSeeUploader?: boolean;
  canSeeVisibility?: boolean;
  canEditVisibility?: boolean;
  canUpload?: boolean;
  canDelete?: boolean;
  deleteFunction?: (row: DocumentRow) => void;
  uploadFunction?: () => void;
  visibilityFunction?: (row: DocumentRow) => void;
}

const DocumentTable = (props: DocumentTableProps) => {
  const { classes } = useStyles();
  const {
    data,
    tableName,
    isLoading = true,
    canSeeUploader = false,
    canSeeVisibility = false,
    canEditVisibility = false,
    canUpload = false,
    canDelete = false,
    isAccordion = false,
    deleteFunction,
    uploadFunction,
    visibilityFunction,
  } = props;
  const [accordionOpen, setAccordionOpen] = React.useState(false);
  const [downloadOpen, setDownloadOpen] = React.useState(false);
  const [downloadDetails, setDownloadDetails] = React.useState({
    fileName: '',
    fileContainer: '',
  });

  const triggerDelete = (row: DocumentRow) => {
    if (deleteFunction !== undefined) {
      deleteFunction(row);
    }
  };

  const editVisibility = (row: DocumentRow) => {
    if (visibilityFunction !== undefined) {
      visibilityFunction(row);
    }
  };

  const triggerUpload = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (uploadFunction !== undefined) {
      uploadFunction();
    }
    e.stopPropagation();
  };

  const hasPD = (role: any) => {
    if (role.includes('POSDeveloper')) return true;
    else return false;
  };

  const hasPO = (role: any) => {
    if (role.includes('POSOperations')) return true;
    else return false;
  };

  const hasPIA = (role: any) => {
    if (role.includes('AcquirerPaymentIntegrator')) return true;
    else return false;
  };

  const Columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'FILE NAME',
      disableColumnMenu: true,
      flex: 2,
      headerClassName: classes.columnHeaderTitle,
    },
    {
      field: 'date',
      headerName: 'PUBLISHED',
      type: 'dateTime',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return '';
        }
        return formatDateTime(params.value);
      },
      flex: 2,
      headerClassName: classes.columnHeaderTitle,
    },
    {
      field: 'download',
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'right',
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<DocumentRow>) => (
        <Box sx={{ mr: '0.9375rem' }}>
          <IconActionButton
            variant="download"
            onClick={() => downloadDocument(params.row)}
          />
        </Box>
      ),
    },
  ];

  const ExpandedColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'FILE NAME',
      disableColumnMenu: true,
      flex: 1.1,
      headerClassName: classes.columnHeaderTitle,
    },
    {
      field: 'createdBy',
      headerName: 'UPLOADED BY',
      hide: !canSeeUploader,
      disableColumnMenu: true,
      flex: 0.7,
      headerClassName: classes.columnHeaderTitle,
    },
    {
      field: 'date',
      headerName: 'PUBLISHED',
      type: 'dateTime',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return '';
        }
        return formatDateTime(params.value);
      },
      flex: 0.5,
      headerClassName: classes.columnHeaderTitle,
    },
    {
      field: 'role',
      headerName: 'VISIBILITY',
      hide: !canSeeVisibility,
      disableColumnMenu: true,
      flex: 0.4,
      headerClassName: classes.columnHeaderTitle,
      renderCell: (params: GridRenderCellParams<DocumentRow>) => (
        <>
          {hasPD(params.value) && <PDChip />}
          {hasPO(params.value) && <POChip />}
          {hasPIA(params.value) && <PIAChip />}
        </>
      ),
    },
    {
      field: 'download',
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'right',
      flex: 0.6,
      renderCell: (params: GridRenderCellParams<DocumentRow>) => (
        <Box sx={{ mr: '0.9375rem' }}>
          <IconActionButton
            variant="download"
            onClick={() => downloadDocument(params.row)}
          />

          {canEditVisibility && (
            <IconActionButton
              variant="editVisibility"
              onClick={() => editVisibility(params.row)}
            />
          )}

          {canDelete && (
            <IconActionButton
              variant="delete"
              onClick={() => triggerDelete(params.row)}
            />
          )}
        </Box>
      ),
    },
  ];

  const getColumns = () => {
    if (!canSeeUploader && !canSeeVisibility) {
      return Columns;
    } else {
      return ExpandedColumns;
    }
  };

  const downloadDocument = (row: DocumentRow | undefined) => {
    if (row !== undefined) {
      setDownloadDetails({ fileName: row.name, fileContainer: row.type });
      setDownloadOpen(true);
    }
  };

  const closeDownload = () => {
    setDownloadDetails({ fileName: '', fileContainer: '' });
    setDownloadOpen(false);
  };

  useEffect(() => {}, [downloadOpen]);

  const tableHeader = (
    <div className="documentTable_TopContainer">
      <Typography variant="h5" className="documentTable_Subheader">
        {tableName}
      </Typography>
      {canUpload && (
        <Button
          variant="action"
          color="primary"
          className="documentTable_AddButton"
          onClick={(e) => triggerUpload(e)}
        >
          <img
            src={action_button_upload}
            className="documentTable_icon"
            alt="Upload Button"
          />
          <span className="documentTable_DownloadText">Upload document</span>
        </Button>
      )}
    </div>
  );

  const tableBody = (
    <>
      {!isLoading && (
        <TableContainer component={Paper} className={classes.tableRoot}>
          <DataGrid
            rows={data}
            columns={getColumns()}
            autoHeight
            hideFooter={true}
            rowHeight={84}
            headerHeight={64}
            sortingOrder={['desc', 'asc']}
            initialState={{
              sorting: {
                sortModel: [{ field: 'date', sort: 'desc' }],
              },
            }}
            classes={{
              columnHeader: 'documentTable_header',
              columnSeparator: 'documentTable_seperator',
              columnHeaders: 'documentTable_header_background',
              row: 'documentTable_row',
              cell: 'documentTable_cell_padding',
              cellContent: 'documentTable_cell_content',
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  color="#000000"
                  alignItems="center"
                  justifyContent="center"
                >
                  This section has no content yet.
                </Stack>
              ),
            }}
          />
        </TableContainer>
      )}
      {isLoading && <Loader isLoading={isLoading} children={undefined} />}
    </>
  );

  const accordionTable = (
    <Accordion expanded={accordionOpen} className="documentTable_accordion_box">
      <AccordionSummary
        classes={{
          root: 'documentTable_accordion',
          content: accordionOpen
            ? 'documentTable_accordion_rootExpanded'
            : 'documentTable_accordion_root',
        }}
        onClick={() => setAccordionOpen(!accordionOpen)}
      >
        <Typography
          variant="h5"
          className={
            accordionOpen
              ? 'documentTable_Subheader'
              : 'documentTable_SubheaderCollapsed'
          }
        >
          {tableName}
        </Typography>
        <IconButton className={'documentTable_closeIcon'}>
          {accordionOpen ? (
            <KeyboardArrowUpIcon className="documentTable_closeIconImg" />
          ) : (
            <KeyboardArrowDownIcon className="documentTable_closeIconImg" />
          )}
        </IconButton>
        {canUpload && accordionOpen && (
          <Button
            variant="action"
            color="primary"
            className="documentTable_AddButton"
            onClick={(e) => triggerUpload(e)}
          >
            <img
              src={action_button_upload}
              className="documentTable_icon"
              alt="Upload Button"
            />
            <span className="documentTable_DownloadText">Upload document</span>
          </Button>
        )}
      </AccordionSummary>
      <AccordionDetails className="documentTable_accordion_details">
        {tableBody}
      </AccordionDetails>
    </Accordion>
  );

  const standardTable = (
    <div className="documentTable_top_box">
      {tableHeader}
      {tableBody}
    </div>
  );

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={downloadOpen}
        keepMounted={true}
        aria-describedby="scroll-dialog-description"
      >
        <DownloadDialog
          downloadType="generic"
          file={downloadDetails}
          closeDialog={() => closeDownload()}
        />
      </Dialog>
      {!isAccordion && <>{standardTable}</>}
      {isAccordion && <>{accordionTable}</>}
    </>
  );
};

export default DocumentTable;
