import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import ExpressInterest from './components/ExpressInterest';
import { HomeLocation, ExpressInterestLocation } from './publicRouteConstants';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <Routes>
    <Route path={HomeLocation.path} element={<HomePage />} />
    <Route path={ExpressInterestLocation.path} element={<ExpressInterest />} />
    <Route path="*" element={<HomePage />} />
  </Routes>
);
