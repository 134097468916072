import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/material';
import { useState } from 'react';
import teal_bg_img from '../../assets/backgrounds/Teal-banner-darker.png';
import { ABNStatus } from './constants/constant-variables';
import { Details } from './Details';
import { ThankYou } from './ThankYouPage';
import { Helmet } from 'react-helmet';
import { PageTitles } from '../../utils/PageTitles';

const useStyles = makeStyles()((theme) => {
  return {
    eoiStepper_header: {
      paddingTop: '0.937rem',
      paddingBottom: '0.937rem',
      paddingLeft: '10rem',
      paddingRight: '10rem',
      minHeight: '4.0625rem',
      '@media (max-width: 1023px)': {
        display: 'none',
      },
    },
    eoiStepper_header_mobile: {
      paddingLeft: '5%',
      paddingRight: '5%',
      paddingTop: '0.937rem',
      paddingBottom: '0.937rem',
      '@media (min-width: 1024px)': {
        display: 'none',
      },
    },
    eoiStepper_stepContainer: {
      display: 'flex',
    },
    eoiStepper: {
      flex: '1',
    },
    eoi_back_button: {
      paddingTop: '0.375rem',
      paddingBottom: '0.375rem',
    },
    eoi_back_button_arrow: {
      marginRight: '0.75rem',
    },
    content_box: {
      background: 'url(' + teal_bg_img + ')',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      padding: '5rem 5% 10rem',
      flex: 1,
      justifyContent: 'center',
      display: 'flex',
    },

    eoiStepper_textContainer_mobile: {
      marginLeft: 'auto',
      textAlign: 'right',
    },

    eoiStepper_progressContainer_mobile: {
      position: 'relative',
      display: 'inline-flex',
    },
    eoiStepper_progress_mobile: {
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    eoiStepper_activeStepText_mobile: {
      font: 'normal normal 600 1.125rem/1.5rem Roboto',
      color: 'var(--primary-purple)',
      textAlign: 'right',
    },
    eoiStepper_nextStepText_mobile: {
      font: 'normal normal 400 0.75rem/1.25rem Roboto',
      color: 'var(--primary-purple)',
      textAlign: 'right',
    },
    eoiStepper_progressCircle_mobile: {
      position: 'absolute',
      color: '#e2e2e2',
    },
  };
});

const ExpressInterest = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [abnStatus, setAbnStatus] = useState(ABNStatus.InitialState);
  const [emailAddress, setEmailAddress] = useState('');
  const { classes } = useStyles();

  const handleNext = (emailName: string) => {
    setEmailAddress(emailName);
    setActiveStep(activeStep + 1);
  };

  const getStepContent = (stepIndex: number) => {
    if (stepIndex === 1) {
      return <ThankYou emailName={emailAddress} />;
    } else {
      return (
        <Details
          handleNext={handleNext}
          abnStatus={abnStatus}
          setAbnStatus={setAbnStatus}
        />
      );
    }
  };

  return (
    <Box className={classes.content_box}>
      <Helmet>
        <title>{PageTitles.EoI}</title>
      </Helmet>
      {getStepContent(activeStep)}
    </Box>
  );
};

export default ExpressInterest;
