import {
  NoticesBriefsLocation,
  ResourcesLocation,
  FrequentlyAskedQuestionsLocation,
  MyDeliverablesLocation,
  MyTestingLocation,
  CredentialsLocation,
  TestResultsLocation,
  MyAccreditationLocation,
  ContactLocation,
} from '../../../privateRouteConstants';
import { MainNavItem } from '../../../models/mainnav';
import './MenuItem.css';

import sidebar_notices_briefs from '../../../assets/icons/sidebar_notices_briefs.svg';
import sidebar_deliverables from '../../../assets/icons/sidebar_deliverables.svg';
import sidebar_faq from '../../../assets/icons/sidebar_faq.svg';
import sidebar_merchant_credentials from '../../../assets/icons/sidebar_merchant_credentials.svg';
import sidebar_resources from '../../../assets/icons/sidebar_resources.svg';
import sidebar_test_evidence from '../../../assets/icons/sidebar_test_evidence.svg';
import sidebar_accreditation from '../../../assets/icons/sidebar_accreditation.svg';
import sidebar_test_results from '../../../assets/icons/sidebar_test_results.svg';
import sidebar_contact from '../../../assets/icons/sidebar_contact.svg';

export const menuItem_NoticesAndBriefs: MainNavItem = {
  label: 'Notices and Briefs',
  icon: (
    <img src={sidebar_notices_briefs} className="menuItem_icon" alt="icon" />
  ),
  color: '#5a73da',
  to: NoticesBriefsLocation.path,
};

export const menuItem_Resources: MainNavItem = {
  label: 'Resources',
  icon: <img src={sidebar_resources} className="menuItem_icon" alt="icon" />,
  color: '#f06e4b',
  to: ResourcesLocation.path,
};

export const menuItem_FAQ: MainNavItem = {
  label: 'FAQ',
  icon: <img src={sidebar_faq} className="menuItem_icon" alt="icon" />,
  color: '#9B27E0',
  to: FrequentlyAskedQuestionsLocation.path,
};

export const menuItem_Deliverables: MainNavItem = {
  label: 'Deliverables',
  icon: <img src={sidebar_deliverables} className="menuItem_icon" alt="icon" />,
  color: '#5D76DD',
  to: MyDeliverablesLocation.path,
};

export const menuItem_TestEvidence: MainNavItem = {
  label: 'Test Evidence',
  icon: (
    <img src={sidebar_test_evidence} className="menuItem_icon" alt="icon" />
  ),
  color: '#FCD646',
  to: MyTestingLocation.path,
};

export const menuItem_Accreditation: MainNavItem = {
  label: 'Accreditation',
  icon: (
    <img src={sidebar_accreditation} className="menuItem_icon" alt="icon" />
  ),
  color: '#CC5672',
  to: MyAccreditationLocation.path,
};

export const menuItem_TestResults: MainNavItem = {
  label: 'Test Results',
  icon: <img src={sidebar_test_results} className="menuItem_icon" alt="icon" />,
  color: '#CC5672',
  to: TestResultsLocation.path,
};

export const menuItem_Contact: MainNavItem = {
  label: 'Contact',
  icon: <img src={sidebar_contact} className="menuItem_icon" alt="icon" />,
  color: '#CC5672',
  to: ContactLocation.path,
};

export const menuItem_Credentials: MainNavItem = {
  label: 'Merchant Site Credentials',
  icon: (
    <img
      src={sidebar_merchant_credentials}
      className="menuItem_icon"
      alt="icon"
    />
  ),
  color: '#63AC6E',
  to: CredentialsLocation.path,
};
