import axios from 'axios';
import { ContactDetails } from '../models/contactDetails';
import config from '../config/config.json';
const axiosPublicInstance = axios.create();

const PublicService = {
  getABNDetails: (abn: string) => {
    const abnSearchUrl =
      config.ABN_URL_BASE +
      config.ABN_SEARCH_PARAM +
      abn.trim().replace(' ', '+') +
      config.INCLUDE_HIST_STRING +
      config.AUTH_GUID_STRING;
    return axiosPublicInstance.get(abnSearchUrl);
  },

  sendEOIContactDetails: (eoiContactDetails: ContactDetails) => {
    return axiosPublicInstance.post<ContactDetails>(
      `${process.env.REACT_APP_API}/stakeholderportal/v1/eoiContactDetails`,
      eoiContactDetails,
    );
  },

  getFactsheet: (factsheetName: string) => {
    return axios.get(
      `${process.env.REACT_APP_API}/odata/factsheet?$filter=name eq '${factsheetName}'`,
    );
  },
};

export default PublicService;
