import Location from 'react-app-location';

export const HomeLocation = new Location('/');

export const FrequentlyAskedQuestionsLocation = new Location(
  '/frequently-asked-questions',
);
export const NoticesBriefsLocation = new Location('/notices-and-briefs');
export const ResourcesLocation = new Location('/resources');
export const MyDeliverablesLocation = new Location('/my-deliverables');
export const MyTestingLocation = new Location('/my-testing');
export const RequestAMeetingLocation = new Location('/request-a-meeting');
export const MyAccreditationLocation = new Location('/accreditation');
export const TestResultsLocation = new Location('/test-results');
export const CredentialsLocation = new Location('/credentials');
export const ContactLocation = new Location('/contact');
