import { ElementItems } from './FAQs';
import merchant from '../../config/MerchantPortalURL.json';
import config from '../../config/config.json';
import {
  CredentialsLocation,
  NoticesBriefsLocation,
  ResourcesLocation,
} from '../../privateRouteConstants';

export const elementItems: ElementItems[] = [
  {
    label: 'Resources',
    path: ResourcesLocation.path,
  },
  {
    label: 'Notices and Briefs',
    path: NoticesBriefsLocation.path,
  },
  {
    label: 'Credential',
    path: CredentialsLocation.path,
  },
  // TO DO: Get URL from FactSheet API instead of hardcoding
  {
    label: 'list of terms',
    isExternalLink: true,
    isFactSheet: true,
    path: '',
  },
  {
    label: 'restricted items factsheet',
    isExternalLink: true,
    isFactSheet: true,
    path: '',
  },
  {
    label: 'PLB Merchant Portal',
    isExternalLink: true,
    path: merchant.HOME,
  },
  {
    label: 'restricted items',
    isExternalLink: true,
    path: merchant.RESTRICTED_ITEMS_PAGE,
  },
  {
    label: 'Product Level Blocking',
    isExternalLink: true,
    path: merchant.WHAT_IS_PLB_PAGE,
  },
  // TODO: Update with actual Services Australia page
  {
    label: 'website',
    isExternalLink: true,
    path: config.SA_EIM_SITE,
  },
  {
    label: 'cash-like products',
    path: '',
    isLink: false,
    isDialog: true,
    dialog: 'CashEquivalentsDialog',
  },
  {
    label: 'here',
    path: '',
    isLink: false,
    isDialog: true,
    dialog: 'ProviderListDialog',
  },
];
