import './DownloadDialog.css';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Link,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import AuthService from '../../../services/AuthService';
import axios, {
  AxiosHeaders,
  AxiosProgressEvent,
  CancelTokenSource,
} from 'axios';
import { tokenRequest } from '../../../config/AuthConfig';
import config from '../../../config/config.json';
import { DocumentDetail } from '../../../models/documentDetail';

export interface DownloadDialogProps {
  closeDialog(): void;
  downloadType: string;
  file: DocumentDetail;
}

const DownloadDialog = (props: DownloadDialogProps) => {
  const { closeDialog, downloadType, file } = props;
  const [showErrror, setShowError] = React.useState<boolean>(false);
  const [downloadPercent, setDownloadPercent] = React.useState<number>(-1);
  const [downloadProgress, setDownloadProgress] = React.useState<string>('');

  const useStyles = makeStyles()((theme) => {
    return {
      root: {
        '& .MuiDialogContent-root': {
          display: 'flex',
          flexDirection: 'column',
          flex: 'none',
          minHeight: '16.875rem',
          justifyContent: 'space-between',
          border: '0.0625rem solid rgba(0,0,0,0.12)',
        },
        '& .MuiDialogActions-root': {
          justifyContent: 'space-evenly',
          paddingTop: '2.1875rem',
          paddingBottom: '2.5rem',
        },
        '& .MuiAlert-root': {
          width: '100%',
          font: 'normal normal normal 0.8125rem/1.5rem Open Sans',
          letterSpacing: '0.0075rem',
          color: '#8A1010',
          opacity: '1',
          maxHeight: '6.25rem',
        },
        '& .MuiAlert-message': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '0rem',
          '& p': {
            margin: '0rem',
          },
        },
        '& .MuiFormGroup-root': {
          paddingBottom: '3.5rem',
        },
        '& img': {
          paddingRight: '0.625rem',
        },
        '& .MuiTypography-root': {
          textAlign: 'left',
        },
        '& .MuiDialogContent-root:nth-of-type(1)': {
          padding: '0rem',
          flex: '1 1 0',
        },
      },
      uploadFileAlert: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '0.5rem',
        padding: '0rem 0rem 0rem',
        background: 'none',
        '& p': {
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          font: 'normal normal normal 0.75rem/1.0625rem Open Sans',
          color: '#606060',
          backgroundColor: '#F8F8F8',
          padding: '0.25rem 1rem',
          overflowWrap: 'anywhere',
        },
      },
      errorAlertPadding: {
        padding: '0.625rem 1rem 0.75rem',
        marginBottom: '0.8125rem',
      },
      noPadding: {
        padding: '0',
      },
      purpleLoader: {
        '&.MuiCircularProgress-colorPrimary': {
          color: 'var(--primary-purple)',
        },
      },
      centerFlex: {},
    };
  });

  const { classes } = useStyles();
  const sourceRef = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    if (file.fileContainer !== null && file.fileName !== null) {
      if (file.fileContainer !== '' && file.fileName !== '') {
        setShowError(false);
        const source = axios.CancelToken.source();
        sourceRef.current = source;
        getDocument(file.fileContainer, file.fileName, source);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  let roundOff = (num: number, places: number) => {
    const x = Math.pow(10, places);
    return Math.round(num * x) / x;
  };

  const stopDownload = () => {
    closeDialog();
    if (sourceRef.current !== null) {
      sourceRef.current.cancel('Component got unmounted');
    }
    setDownloadPercent(-1);
    setDownloadProgress('');
  };

  const getDocument = async (
    container: string,
    fileName: string,
    cancelToken: CancelTokenSource,
  ) => {
    var accessToken = '';

    const tokenResponse = await AuthService.getInstance().getToken(
      tokenRequest,
    );

    if (tokenResponse && tokenResponse?.accessToken) {
      accessToken = `Bearer ${tokenResponse?.accessToken}`;
    }
    var url = '';
    if (downloadType === 'stakeholder') {
      url = `${process.env.REACT_APP_API}/stakeholderportal/v1/stakeholderspecificdocument/document/${container}`;
    } else {
      url = `${process.env.REACT_APP_API}/stakeholderportal/v1/genericdocument/${container}/${fileName}`;
    }
    axios({
      url: url,
      headers: new AxiosHeaders({ Authorization: accessToken }),
      cancelToken: cancelToken.token,
      responseType: 'blob',
      onDownloadProgress(progress: AxiosProgressEvent) {
        if (progress.total !== undefined) {
          if (progress.total > 1048576) {
            var loadedMegabyte = roundOff(progress.loaded / 1048576, 2);
            var totalMegabyte = roundOff(progress.total / 1048576, 2);
            setDownloadProgress(
              loadedMegabyte + 'MB / ' + totalMegabyte + 'MB',
            );
          } else {
            var loadedKilobyte = roundOff(progress.loaded / 1024, 2);
            var totalKilobyte = roundOff(progress.total / 1024, 2);
            setDownloadProgress(
              loadedKilobyte + 'KB / ' + totalKilobyte + 'KB',
            );
          }
          setDownloadPercent(
            Math.round((progress.loaded * 100) / progress.total),
          );
        }
      },
    })
      .then((x) => {
        var _url = window.URL.createObjectURL(x.data);
        const link = document.createElement('a');
        link.href = _url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        setDownloadPercent(-1);
        setDownloadProgress('');
        closeDialog();
      })
      .catch(() => {
        setShowError(true);
      });
  };

  return (
    <div className={classes.root}>
      <DialogTitle className="DownloadDialog_Header">
        <p className="DownloadDialog_Text">Downloading</p>
      </DialogTitle>
      <div className="DownloadDialog_flexRow">
        <DialogContent
          dividers={true}
          className="DownloadDialog_container, DownloadDialog_flexboxItem"
        >
          <div className="DownloadDialog_centerflex">
            <p className="DownloadDialog_smallText">{file.fileName}</p>
            {downloadPercent === -1 && (
              <CircularProgress size={120} className={classes.purpleLoader} />
            )}
            {downloadPercent !== -1 && (
              <CircularProgress
                variant="determinate"
                value={downloadPercent}
                size={120}
                className={classes.purpleLoader}
              />
            )}
            {downloadPercent === -1 && (
              <p className="DownloadDialog_ProgressText">Please Wait</p>
            )}
            {downloadPercent !== -1 && (
              <p className="DownloadDialog_ProgressText">{downloadProgress}</p>
            )}
          </div>
        </DialogContent>
      </div>
      <DialogActions
        className="DownloadDialog_ButtonContainer"
        disableSpacing={true}
      >
        {showErrror && (
          <Alert className={classes.errorAlertPadding} severity="error">
            <p>
              File download has failed. Please try again and contact Services
              Australia at{' '}
              <Link href={'mailto:' + config.SERVICES_AUS_EMAIL}>
                {config.SERVICES_AUS_EMAIL}
              </Link>{' '}
              if you continue to have trouble downloading this file.
            </p>
          </Alert>
        )}
        <Button
          variant="primary"
          onClick={() => stopDownload()}
          className="DownloadDialog_uploadButton"
        >
          Cancel download
        </Button>
      </DialogActions>
    </div>
  );
};

export default DownloadDialog;
