import { makeStyles } from 'tss-react/mui';
import secret_expired from '../../../assets/icons/secret_expired.svg';
import secret_expiry_warning from '../../../assets/icons/secret_expiry_warning.svg';
import { formatDate } from '../../../utils/FormatDateTime';

const useStyles = makeStyles()((theme) => {
  return {
    secretExpired: {
      fontWeight: 'bold',
      color: '#E45858',
    },
    secretNearingExpired: {
      fontWeight: 'bold',
      color: '#EB9330',
    },
    expiryIcon: {
      marginLeft: '0.5rem',
    },
  };
});

export interface SecretExpiryProps {
  secretExpiryDate: string;
}

export const SecretExpiry = (props: SecretExpiryProps) => {
  const { secretExpiryDate } = props;
  const { classes } = useStyles();

  const dateToday = new Date().toLocaleDateString('en-AU', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  const dateOneMonthBeforeExipry = (function () {
    const expiryDate = new Date(secretExpiryDate);
    const date = expiryDate.getDate();
    expiryDate.setMonth(expiryDate.getMonth() - 1);
    if (expiryDate.getDate() !== date) {
      expiryDate.setDate(0);
    }
    return expiryDate.toLocaleDateString('en-AU', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  })();

  const getDateClass = () => {
    if (Date.parse(dateToday) > Date.parse(secretExpiryDate)) {
      return classes.secretExpired;
    } else if (Date.parse(dateToday) >= Date.parse(dateOneMonthBeforeExipry)) {
      return classes.secretNearingExpired;
    } else {
      return '';
    }
  };

  return (
    <span className={getDateClass()}>
      {formatDate(new Date(secretExpiryDate))}
      {getDateClass() === classes.secretExpired && (
        <img
          src={secret_expired}
          className={classes.expiryIcon}
          alt="Expired Secret Icon"
        />
      )}
      {getDateClass() === classes.secretNearingExpired && (
        <img
          src={secret_expiry_warning}
          className={classes.expiryIcon}
          alt="Secret Nearly Expired Icon"
        />
      )}
    </span>
  );
};

export default SecretExpiry;
