import { makeStyles } from 'tss-react/mui';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {
  Box,
  AppBar,
  Button,
  Dialog,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  CssBaseline,
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { AuthConsumer, AuthContext } from '../../context/AuthContext';
import { userType, userTypeToString } from '../../models/mainnav';
import ActionDialog from '../Shared/ActionDialog';
import { Default_Menu } from './MenuItems/DefaultMenu';
import { Services_Australia_Menu } from './MenuItems/ServicesAustraliaMenu';
import { DXC_Menu } from './MenuItems/DXCMenu';
import { PI_Acquirer_Menu } from './MenuItems/PIorAcquirerMenu';
import { POS_Developer_Menu } from './MenuItems/POSDeveloperMenu';
import { POS_Developer_Operations_Menu } from './MenuItems/POSDeveloperOperationsMenu';
import { POS_Operations_Menu } from './MenuItems/POSOperationsMenu';

import './ResponsiveDrawer.css';
const drawerWidth = 230;

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      backgroundColor: '#004052 !important',
      boxShadow: 'none !important',
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    appBarAdmin: {
      backgroundColor: '#5f277e !important',
      boxShadow: 'none !important',
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    toolbarHeader: {
      flex: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
    },
    logo: {
      maxWidth: '100%',
      marginTop: theme.spacing(2),
    },
    signOutButton: {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: '#fff',
      fontWeight: 400,
    },
    signOutAppBarArea: {
      display: 'flex',
      alignItems: 'center',
      verticalAlign: 'center',
      flexWrap: 'wrap',
      marginLeft: 'auto',
      cursor: 'pointer',
    },
    userNameAppBar: {
      padding: `0 ${theme.spacing(3)}`,
    },
    headerTitle: {
      font: 'normal normal bold 22px/22px Open Sans',
      textAlign: 'left',
      color: '#5F277E',
      letterSpacing: '0.66px',
      padding: `0 ${theme.spacing(3)}`,
      paddingTop: '4px',
      paddingBottom: '40px',
      marginTop: '0px !important',
    },
    drawerSection: {
      paddingTop: `${theme.spacing(3)}`,
    },
    sectionTitle: {
      textAlign: 'left',
      font: 'normal normal 400 14px/28px Open sans',
      padding: `0 ${theme.spacing(3)}`,
      letterSpacing: '0px',
      color: '#31345B',
      textTransform: 'uppercase',
      opacity: '0.77',
      marginBottom: '0px',
      marginTop: '16px',
    },
    itemButton: {
      padding: '16px 24px !important',
    },
    itemButtonSelected: {
      padding: '16px 24px !important',
      background: '#F7F9FC 0% 0% no-repeat padding-box',
      backgroundColor: '#F7F9FC !important',
    },
    itemText: {
      textAlign: 'left',
      font: 'normal normal 400 15px/20px Open sans',
      letterSpacing: '0px',
      color: '#43425D',
      margin: '0px',
    },
    itemTextSelected: {
      textAlign: 'left',
      font: 'normal normal 400 15px/20px Open sans',
      letterSpacing: '0px',
      color: '#181819',
      margin: '0px',
    },
    accountIcon: {
      color: '#FEE98E',
      height: '2em',
      width: '2em',
    },
  };
});

interface Props {
  children?: React.ReactNode;
}

export default function ResponsiveDrawer(props: Props) {
  const { children } = props;
  const { classes } = useStyles();
  const location = useLocation();
  const authContextValue = useContext(AuthContext);
  const [signoutOpen, setSignoutOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const idleTimeout = parseInt(process.env.REACT_APP_IDLE_TIMEOUT!);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (authContextValue.isAuthenticated === true) {
      start();
    } else {
      reset();
    }

    setCurrentPage(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const isCurrentPage = (pageName: string) => {
    if (currentPage === pageName) {
      return true;
    } else {
      return false;
    }
  };

  const onIdle = () => {
    console.log('Trigger Signout due to Inactivity');
    localStorage.setItem('promptSignoutDialog', 'promptSignoutDialog');
    localStorage.removeItem('lastInputTime');
    authContextValue.logout();
  };

  const closeAction = () => {
    setSignoutOpen(false);
    handleClose();
  };

  const onAction = () => {
    var getDate = localStorage.getItem('lastInputTime');
    var dateNow = new Date();
    if (getDate !== null) {
      var parseDate = new Date(getDate);
      if (dateNow.getTime() - parseDate.getTime() > idleTimeout) {
        onIdle();
      }
    }
    localStorage.setItem('lastInputTime', new Date().toString());
  };

  const { start, reset } = useIdleTimer({
    onIdle,
    onAction,
    timeout: idleTimeout,
  });

  const getUserTypeMenu = () => {
    if (
      authContextValue.currentUserType === userType.Services_Australia_Admin
    ) {
      return Services_Australia_Menu;
    } else if (authContextValue.currentUserType === userType.DXC_Admin) {
      return DXC_Menu;
    } else if (authContextValue.currentUserType === userType.POS_Developer) {
      return POS_Developer_Menu;
    } else if (authContextValue.currentUserType === userType.POS_Operations) {
      return POS_Operations_Menu;
    } else if (
      authContextValue.currentUserType === userType.POS_Developer_Operations
    ) {
      return POS_Developer_Operations_Menu;
    } else if (authContextValue.currentUserType === userType.PIorAcquirer) {
      return PI_Acquirer_Menu;
    } else {
      return Default_Menu;
    }
  };

  const drawer = (
    <div className={classes.drawerSection}>
      <p className={classes.headerTitle}>PLB Portal</p>
      {getUserTypeMenu().map((section) => (
        <div key={section.label}>
          <p className={classes.sectionTitle}>{section.label}</p>
          <List>
            {section.navItems.map((item) => (
              <ListItem
                button
                className={
                  isCurrentPage(item.to)
                    ? classes.itemButtonSelected
                    : classes.itemButton
                }
                key={item.label}
                component={Link}
                to={item.to}
              >
                <ListItemIcon sx={{ color: item.color }}>
                  {item.icon}
                </ListItemIcon>
                <p
                  className={
                    isCurrentPage(item.to)
                      ? classes.itemTextSelected
                      : classes.itemText
                  }
                >
                  {item.label}
                </p>
              </ListItem>
            ))}
          </List>
        </div>
      ))}
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={signoutOpen}
        aria-describedby="scroll-dialog-description"
      >
        <AuthConsumer>
          {({ logout }) => {
            return (
              <ActionDialog
                headingActionName="sign out"
                actionName="sign out"
                noFunction={() => closeAction()}
                yesFunction={() => logout()}
              />
            );
          }}
        </AuthConsumer>
      </Dialog>
      <AppBar
        position="absolute"
        className={
          authContextValue.isAdmin() ? classes.appBarAdmin : classes.appBar
        }
      >
        <Toolbar id="header-toolbar">
          <AuthConsumer>
            {({ isAuthenticated, user }) => {
              if (isAuthenticated) {
                if (user != null) {
                  return (
                    <>
                      <Box
                        id="dropdownSelect"
                        onClick={handleMenu}
                        className={classes.signOutAppBarArea}
                      >
                        <Box
                          component="span"
                          className={classes.userNameAppBar}
                        >
                          <Typography className="responsiveDrawer_userType_text">
                            {
                              userTypeToString[
                                authContextValue.currentUserType as number
                              ]
                            }
                          </Typography>
                          <Typography className="responsiveDrawer_userName_text">
                            {user.username}
                          </Typography>
                          <Typography className="responsiveDrawer_companyName_text">
                            {authContextValue.currentUserStakeholderName}
                          </Typography>
                        </Box>
                        <AccountCircle className={classes.accountIcon} />
                      </Box>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        open={Boolean(anchorEl)}
                        disableScrollLock={true}
                        onBackdropClick={handleClose}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={() => setSignoutOpen(true)}>
                          Sign Out
                        </MenuItem>
                      </Menu>
                    </>
                  );
                } else {
                  return (
                    <Button
                      className={classes.signOutButton}
                      onClick={() => setSignoutOpen(true)}
                    >
                      Sign Out
                    </Button>
                  );
                }
              }
            }}
          </AuthConsumer>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="Main navigation">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </nav>
      <main className={classes.content}>
        <div className="toolbar" />
        {children}
      </main>
    </div>
  );
}
