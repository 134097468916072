import InformationDialog from '../../../Shared/InformationDialog';

export interface RolesDialogProps {
    closeFunction(): void;
  }

export const RolesDialog = (props: RolesDialogProps) => {
    const { closeFunction } = props;

    return (
        <>
            <InformationDialog
                DialogTitle="Roles"
                closeFunction={closeFunction}
            >
                <ul>
                    <li>POS Developer: POS providers that develop POS solutions for merchants</li> 
                    <li>POS Operational Support: POS provider or reseller providing operational support to merchants</li>
                    <li>Payment Integrator or Acquirer: Payment integrators help POS systems connect with PIN pads. Acquiring banks provide PIN pads to merchants</li> 
                    <li>Other: Other stakeholders interested in PLB</li>
                </ul>
            </InformationDialog>
        </>
    );
}
