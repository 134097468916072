import { CircularProgress, Typography } from '@mui/material';
import './LoadingPage.css';

const LoadingPage = () => {
  return (
    <div className="loadingScreen">
      <CircularProgress size={90}></CircularProgress>
      <Typography variant="h3" sx={{ paddingTop: '2rem' }}>
        Please wait...
      </Typography>
    </div>
  );
};

export default LoadingPage;
