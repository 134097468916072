import './InformationDialog.css';
import {
  Button,
  IconButton,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

export interface InformationDialogProps {
  DialogTitle?: string;
  closeFunction(): void;
  children: React.ReactNode;
}

const InformationDialog = (props: InformationDialogProps) => {
  const { DialogTitle = '', closeFunction, children } = props;

  return (
    <>
      <div className="informationDialog_Container">
        <DialogContent
          id="popup"
          dividers={true}
          className="informationDialog_ContentContainer"
        >
          <div
            id="scroll-dialog-description"
            className="informationDialog_TextContainer"
          >
            <Typography className="informationDialog_HeaderText">
              {DialogTitle}
            </Typography>
            <div className="informationDialog_Content">{children}</div>
          </div>
          <div className="informationDialog_CloseContainer">
            <IconButton
              className="informationDialog_CloseIcon"
              onClick={() => closeFunction()}
            >
              <CancelIcon />
            </IconButton>
          </div>
        </DialogContent>
      </div>
      <div className="informationDialog_Border" />
      <DialogActions className="informationDialog_ButtonContainer">
        <Button variant="primary" onClick={() => closeFunction()}>
          Close
        </Button>
      </DialogActions>
    </>
  );
};

export default InformationDialog;
