import { Alert } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import config from '../../../config/config.json';

const useStyles = makeStyles()((theme) => {
  return {
    alert: {
      marginBottom: '1rem',
    },
    alertCloseButton: {
      color: theme.palette.grey[500],
    },
  };
});

export interface ErrorAlertProps {
  alertAction: string;
  alertVisible: boolean;
}

const ErrorAlert = (props: ErrorAlertProps) => {
  const { alertAction, alertVisible } = props;
  const { classes } = useStyles();

  return (
    <div>
      {alertVisible ? (
        <Alert severity="error" className={classes.alert}>
          <b>Something went wrong.</b>
          <br />
          An error occurred, and {alertAction} at this time. Please try again.
          <br />
          If this error continues to occur, please contact us at{' '}
          <a href={'mailto:' + config.SERVICES_AUS_EMAIL}>
            {config.SERVICES_AUS_EMAIL}
          </a>
          .
        </Alert>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ErrorAlert;
