import React from 'react';
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

/// The Accordion component implements the AccordionProps interface, taking in {heading: string, body: string}, where the heading is the title, and the body is the body of the accordion.

// Accordion example:

/* <Accordion
  heading = "Accordion question 1"
  body = "Accordion body - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod."
/> */

export interface AccordionProps {
  parentIndex?: number;
  index?: number;
  heading: string;
  body: any;
  expanded?: boolean;
  handleAChange?: (parentIndex?: number, index?: number) => void;
}

const useStyles = makeStyles()((theme) => {
  return {
    accordion_header_collapsed: {
      textAlign: 'left',
      color: 'var(--dss-teal-text)',
      font: 'normal normal 600 1.25rem/1.5rem Open Sans',
      letterSpacing: '0.0125rem',
      opacity: '1',
      paddingRight: '3.125rem',
    },
    accordion_header_expanded: {
      textAlign: 'left',
      color: 'var(--dss-teal-text)',
      font: 'normal normal bold 1.25rem/1.5rem Open Sans',
      letterSpacing: '0.0125rem',
      opacity: '1',
      paddingRight: '3.125rem',
    },
    accordion_body: {
      textAlign: 'left',
      font: 'normal normal normal 1.125rem/2rem Open Sans',
      letterSpacing: '0',
      color: '#3D4D5A',
      opacity: '1',
      marginBottom: '1.25rem',
    },
    icon_button: {
      width: '2rem',
      height: '2rem',
      color: 'var(--dss-teal-text)',
      font: 'normal normal bold 1.25rem/1.5rem Open Sans',
      boxSizing: 'border-box',
      justifySelf: 'flex-end',
    },
    mui_accordion: {
      minHeight: '5.9375rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      boxSizing: 'border-box',
      padding: '1.25rem 2.5rem',
      marginBottom: '15px',
      boxShadow: '0rem 0.375rem 0.625rem #00000008',
      border: '0.0625rem solid #E5EAF4',
      borderRadius: '0.5rem',
      opacity: '1',
      '&.MuiAccordion-root:before': {
        backgroundColor: 'transparent',
      },
    },
    mui_accordion_summary: {
      boxSizing: 'border-box',
      padding: '0',
    },
    mui_accordion_details: {
      boxSizing: 'border-box',
      padding: '0',
      textAlign: 'start',
      paddingRight: '5.125rem',
    },
  };
});

export const Accordion = (props: AccordionProps) => {
  const { parentIndex, index, heading, body, expanded, handleAChange } = props;
  const { classes } = useStyles();

  const handleChange = (parentIndex?: number, index?: number) => {
    if (handleAChange !== undefined) {
      handleAChange(parentIndex, index);
    }
  };

  return (
    <>
      <MuiAccordion
        className={classes.mui_accordion}
        expanded={expanded}
        key="{parentIndex} || '' + '-' + {index} || ''"
        onChange={() => handleChange(parentIndex, index)}
      >
        <AccordionSummary
          className={classes.mui_accordion_summary}
          expandIcon={
            <IconButton className={classes.icon_button}>
              {expanded ? '−' : '+'}
            </IconButton>
          }
        >
          <Typography
            className={
              expanded
                ? classes.accordion_header_expanded
                : classes.accordion_header_collapsed
            }
          >
            {heading}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.mui_accordion_details}>
          <Typography className={classes.accordion_body}>{body}</Typography>
        </AccordionDetails>
      </MuiAccordion>
    </>
  );
};

export default Accordion;
