import { LogLevel } from '@azure/msal-browser';

import { ResponseMode } from '@azure/msal-common';

export const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_AZURE_SIGNINOUTPOLICY,
    forgotPassword: process.env.REACT_APP_AZURE_PWDRESETPOLICY,
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${process.env.REACT_APP_AZURE_TENANT}.b2clogin.com/${process.env.REACT_APP_AZURE_TENANT}.onmicrosoft.com/${process.env.REACT_APP_AZURE_SIGNINOUTPOLICY}`,
    },
    forgotPassword: {
      authority: `https://${process.env.REACT_APP_AZURE_TENANT}.b2clogin.com/${process.env.REACT_APP_AZURE_TENANT}.onmicrosoft.com/${process.env.REACT_APP_AZURE_PWDRESETPOLICY}`,
    },
  },
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_APP_ID || '',
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [
      b2cPolicies.authorities.signUpSignIn.authority,
      b2cPolicies.authorities.forgotPassword.authority,
    ],
    redirectUri:
      process.env.REACT_APP_AZURE_B2C_REDIRECT_URI_AFTER_LOGIN ||
      process.env.REACT_APP_AZURE_B2C_REDIRECT_URI_LOGOUT ||
      undefined,
    postLogoutRedirectUri:
      process.env.REACT_APP_AZURE_B2C_REDIRECT_URI_LOGOUT || undefined,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
  },
};

/**
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
 */
export const loginRequest = {
  scopes: [],
  extraScopesToConsent: [
    `https://${process.env.REACT_APP_AZURE_TENANT}.onmicrosoft.com/api/merchant.api`,
  ],
  authority: '',
  correlationId: '',
  redirectUri: '',
  responseMode: ResponseMode.FRAGMENT,
  state: '',
  nonce: '',
};

// The current application coordinates were pre-registered in a B2C tenant.
export const apiConfig = {
  b2cScopes: loginRequest.extraScopesToConsent,
  webApi: process.env.REACT_APP_API,
};

// Add here scopes for access token to be used at the API endpoints.
export const tokenRequest = {
  scopes: apiConfig.b2cScopes, // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
};

export const tokenReq = {
  scopes: apiConfig.b2cScopes, // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
};

export const redirectReq = {
  scopes: apiConfig.b2cScopes, // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
};

export const merchantReq = {
  scopes: [
    `https://${process.env.REACT_APP_AZURE_TENANT}.onmicrosoft.com/api/merchant.api`,
  ],
};
