import { userType } from './mainnav';

// information about the user in the database
export interface UserInfo {
  userId: string;
  userTypeName: string;
  stakeholderName: string;
}

export class UserInfoDto {
  userId!: string;
  userTypeName!: string;
  userType!: userType;
  stakeholderName!: string;
}
