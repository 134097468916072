import { Box, Grid, Link, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import {
  HomeLocation,
  ExpressInterestLocation,
} from '../../publicRouteConstants';
import { AuthConsumer } from '../../context/AuthContext';
import './PublicFooter.css';
import config from '../../config/config.json';

const useStyles = makeStyles()((theme) => {
  return {
    footer_link: {
      color: 'white',
      textDecorationColor: 'white',
    },
  };
});

const PublicFooter = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const goToLocation = (location: string) => {
    window.scrollTo(0, 0);
    navigate(location);
  };

  return (
    <Box className="footer footer_gutter">
      <div className="footer_container">
        <Grid container className="footer_section1">
          <Grid item xs={12} md={3}>
            <Typography className="footer_site_title">PLB Portal</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              onClick={() => goToLocation(HomeLocation.path)}
              onKeyPress={(e) =>
                e.key === 'Enter' && goToLocation(HomeLocation.path)
              }
              tabIndex={0}
              className="footer_link_text"
            >
              Home page
            </Typography>
            <br />
            <AuthConsumer>
              {({ login }) => {
                return (
                  <>
                    <Typography
                      onClick={login}
                      onKeyPress={(e) => e.key === 'Enter' && login}
                      tabIndex={0}
                      className="footer_link_text"
                    >
                      Sign in to the PLB Portal
                    </Typography>{' '}
                    <br />
                  </>
                );
              }}
            </AuthConsumer>
            <Typography
              onClick={() => goToLocation(ExpressInterestLocation.path)}
              onKeyPress={(e) =>
                e.key === 'Enter' && goToLocation(ExpressInterestLocation.path)
              }
              tabIndex={0}
              className="footer_link_text_last"
            >
              Express interest
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className="footer_text">
              Services Australia acknowledges the Traditional Custodians of the
              lands we live on. We pay our respects to all Elders, past and
              present, of all Aboriginal and Torres Strait Islander nations.
            </Typography>
          </Grid>
        </Grid>

        <Typography className="footer_copyright">
          If you have any questions or would like more information, contact the
          Services Australia PLB Team at{' '}
          <Link
            className={classes.footer_link}
            href={'mailto:' + config.SERVICES_AUS_EMAIL}
          >
            {config.SERVICES_AUS_EMAIL}
          </Link>
          <br />
          <br />
          Copyright © 2023. Services Australia.
        </Typography>
      </div>
    </Box>
  );
};

export default PublicFooter;
