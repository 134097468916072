import React, { useEffect } from 'react';
import PageHeader from '../Shared/PageHeader';
import PageOverlayContent from '../Shared/PageOverlayContent';
import { StakeholderDocumentRow } from '../../models/stakeholderDocumentRow';
import StakeholderDocumentTable from '../Shared/StakeholderDocumentTable';
import { getStakeholderDocumentsApi } from '../../utils/StakeholderDocumentUtil';
import { Helmet } from 'react-helmet';
import { PageTitles } from '../../utils/PageTitles';
import './TestResults.css';

const TestResults = () => {
  const [allData, setAllData] = React.useState<StakeholderDocumentRow[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const getStakeholderDocuments = (
    callback: (fileCategory: string) => Promise<StakeholderDocumentRow[]>,
  ) => {
    // Return the document
    callback('Test Results')
      .then((documentTable) => {
        // Set the state for the new documents
        setAllData(documentTable);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  // useEffect to initialise documents
  useEffect(() => {
    getStakeholderDocuments(getStakeholderDocumentsApi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{PageTitles.TestResults}</title>
      </Helmet>
      <PageHeader headerTitle="Test Results" hasOverlay={true} isAdmin={false}>
        {
          'See your test results here.\nTest results are provided by DXC during Configuration Acceptance Testing.'
        }
      </PageHeader>
      <PageOverlayContent>
        <div className="Overlay_box">
          <StakeholderDocumentTable
            data={allData}
            tableName={'TestResults'}
            isLoading={isLoading}
            canSeeUploader={true}
            canUpload={false}
            canDelete={false}
            isAccordion={false}
            fileCategory={'Test Results'}
          />
        </div>
      </PageOverlayContent>
    </>
  );
};

export default TestResults;
