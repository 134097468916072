import { Dialog } from '@mui/material';
import React from 'react';
import './ModalLink.css';
import InfoIcon from '@mui/icons-material/Info';

export interface ModalLinkProps {
  children: React.ReactNode;
  dialog?: JSX.Element;
  open: boolean;
  handleOpen(): void;
  handleClose(): void;
  form?: boolean;
}

const ModalLink = (props: ModalLinkProps) => {
  const { children, dialog, open, handleOpen, handleClose, form } = props;

  return (
    <>
    
      <div className="modalLink_container" onClick={handleOpen}>
        {form && <InfoIcon className="modalLink_icon" sx={{marginRight: '0.4rem'}}/>}
        <a>{children}</a>
        {!form && <InfoIcon className="modalLink_icon" sx={{marginLeft: '0.1875rem'}}/>}
      </div>

      <Dialog open={open} onClose={handleClose}>
        {dialog}
      </Dialog>
    </>
  );
};

export default ModalLink;
