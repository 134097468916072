import { Typography } from '@mui/material';
import SearchBar from '../SearchBar';
import './PageHeader.css';

export interface PageHeaderProps {
  children?: React.ReactNode;
  headerTitle: string;
  hasOverlay?: boolean;
  isAdmin?: boolean;
  hasSearchBar?: boolean;
  searchLabel?: string;
  searchValue?: string;
  setSearchValue?: (value: string) => void;
}

const PageHeader = (props: PageHeaderProps) => {
  const {
    children,
    hasSearchBar = false,
    headerTitle,
    hasOverlay = false,
    isAdmin = false,
    searchLabel,
    searchValue,
    setSearchValue,
  } = props;

  const getHeaderClass = () => {
    if (isAdmin) {
      if (hasOverlay) {
        return 'pageHeader pageHeader_Admin pageHeaderOverlay';
      } else {
        return 'pageHeader pageHeader_Admin';
      }
    } else {
      if (hasOverlay) {
        return 'pageHeader pageHeader_User pageHeaderOverlay';
      } else {
        return 'pageHeader pageHeader_User';
      }
    }
  };

  return (
    <div className={getHeaderClass()}>
      <div className="pageHeader_TextContainer">
        <Typography variant="h1">{headerTitle}</Typography>
        {children && (
          <Typography variant="body2" className="pageHeader_HeaderText">
            {children}
          </Typography>
        )}
      </div>
      {hasSearchBar && (
        <div className="pageHeader_SearchBarContainer">
          <SearchBar
            label={searchLabel}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </div>
      )}
    </div>
  );
};

export default PageHeader;
