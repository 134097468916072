import { Chip } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  root: {
    backgroundColor: '#D1F8BF',
    color: '#063E20',
    height: '1.188rem',
    width: '2.5rem',
    marginRight: '0.313rem',
    '& .MuiChip-label': {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
});

const PIAChip = () => {
  const { classes } = useStyles();

  return (
    <>
      <Chip
        className={classes.root}
        label="PI/A"
        title="Payment Integrator/Acquirer"
      />
    </>
  );
};

export default PIAChip;
