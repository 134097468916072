import './PageOverlayContent.css';

export interface PageOverlayContentProps {
  children: React.ReactNode;
}

const PageOverlayContent = (props: PageOverlayContentProps) => {
  const { children } = props;

  return (
    <div className="pageContent_Padding">
      <div className="pageContent_Margin">{children}</div>
    </div>
  );
};

export default PageOverlayContent;
