import ApiService from '../services/ApiService';
import { StakeholderDocumentRow } from '../models/stakeholderDocumentRow';

export const getStakeholderContainersApi = async () => {
  // Create object to return data
  let dataObject = new Map<string, string>();

  await ApiService.getStakeholderContainers().then((x) => {
    //  Convert into key value array and filter Services Australia and dxc from the list
    let organisationList = Object.entries(x.data)
      .map(([key, value]) => ({ key, value }))
      .filter(
        (organisationItem) =>
          organisationItem.value !== 'services-australia' &&
          organisationItem.value !== 'dxc',
      );

    organisationList.sort((a, b) =>
      a.key.toLowerCase() > b.key.toLowerCase() ? 1 : -1,
    );

    organisationList.forEach((org) => {
      dataObject.set(org.value, org.key);
    });
  });
  return dataObject;
};

export const getStakeholderDocumentsByContainerApi = async (
  container: string,
  fileCategory: string,
) => {
  let newTable: StakeholderDocumentRow[] = [];
  await ApiService.getStakeholderDocumentsByContainer(container)
    .then((x) => {
      let data = x.data.filter(
        (document) => document.category === fileCategory,
      );
      for (let document of data) {
        let newRow: StakeholderDocumentRow = {
          id: newTable.length,
          name: document.fileName,
          date: new Date(document.createdOn),
          link: container + '/' + document.fileName,
          type: document.category,
          createdBy: document.createdBy,
          isDownloading: false,
        };
        newTable.push(newRow);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return newTable;
};

export const getStakeholderDocumentsApi = async (fileCategory: string) => {
  let newTable: StakeholderDocumentRow[] = [];
  await ApiService.getStakeholderDocuments()
    .then((x) => {
      let data = x.data.filter(
        (document) => document.category === fileCategory,
      );
      for (let document of data) {
        let newRow: StakeholderDocumentRow = {
          id: newTable.length,
          name: document.fileName,
          date: new Date(document.createdOn),
          link: document.fileName,
          type: document.category,
          createdBy: document.createdBy,
          isDownloading: false,
        };
        newTable.push(newRow);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return newTable;
};

export const fileValidation = (
  documentType: string,
  availableFiles: string[],
  fileName: string,
  fileType: string,
  fileSize: number,
  stakeholderName?: string,
) => {
  let errorMessage = [];

  if (/[+#%&<>;]/.test(fileName)) {
    errorMessage.push(
      'Filenames cannot contain the following characters: + # % & < > ;',
    );
  }
  if (fileName.length > 100) {
    errorMessage.push('Filenames cannot be greater than 100 characters');
  }
  if (availableFiles.includes(fileName)) {
    if (documentType === 'Generic Document') {
      errorMessage.push(
        'This file already exists. Please delete the existing file and then upload the new copy, or use a unique filename.',
      );
    } else {
      errorMessage.push(
        `A file with the same name already exists in one of the ${stakeholderName} PLB Tasks file lists. Please use a unique filename, or if relevant locate and delete the existing file and then upload the new copy.`,
      );
    }
  }
  if (fileSize === 0) {
    errorMessage.push(
      'File size too small. Files must be greater than 0 bytes.',
    );
  }
  if (fileSize > 209715200) {
    errorMessage.push('Max file size exceeded. Files cannot exceed 200 MB');
  }

  return errorMessage;
};

export const getStakeholderContainerName = (stakeholderName: string) => {
  let containerName = '';

  containerName = stakeholderName.toLowerCase();
  containerName = containerName.replaceAll(' ', '-');

  return containerName;
};
