import React from 'react';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import action_button_download from '../../../assets/icons/action_button_download.svg';
import action_button_delete from '../../../assets/icons/action_button_delete.svg';
import action_button_open from '../../../assets/icons/action_button_open.svg';
import action_button_edit_visibility from '../../../assets/icons/action_button_edit_visibility.svg';
import action_button_update_info from '../../../assets/icons/action_update_info.svg';
import action_button_manage_secret from '../../../assets/icons/action_manage_secret.svg';
import action_button_view_credentials from '../../../assets/icons/action_button_view_secret.svg';
import './IconActionButton.css';

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      padding: '0.9375rem',
      '&:hover, &.Mui-focusVisible': { padding: '0.9375rem' },
    },
  };
});

export interface IconActionButtonProps {
  onClick: () => void;
  variant:
    | 'download'
    | 'delete'
    | 'open'
    | 'editVisibility'
    | 'updateInfo'
    | 'manageSecret'
    | 'viewCredentials';
}

export const IconActionButton = (props: IconActionButtonProps) => {
  const { onClick, variant } = props;
  const { classes } = useStyles();

  const GetSrc = (variantParam: string) => {
    switch (variantParam) {
      case 'download':
        return action_button_download;

      case 'delete':
        return action_button_delete;

      case 'open':
        return action_button_open;

      case 'editVisibility':
        return action_button_edit_visibility;

      case 'updateInfo':
        return action_button_update_info;

      case 'manageSecret':
        return action_button_manage_secret;

      case 'viewCredentials':
        return action_button_view_credentials;

      default:
        return action_button_download;
    }
  };

  const GetAlt = (variantParam: string) => {
    switch (variantParam) {
      case 'download':
        return 'Download Button';

      case 'delete':
        return 'Delete Button';

      case 'open':
        return 'Open Button';

      case 'editVisibility':
        return 'Edit Visibility Button';

      case 'updateInfo':
        return 'Update Info Button';

      case 'manageSecret':
        return 'Manage Secret Button';

      case 'viewCredentials':
        return 'View Credentials Button';

      default:
        return 'Download Button';
    }
  };

  const GetTitle = (variantParam: string) => {
    switch (variantParam) {
      case 'download':
        return 'Download';

      case 'delete':
        return 'Delete';

      case 'open':
        return 'Open';

      case 'editVisibility':
        return 'Edit visibility';

      case 'updateInfo':
        return 'Update info';

      case 'manageSecret':
        return 'Manage secret';

      case 'viewCredentials':
        return 'View credentials';

      default:
        return 'Download';
    }
  };

  return (
    <>
      <IconButton
        size="large"
        onClick={onClick}
        className={classes.root}
        title={GetTitle(variant)}
      >
        <img
          src={GetSrc(variant)}
          className="iconActionButton"
          alt={GetAlt(variant)}
        />
      </IconButton>
    </>
  );
};
