import { SelectChangeEvent } from '@mui/material/Select';
import { Dialog } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import ActionDialog from '../Shared/ActionDialog';
import { StakeholderDocumentRow } from '../../models/stakeholderDocumentRow';
import {
  getStakeholderContainersApi,
  getStakeholderDocumentsByContainerApi,
} from '../../utils/StakeholderDocumentUtil';
import PageHeader from '../Shared/PageHeader';
import PageOverlayContent from '../Shared/PageOverlayContent';
import StakeholderDocumentTable from '../Shared/StakeholderDocumentTable';
import { StakeholderUploadDialog } from '../Shared/StakeholderUploadDialog';
import { Helmet } from 'react-helmet';
import { PageTitles } from '../../utils/PageTitles';
import './TestResults.css';
import ApiService from '../../services/ApiService';

const AdminTestResults = () => {
  const authContextValue = useContext(AuthContext);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteFileName, setDeleteFileName] = React.useState('');
  const [deleteLink, setDeleteLink] = React.useState('');
  const [allData, setAllData] = React.useState<StakeholderDocumentRow[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [stakeholderNames, setStakeholderNames] = React.useState<string[]>([
    '',
  ]);
  const [stakeholderContainers, setStakeholderContainers] = React.useState<
    string[]
  >([]);
  const [stakeholderIndex, setStakeholderIndex] = React.useState(-1);
  const [stakeholderName, setStakeholderName] = React.useState<string>('');
  const [uploadOpen, setUploadOpen] = React.useState(false);

  const getStakeholderContainers = async (
    callback: () => Promise<Map<string, string>>,
  ) => {
    // Return the list
    await callback().then((mappedContainers) => {
      // Set state of containers and names using return list
      let containers = Array.from(mappedContainers.keys());
      let names = Array.from(mappedContainers.values());
      setStakeholderContainers(containers);
      setStakeholderNames(names);
    });
  };

  const getStakeholderDocumentsByContainer = (
    callback: (
      container: string,
      fileCategory: string,
    ) => Promise<StakeholderDocumentRow[]>,
    containerName: string,
  ) => {
    // Reset the list for new stakeholder query
    setAllData([]);

    // Return the document row
    callback(containerName, 'Test Results')
      .then((documentTable) => {
        // Set state for new documents
        setAllData(documentTable);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  // useEffect to initialise documents
  useEffect(() => {
    getStakeholderContainers(getStakeholderContainersApi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect to monitor change of stakeholders in drop down
  useEffect(() => {
    if (stakeholderIndex > -1) {
      getStakeholderDocumentsByContainer(
        getStakeholderDocumentsByContainerApi,
        stakeholderContainers[stakeholderIndex],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stakeholderIndex]);

  // Handle the changing of drop down stakeholder selector
  const handleChange = (event: SelectChangeEvent) => {
    setIsLoading(true);
    setStakeholderIndex(stakeholderNames.indexOf(event.target.value));
    setStakeholderName(event.target.value);
  };

  const refreshDocuments = () => {
    setUploadOpen(false);
    getStakeholderDocumentsByContainer(
      getStakeholderDocumentsByContainerApi,
      stakeholderContainers[stakeholderNames.indexOf(stakeholderName)],
    );
  };

  const openDeleteDialog = (row: StakeholderDocumentRow) => {
    setDeleteFileName(row.name);
    setDeleteLink(row.link);
    setDeleteOpen(true);
  };

  const confirmDelete = () => {
    ApiService.deleteStakeholderDocumentAdmin(deleteLink)
      .then(() => {
        let removeFromData = allData.filter((x) => x.link !== deleteLink);
        setAllData(removeFromData);
        setDeleteOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setDeleteOpen(false);
      });
  };

  const showHideUploadDelete = () => {
    if (authContextValue.isDXCAdmin()) {
      if (stakeholderName === '') {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={deleteOpen}
        aria-describedby="scroll-dialog-description"
      >
        <ActionDialog
          headingActionName="delete this file"
          actionName="delete file"
          fileName={deleteFileName}
          noFunction={() => setDeleteOpen(false)}
          yesFunction={() => confirmDelete()}
        />
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={uploadOpen}
        aria-describedby="scroll-dialog-description"
      >
        <StakeholderUploadDialog
          closeDialog={() => setUploadOpen(false)}
          confirmDialog={() => refreshDocuments()}
          fileCategory="Test Results"
          stakeholderName={stakeholderName}
          userName={authContextValue.user?.username as string}
          admin={true}
        />
      </Dialog>
      <Helmet>
        <title>{PageTitles.TestResults}</title>
      </Helmet>
      <PageHeader headerTitle="Test Results" hasOverlay={true} isAdmin={true}>
        {
          'DXC will upload test results for stakeholder organisations here.\nTest results are provided during Configuration Acceptance Testing.\nUse the dropdown menu below to filter by organisation.'
        }
      </PageHeader>
      <PageOverlayContent>
        <StakeholderDocumentTable
          data={allData}
          tableName={stakeholderNames[stakeholderIndex]}
          isLoading={isLoading}
          canSeeUploader={true}
          canUpload={showHideUploadDelete()}
          canDelete={showHideUploadDelete()}
          isAccordion={false}
          deleteFunction={openDeleteDialog}
          uploadFunction={() => setUploadOpen(true)}
          canSelectStakeholder={true}
          selectedStakeholderIndex={stakeholderIndex}
          handleStakeholderChange={handleChange}
          stakeholderNames={stakeholderNames}
          fileCategory="Test Results"
        />
      </PageOverlayContent>
    </>
  );
};

export default AdminTestResults;
