import React, { useContext } from 'react';
import PageHeader from '../Shared/PageHeader';
import PageOverlayContent from '../Shared/PageOverlayContent/PageOverlayContent';
import { AuthContext } from '../../context/AuthContext';
import { Helmet } from 'react-helmet';
import { PageTitles } from '../../utils/PageTitles';
import { Link, List, ListItem, Typography } from '@mui/material';
import config from '../../config/config.json';
import './Contact.css';

const Contact = () => {
  const authContextValue = useContext(AuthContext);

  return (
    <>
      <Helmet>
        <title>{PageTitles.Contact}</title>
      </Helmet>
      <PageHeader
        headerTitle="Contact Details"
        hasOverlay={false}
        isAdmin={authContextValue.isAdmin()}
      >
        {'See PLB contact details here.'}
      </PageHeader>
      <PageOverlayContent>
        <div className="Contact_adminPadding">
          <div className="contactInfo_top_box">
            <div className="contactInfo_content">
              <Typography align="left" color={'#31345b'}>
                {'If you: \n '}
                <List sx={{ listStyleType: 'disc', pl: 5 }}>
                  <ListItem sx={{ display: 'list-item' }}>
                    {
                      'have any questions relating to merchant site credentials, or'
                    }
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }}>
                    {'have any questions on testing or accreditation,'}
                  </ListItem>
                </List>
                {'you can contact the DXC Technology team at '}
                <Link href={'mailto:' + config.DXC_EMAIL}>
                  {config.DXC_EMAIL}
                </Link>
                {'.'}
              </Typography>
            </div>
            <div className="contactInfo_content">
              <Typography align="left" color={'#31345b'}>
                {'If you: \n '}
                <List sx={{ listStyleType: 'disc', pl: 5 }}>
                  <ListItem sx={{ display: 'list-item' }}>
                    {
                      'have any questions or would like more information about PLB'
                    }
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }}>
                    {'have feedback regarding your experience with PLB, or'}
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }}>
                    {'need to give an update on changes in your business,'}
                  </ListItem>
                </List>
                {'you can contact the Services Australia PLB Team at '}
                <Link href={'mailto:' + config.SERVICES_AUS_EMAIL}>
                  {config.SERVICES_AUS_EMAIL}
                </Link>
                {'.'}
              </Typography>
            </div>
          </div>
        </div>
      </PageOverlayContent>
    </>
  );
};

export default Contact;
