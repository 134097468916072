import './SearchBar.css';
import { TextField, InputAdornment, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const HeaderSearchBarTheme = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #43425D',
    '& input': {
      font: 'normal normal 500 0.875rem/0.875rem Open Sans !important',
      letterSpacing: '0px',
      color: '#2C1936 !important',
      padding: '16.5px 14px 16.5px 0px !important',
    },
    '& input::placeholder': {
      font: 'normal normal 500 0.875rem/0.875rem Open Sans',
      letterSpacing: '0px',
      color: '#4D4F5C',
      opacity: '1',
    },
    '& fieldset': {
      border: 'none',
    },
    'min-width': '18.75rem',
    'align-self': 'flex-end',
  },
}));

export interface SearchBarProps {
  searchValue?: string;
  setSearchValue?: (value: string) => void;
  label?: string;
}

const SearchBar = (props: SearchBarProps) => {
  const { searchValue, setSearchValue, label } = props;

  const getValue = () => {
    if (searchValue !== undefined) {
      return searchValue;
    } else {
      return '';
    }
  };

  const setValue = (value: string) => {
    if (setSearchValue !== undefined) {
      setSearchValue(value);
    }
  };

  return (
    <HeaderSearchBarTheme
      fullWidth={true}
      id="outlined-search"
      placeholder={label ? label : 'Search'}
      margin="none"
      type="text"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setValue(event.target.value)
      }
      value={getValue()}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className="searchBar_searchIcon" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
