import { Grid, Typography } from '@mui/material';
import React from 'react';
import InformationDialog from '../../Shared/InformationDialog/InformationDialog';

export interface ProviderListDialogProps {
  closeFunction(): void;
}

export const ProviderListDialog = (props: ProviderListDialogProps) => {
  const { closeFunction } = props;

  return (
    <>
      <InformationDialog
        DialogTitle="PLB-accredited POS providers"
        closeFunction={closeFunction}
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={4}>
            <ul>
              <li>
                <Typography variant="body2">
                  ABS POS Systems
                  <br />
                  <i>RetailTouch</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  Beacon Business Systems
                  <br />
                  <i>Beacon POS</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  Bepoz
                  <br />
                  <i>SmartPOS</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  Black Label Solutions
                  <br />
                  <i>NomadPOS</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  Companion Software
                  <br />
                  <i>EDGEPos</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  FutureNet
                  <br />
                  <i>OSPOS, ConveniencePOS</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  GaP Solutions
                  <br />
                  <i>EM Cloud, EM POS</i>
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={4}>
            <ul>
              <li>
                <Typography variant="body2">
                  GPK Group
                  <br />
                  <i>Merlin POS, Merlin ESP</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  H&L
                  <br />
                  <i>Exceed POS</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  Idealpos
                  <br />
                  <i>Idealpos</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  Impulse Retailing
                  <br />
                  <i>Impulse Touch</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  Independent Solutions
                  <br />
                  <i>PROFIT-TRACK</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  Kreative Technology
                  <br />
                  <i>Konvenience</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  LS Retail
                  <br />
                  <i>LS Retail - LS Central</i>
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={4}>
            <ul>
              <li>
                <Typography variant="body2">
                  POS Solutions
                  <br />
                  <i>PosBrowser</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  Quickfuel
                  <br />
                  <i>Quickfuel powered by SwiftPOS</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  SmartFuel
                  <br />
                  <i>SmartFuel POS</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  Starrtec Solutions
                  <br />
                  <i>StarrPOS</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  Surefire Systems
                  <br />
                  <i>Surefire POS</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  SwiftPOS
                  <br />
                  <i>SwiftPOS</i>
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body2">
                  Tower Systems
                  <br />
                  <i>Retailer</i>
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </InformationDialog>
    </>
  );
};
