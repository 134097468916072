import React from 'react';
import InformationDialog from '../../Shared/InformationDialog/InformationDialog';

export interface CashEquivalentsDialogProps {
  closeFunction(): void;
}

export const CashEquivalentsDialog = (props: CashEquivalentsDialogProps) => {
  const { closeFunction } = props;

  return (
    <>
      <InformationDialog
        DialogTitle="Cash-like products"
        closeFunction={closeFunction}
      >
        A cash-like product is an item that can be used like cash to purchase
        alcohol, gambling services, tobacco, pornography or to withdraw cash.
        Examples include some gift cards, prepaid cards, store credit or
        e-vouchers.
      </InformationDialog>
    </>
  );
};
