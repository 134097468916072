// Standard Javascript format for this application that excludes weekday
export const formatDate = (dateInput: Date) => {
  return dateInput.toLocaleDateString('en-AU', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

// Standard Javascript format for this application that excludes weekday and seconds
export const formatDateTime = (dateInput: Date) => {
  const time = dateInput.toLocaleTimeString('en-AU', {
    minute: '2-digit',
    hour: 'numeric',
  });
  return formatDate(dateInput) + ' ' + time;
};
