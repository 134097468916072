import { Box, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { HomeLocation } from '../../../publicRouteConstants';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles()((theme) => {
  return {
    button: {
      margin: `${theme.spacing(2)} 0`,
    },
    success_title: {
      textAlign: 'center',
      font: 'normal normal bold 2.25rem/4.25rem Open Sans',
      letterSpacing: '0.0125rem',
      color: '#000000',
      opacity: '1',
    },
    success_sub_text: {
      textAlign: 'center',
      font: 'normal normal normal 1.25rem/2.25rem Open Sans',
      letterSpacing: '0',
      color: '#000000',
      opacity: '1',
      padding: '0.25rem 0 0',
      whiteSpace: 'pre-wrap',
    },
    doc_container: {
      margin: '0 5% 0.937rem',
      border: '0.0625rem solid #BABABA',
      borderRadius: '0.25rem',
      padding: '0.937rem',
    },
    document_name: {
      textAlign: 'left',
      font: 'normal normal bold 1.5rem/2.25rem Open Sans',
      letterSpacing: '0',
      color: '#000000',
      opacity: '1',
    },
    document_summary: {
      textAlign: 'left',
      font: 'normal normal normal 1rem/2.25rem Open Sans',
      letterSpacing: '0',
      color: '#000000',
      opacity: '1',
    },
    download_button_container: {
      textAlign: 'right',
    },
    download_button: {
      width: '90%',
      margin: '0.625rem',
      fontWeight: 'bold',
    },
    thankyou_button_container: {
      textAlign: 'center',
      marginTop: '3.75rem',
    },
    thankyou_button: {
      margin: '0.625rem',
    },
    content_panel: {
      background: '#FFFFFF',
      boxShadow: '0 0.625rem 2.1875rem  #00000008',
      borderRadius: '0.5rem',
      opacity: '1',
      padding: '3.125rem 1rem',
      width: '56.25rem',
      boxSizing: 'border-box',
      height: 'max-content',
    },
  };
});

export interface ThankYouProps {
  emailName: string;
}

export const ThankYou = (props: ThankYouProps) => {
  const { emailName } = props;
  const { classes } = useStyles();
  const navigate = useNavigate();

  const goToLocation = (location: string) => {
    window.scrollTo(0, 0);
    navigate(location);
  };

  return (
    <div className={classes.content_panel}>
      <Box>
        <Typography className={classes.success_title}>Thank you!</Typography>
        <Typography className={classes.success_sub_text}>
          {'A Services Australia PLB team member will be in\ntouch with you shortly.\n\nA copy of your submission has been sent to your email address\n"' +
            emailName +
            '" along with overview documents to provide\nyou with more information about the PLB program.'}
        </Typography>
        <Grid
          container
          justifyContent="center"
          spacing="3"
          className={classes.thankyou_button_container}
        >
          <Grid item xs={12} sm={5} md={4}>
            <Button
              variant="primary"
              className={classes.thankyou_button}
              onClick={() => goToLocation(HomeLocation.path)}
            >
              Back to home
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
