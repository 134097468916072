import { useContext } from 'react';
import { AuthContext } from './context/AuthContext';
import { Route, Routes } from 'react-router-dom';
import {
  HomeLocation,
  NoticesBriefsLocation,
  CredentialsLocation,
  ResourcesLocation,
  FrequentlyAskedQuestionsLocation,
  MyDeliverablesLocation,
  MyTestingLocation,
  TestResultsLocation,
  MyAccreditationLocation,
  ContactLocation,
} from './privateRouteConstants';
import Merchants from './components/Merchants';
import NoticesBriefs from './components/NoticesBriefs';
import PLBResources from './components/PLBResources';
import FAQs from './components/FAQs';
import Deliverables from './components/Deliverables';
import TestEvidence from './components/TestEvidence';
import AdminTestEvidence from './components/TestEvidence/AdminTestEvidence';
import AdminDeliverables from './components/Deliverables/AdminDeliverables';
import AdminPLBResources from './components/PLBResources/AdminPLBResources';
import AdminNoticesBriefs from './components/NoticesBriefs/AdminNoticesBriefs';
import { PageNotFound } from './components/PageNotFound';
import HomePage from './components/HomePage';
import AdminAccreditation from './components/Accreditation/AdminAccreditation';
import Accreditation from './components/Accreditation/Accreditation';
import TestResults from './components/TestResults/TestResults';
import AdminTestResults from './components/TestResults/AdminTestResults';
import Contact from './components/Contact/Contact';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <Routes>
    <Route
      path={HomeLocation.path}
      element={
        useContext(AuthContext).isAdmin() ? (
          <AdminNoticesBriefs />
        ) : (
          <NoticesBriefs />
        )
      }
    />
    <Route path={CredentialsLocation.path} element={<Merchants />} />
    <Route
      path={NoticesBriefsLocation.path}
      element={
        useContext(AuthContext).isAdmin() ? (
          <AdminNoticesBriefs />
        ) : (
          <NoticesBriefs />
        )
      }
    />
    <Route
      path={ResourcesLocation.path}
      element={
        useContext(AuthContext).isAdmin() ? (
          <AdminPLBResources />
        ) : (
          <PLBResources />
        )
      }
    />
    <Route
      path={MyDeliverablesLocation.path}
      element={
        useContext(AuthContext).isAdmin() ? (
          <AdminDeliverables />
        ) : (
          <Deliverables />
        )
      }
    />
    <Route
      path={FrequentlyAskedQuestionsLocation.path}
      element={
        useContext(AuthContext).isAuthenticated ? <FAQs /> : <HomePage />
      }
    />
    <Route
      path={MyTestingLocation.path}
      element={
        useContext(AuthContext).isAdmin() ? (
          <AdminTestEvidence />
        ) : (
          <TestEvidence />
        )
      }
    />
    <Route
      path={MyAccreditationLocation.path}
      element={
        useContext(AuthContext).isAdmin() ? (
          <AdminAccreditation />
        ) : (
          <Accreditation />
        )
      }
    />
    <Route
      path={TestResultsLocation.path}
      element={
        useContext(AuthContext).isAdmin() ? (
          <AdminTestResults />
        ) : (
          <TestResults />
        )
      }
    />
    <Route
      path={ContactLocation.path}
      element={
        useContext(AuthContext).isAuthenticated ? <Contact /> : <HomePage />
      }
    />
    <Route path="*" element={PageNotFound()} />
  </Routes>
);
