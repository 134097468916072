import React, { useEffect, useState, useContext } from 'react';
import {
  Merchant,
  MerchantCredentials,
  NewMerchant,
} from '../../models/merchant';
import PageHeader from '../Shared/PageHeader';
import PageOverlayContent from '../Shared/PageOverlayContent';
import ApiService from '../../services/ApiService';
import { AuthContext } from '../../context/AuthContext';
import AddMerchantForm from '../AddMerchantForm';
import DialogTitleClose from '../DialogTitleClose';
import EditMerchantForm from '../EditMerchantForm';
import MerchantCredentialsDialog from '../MerchantCredentialsDialog';
import MerchantsDataTable from '../MerchantsDataTable';
import action_create_new_site from '../../assets/icons/action_create_new_site.svg';
import { Loader } from '../../utils/Loader';
import {
  Typography,
  Button,
  Dialog,
  Paper,
  DialogActions,
  DialogContent,
} from '@mui/material';
import './Merchants.css';
import { PageTitles } from '../../utils/PageTitles';
import { Helmet } from 'react-helmet';

const Merchants = () => {
  const authContextValue = useContext(AuthContext);
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [credentialsOpen, setCredentialsOpen] = useState(false);
  const [newCredential, setNewCredential] = useState(false);
  const [newMerchant, setNewMerchant] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addMerchantLoading, setAddMerchantLoading] = useState(false);
  const [editMerchantLoading, setEditMerchantLoading] = useState(true);
  const [viewMerchantLoading, setViewMerchantLoading] = useState(true);
  const [selectedMerchant, setSelectedMerchant] =
    useState<MerchantCredentials | null>(null);
  const [merchants, setMerchants] = useState<MerchantCredentials[]>([]);
  const [generateCredentialsLoading, setGenerateCredentialsLoading] =
    useState(false);
  const [merchantId, setMerchantId] = useState(Number);
  const [merchantName, setMerchantName] = useState(String);
  const [merchantSiteIdentifier, setMerchantSiteIdentifier] = useState(String);
  const [merchantSiteAddress, setMerchantSiteAddress] = useState(String);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [addMerchantAlertVisible, setAddMerchantAlertVisible] = useState(false);
  const [credentialsAlertVisible, setCredentialsAlertVisible] = useState(false);
  const [editMerchantAlertVisible, setEditMerchantAlertVisible] =
    useState(false);
  const [generateCredentialsAlertVisible, setGenerateCredentialsAlertVisible] =
    useState(false);

  useEffect(() => {
    fetchMerchants();
  }, []);

  const fetchMerchants = () => {
    setButtonDisable(false);
    setLoading(true);
    ApiService.getMerchants()
      .then((d) => {
        setMerchants(d.data);
      })
      .catch((e) => console.log('Error fetching merchants', e))
      .finally(() => setLoading(false));
  };

  const handleAddMerchantOpen = () => {
    setAddOpen(true);
  };

  const handleAddMerchantClose = () => {
    setAddOpen(false);
    setAddMerchantAlertVisible(false);
    setButtonDisable(false);
    setAddMerchantLoading(false);
  };

  const handleEditMerchantOpen = () => {
    setEditOpen(true);
  };

  const handleEditMerchantClose = () => {
    setEditMerchantAlertVisible(false);
    setButtonDisable(false);
    setEditOpen(false);
  };

  const handleViewCredentialsOpen = () => {
    setGenerateCredentialsAlertVisible(false);
    setCredentialsAlertVisible(false);
    setCredentialsOpen(true);
  };

  const handleViewCredentialsClose = () => {
    setCredentialsOpen(false);
    fetchMerchants();
  };

  const viewCredentials = (merchant: Merchant) => {
    setViewMerchantLoading(true);
    handleViewCredentialsOpen();
    ApiService.getMerchant(merchant.merchantId)
      .then((d) => {
        setCredentialsAlertVisible(false);
        setGenerateCredentialsAlertVisible(false);
        setSelectedMerchant(d.data);
        setNewCredential(false);
        setNewMerchant(false);
      })
      .catch((e) => {
        console.log(e);
        setCredentialsAlertVisible(true);
      })
      .finally(() => setViewMerchantLoading(false));
  };

  const addMerchant = (values: NewMerchant) => {
    setAddMerchantLoading(true);
    setButtonDisable(true);
    ApiService.createMerchant(values)
      .then((d) => {
        setAddMerchantAlertVisible(false);
        setSelectedMerchant(d.data);
        setNewMerchant(true);
        setViewMerchantLoading(false);
        handleAddMerchantClose();
        handleViewCredentialsOpen();
        fetchMerchants();
      })
      .catch((e) => {
        console.log(e);
        setAddMerchantAlertVisible(true);
        setButtonDisable(false);
      });
  };

  const generateNewCredential = (merchantId: number) => {
    setGenerateCredentialsLoading(true);
    ApiService.generateCredentials(merchantId)
      .then((d) => {
        setSelectedMerchant(d.data);
        setGenerateCredentialsAlertVisible(false);
        setNewCredential(true);
      })
      .catch((e) => {
        console.log(e);
        setGenerateCredentialsAlertVisible(true);
        setButtonDisable(false);
      })
      .finally(() => setGenerateCredentialsLoading(false));
    setButtonDisable(false);
  };

  const getMerchant = (merchant: Merchant) => {
    handleEditMerchantOpen();
    setEditMerchantLoading(true);
    ApiService.getMerchant(merchant.merchantId)
      .then((d) => {
        setMerchantId(d.data.merchantId);
        setMerchantName(d.data.merchantName);
        setMerchantSiteAddress(d.data.siteLocation);
        setMerchantSiteIdentifier(d.data.siteIdentifier);
      })
      .finally(() => {
        setEditMerchantLoading(false);
      });
  };

  const editMerchantConfirmation = (merchant: Merchant) => {
    setEditMerchantLoading(true);
    setButtonDisable(true);
    ApiService.editMerchant(merchant.merchantId, merchant)
      .then((d) => {
        setMerchantName(d.data.merchantName);
        setMerchantSiteAddress(d.data.siteLocation);
        setEditMerchantAlertVisible(false);
        handleEditMerchantClose();
      })
      .catch((e) => {
        console.log(e);
        setEditMerchantAlertVisible(true);
      })
      .finally(() => {
        fetchMerchants();
      });
  };

  return (
    <>
      <Helmet>
        <title>{PageTitles.MerchantSiteCredentials}</title>
      </Helmet>
      {authContextValue.isPOS() && (
        <>
          <PageHeader
            headerTitle="Merchant Site Credentials"
            hasOverlay={true}
            isAdmin={false}
          >
            {
              'Create and manage merchant site credentials here.\nPlease generate a new client secret if your credentials are lost, compromised or nearing expiry.\nBeware - if site credentials expire, data will not be received by the Central Platform.'
            }
          </PageHeader>
          <PageOverlayContent>
            <div className="Merchants_box">
              <div className="Merchants_TopContainer">
                <Typography variant="h5" className="Merchants_Subheader">
                  Credential Management
                </Typography>
                <Button
                  variant="action"
                  className="Merchants_AddButton"
                  onClick={handleAddMerchantOpen}
                >
                  <img
                    src={action_create_new_site}
                    className="Merchants_Icon"
                    alt="Create New Site Button"
                  />
                  <span className="Merchants_ActionButtonText">
                    Create new site
                  </span>
                </Button>
              </div>
              {/* Merchant Data Table */}
              <Loader isLoading={loading}>
                <MerchantsDataTable
                  merchantData={merchants}
                  viewCredentialsAction={viewCredentials}
                  editMerchantAction={getMerchant}
                  isUser={true}
                />
              </Loader>
            </div>
          </PageOverlayContent>
          <Paper>
            {/* Add Merchant Modal */}
            <Dialog
              fullWidth={true}
              maxWidth={'md'}
              scroll={'paper'}
              aria-labelledby="add-merchant-dialog-title"
              open={addOpen}
            >
              <DialogTitleClose
                id="add-merchant-dialog-title"
                onClose={handleAddMerchantClose}
              >
                <strong>Create a new merchant site</strong>
              </DialogTitleClose>
              <DialogContent
                dividers={true}
                className="Merchants_dialogContainer"
              >
                <Loader isLoading={addMerchantLoading}>
                  <AddMerchantForm
                    onSubmit={addMerchant}
                    alertErrorVisible={addMerchantAlertVisible}
                    merchantList={merchants}
                  />
                </Loader>
              </DialogContent>
              <DialogActions className="Merchants_ButtonContainer">
                <Button
                  variant="secondary"
                  disabled={buttonDisable}
                  className="Merchants_actionButton"
                  onClick={handleAddMerchantClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={buttonDisable}
                  className="Merchants_actionButton"
                  form="addMerchantForm"
                >
                  Create site
                </Button>
              </DialogActions>
            </Dialog>
            {/* Edit Merchant Modal */}
            <Dialog
              fullWidth={true}
              maxWidth={'md'}
              scroll={'paper'}
              aria-labelledby="edit-merchant-dialog-title"
              open={editOpen}
            >
              <DialogTitleClose
                id="add-merchant-dialog-title"
                onClose={handleEditMerchantClose}
              >
                <strong>Update merchant site information</strong>
              </DialogTitleClose>
              <DialogContent
                dividers={true}
                className="Merchants_dialogContainer"
              >
                <Loader isLoading={editMerchantLoading}>
                  <EditMerchantForm
                    onSubmit={editMerchantConfirmation}
                    merchantId={merchantId}
                    merchantName={merchantName}
                    siteAddress={merchantSiteAddress}
                    siteIdentifier={merchantSiteIdentifier}
                    merchantList={merchants}
                    alertVisible={editMerchantAlertVisible}
                    currentMerchantName={merchantName}
                  />
                </Loader>
              </DialogContent>
              <DialogActions className="Merchants_ButtonContainer">
                <Button
                  variant="secondary"
                  disabled={buttonDisable}
                  className="Merchants_actionButton"
                  onClick={handleEditMerchantClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={buttonDisable}
                  variant="primary"
                  className="Merchants_actionButton"
                  form="editMerchantForm"
                >
                  Save changes
                </Button>
              </DialogActions>
            </Dialog>
            {/* Management Secret/Credential Modal */}
            <MerchantCredentialsDialog
              open={credentialsOpen}
              selectedMerchant={selectedMerchant}
              onClose={handleViewCredentialsClose}
              generateNewCredentialAction={generateNewCredential}
              newCredential={newCredential}
              newMerchant={newMerchant}
              loadingNewCredential={generateCredentialsLoading}
              loading={viewMerchantLoading}
              credentialsAlertVisible={credentialsAlertVisible}
              secretAlertVisible={generateCredentialsAlertVisible}
              isPOS={true}
            />
          </Paper>
        </>
      )}
      {authContextValue.isDXCAdmin() && (
        <>
          <PageHeader
            headerTitle="Merchant Site Credentials"
            hasOverlay={true}
            isAdmin={true}
          >
            {'View merchant site credentials for all organisations here.'}
          </PageHeader>
          <PageOverlayContent>
            <div className="Merchants_box">
              <div className="Merchants_TopContainer">
                <Typography variant="h5" className="Merchants_Subheader">
                  Credential Management
                </Typography>
              </div>
              {/* Merchant Data Table */}
              <Loader isLoading={loading}>
                <MerchantsDataTable
                  merchantData={merchants}
                  viewCredentialsAction={viewCredentials}
                  editMerchantAction={getMerchant}
                  isUser={false}
                />
              </Loader>
            </div>
          </PageOverlayContent>
          <Paper>
            {/* Management Secret/Credential Modal */}
            <MerchantCredentialsDialog
              open={credentialsOpen}
              selectedMerchant={selectedMerchant}
              onClose={handleViewCredentialsClose}
              generateNewCredentialAction={generateNewCredential}
              newCredential={newCredential}
              newMerchant={newMerchant}
              loadingNewCredential={generateCredentialsLoading}
              loading={viewMerchantLoading}
              credentialsAlertVisible={credentialsAlertVisible}
              secretAlertVisible={generateCredentialsAlertVisible}
              isPOS={false}
            />
          </Paper>
        </>
      )}
    </>
  );
};

export default Merchants;
