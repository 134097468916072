import { Icon, Tooltip } from '@mui/material';
import action_copy_text from '../../../assets/icons/action_copy_text.svg';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
  return {
    iconButtonContainer: {
      height: '3.125rem',
      width: '3.125rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: '0.3s',
      '&:hover': {
        borderRadius: '50%',
        backgroundColor: '#F6F6F6',
        verticalAlign: 'middle',
      },
      '&:active': {
        backgroundColor: '#dedede',
        transition: 'background 0s',
      },
    },
    iconButton: {
      color: '#4D4D4D',
    },
  };
});

export interface CopyIconProps {
  onClickAction: () => void;
}

const CopyIcon = (props: CopyIconProps) => {
  const { onClickAction } = props;
  const { classes } = useStyles();

  return (
    <div>
      <Tooltip title="Copy text" placement="right-end">
        <Icon onClick={onClickAction} className={classes.iconButtonContainer}>
          <img
            src={action_copy_text}
            alt="Copy Button"
            className={classes.iconButton}
          />
        </Icon>
      </Tooltip>
    </div>
  );
};

export default CopyIcon;
