import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Merchant } from '../../models/merchant';
import { TextField } from '@mui/material';
import lodash from 'lodash';
import ErrorAlert from '../Shared/ErrorAlert';
import { MerchantCredentials } from '../../models/merchant';

interface Values {
  merchantName: string;
  siteLocation: string;
}

export interface EditMerchantFormProps {
  onSubmit: (values: Merchant) => void;
  merchantId: number;
  merchantName: string;
  siteIdentifier: string;
  siteAddress: string;
  merchantList: MerchantCredentials[];
  alertVisible: boolean;
  currentMerchantName: string;
}

const EditMerchantForm = (props: EditMerchantFormProps) => {
  const {
    onSubmit,
    merchantId,
    merchantName,
    siteIdentifier,
    siteAddress,
    merchantList,
    alertVisible,
    currentMerchantName,
  } = props;

  const validate = (values: Values) => {
    const errors: any = {};
    const merchantNameCharLimit = 100;
    const siteLocationCharLimit = 170;

    const checkLength = (
      fieldLabel: string,
      fieldName: string,
      maxLength: number,
    ) => {
      if (lodash.get(values, fieldName).length > maxLength) {
        lodash.set(formik.touched, fieldName, true);
        errors.fieldName = `${fieldLabel} cannot exceed ${maxLength} characters in length.`;
      }
    };

    checkLength('merchantName', 'merchantName', merchantNameCharLimit);
    checkLength('siteLocation', 'siteLocation', siteLocationCharLimit);

    const checkIllegalChars = (fieldName: string) => {
      const regexIllegal = /[<>;&]/;

      if (regexIllegal.test(lodash.get(values, fieldName))) {
        lodash.set(formik.touched, fieldName, true);
        errors[fieldName] =
          'The following characters are not allowed in this field: < > ; &';
      }
    };

    checkIllegalChars('merchantName');
    checkIllegalChars('siteLocation');

    return errors;
  };

  const checkEditMerchantNameDuplication = (
    merchantArray: MerchantCredentials[],
    currentMerchantName: string,
    newMerchantName: string | undefined,
  ) => {
    if (newMerchantName == undefined) {
      return false;
    }
    for (var i = 0; i < merchantArray.length; i++) {
      if (
        currentMerchantName != newMerchantName &&
        merchantArray[i].merchantName == newMerchantName
      ) {
        return true;
      }
    }
    return false;
  };

  const formik = useFormik({
    initialValues: {
      merchantId: merchantId,
      merchantName: merchantName,
      siteIdentifier: siteIdentifier,
      siteLocation: siteAddress,
    },
    validationSchema: Yup.object({
      merchantName: Yup.string()
        .max(100, 'Merchant site name cannot be greater than 100 characters.')
        .required('Please enter a merchant site name.')
        .test(
          '',
          'Merchant Site Name already exists. Please enter a unique Merchant Site Name.',
          (value) =>
            !checkEditMerchantNameDuplication(
              merchantList,
              currentMerchantName,
              value,
            ),
        ),
      siteIdentifier: Yup.string().max(100, 'Must be 100 characters or less'),
      siteLocation: Yup.string()
        .max(
          170,
          'Merchant site address cannot be greater than 170 characters.',
        )
        .required('Please enter a merchant site address.'),
    }),
    validate,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <>
      <form id="editMerchantForm" onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          variant="filled"
          margin="normal"
          id="MerchantName"
          name="merchantName"
          label="Merchant site name"
          value={formik.values.merchantName}
          onChange={formik.handleChange}
          error={
            formik.touched.merchantName && Boolean(formik.errors.merchantName)
          }
          helperText={
            formik.touched.merchantName && formik.errors.merchantName
              ? formik.errors.merchantName
              : ''
          }
        />
        <TextField
          fullWidth
          variant="filled"
          margin="normal"
          id="SiteLocation"
          name="siteLocation"
          label="Site address"
          value={formik.values.siteLocation}
          onChange={formik.handleChange}
          error={
            formik.touched.siteLocation && Boolean(formik.errors.siteLocation)
          }
          helperText={
            formik.touched.siteLocation && formik.errors.siteLocation
              ? formik.errors.siteLocation
              : ''
          }
          sx={{ paddingBottom: '1rem' }}
        />
        <ErrorAlert
          alertAction="this merchant could not be edited"
          alertVisible={alertVisible}
        />
      </form>
    </>
  );
};

export default EditMerchantForm;
