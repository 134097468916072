import React, { useEffect, useContext } from 'react';
import { Dialog } from '@mui/material';
import { AuthContext } from '../../context/AuthContext';
import ActionDialog from '../Shared/ActionDialog';
import PageHeader from '../Shared/PageHeader';
import PageOverlayContent from '../Shared/PageOverlayContent';
import ApiService from '../../services/ApiService';
import { StakeholderDocumentRow } from '../../models/stakeholderDocumentRow';
import StakeholderDocumentTable from '../Shared/StakeholderDocumentTable';
import { StakeholderUploadDialog } from '../Shared/StakeholderUploadDialog';
import { getStakeholderDocumentsApi } from '../../utils/StakeholderDocumentUtil';
import { Helmet } from 'react-helmet';
import { PageTitles } from '../../utils/PageTitles';
import './Deliverables.css';

const Deliverables = () => {
  const authContextValue = useContext(AuthContext);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteFileName, setDeleteFileName] = React.useState('');
  const [deleteLink, setDeleteLink] = React.useState('');
  const [uploadOpen, setUploadOpen] = React.useState(false);
  const [allData, setAllData] = React.useState<StakeholderDocumentRow[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const getStakeholderDocuments = (
    callback: (fileCategory: string) => Promise<StakeholderDocumentRow[]>,
  ) => {
    // Return the document
    callback('Deliverable')
      .then((documentTable) => {
        // Set the state for containerName

        // Set the state for the new documents
        setAllData(documentTable);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  // useEffect to initialise documents
  useEffect(() => {
    getStakeholderDocuments(getStakeholderDocumentsApi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDeleteDialog = (row: StakeholderDocumentRow) => {
    setDeleteFileName(row.name);
    setDeleteLink(row.link);
    setDeleteOpen(true);
  };

  const confirmDelete = () => {
    ApiService.deleteStakeholderDocument(deleteLink)
      .then(() => {
        let removeFromData = allData.filter((x) => x.link !== deleteLink);
        setAllData(removeFromData);
        setDeleteOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setDeleteOpen(false);
      });
  };

  const refreshDocuments = () => {
    setUploadOpen(false);
    getStakeholderDocuments(getStakeholderDocumentsApi);
  };

  return (
    <>
      <Helmet>
        <title>{PageTitles.Deliverables}</title>
      </Helmet>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={deleteOpen}
        aria-describedby="scroll-dialog-description"
      >
        <ActionDialog
          headingActionName="delete this file"
          actionName="delete file"
          fileName={deleteFileName}
          noFunction={() => setDeleteOpen(false)}
          yesFunction={() => confirmDelete()}
        />
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={uploadOpen}
        aria-describedby="scroll-dialog-description"
      >
        <StakeholderUploadDialog
          closeDialog={() => setUploadOpen(false)}
          confirmDialog={() => refreshDocuments()}
          fileCategory="Deliverable"
          userName={authContextValue.user?.username as string}
          admin={false}
          stakeholderName={
            authContextValue.currentUserStakeholderName === null
              ? undefined
              : authContextValue.currentUserStakeholderName
          }
        />
      </Dialog>
      <PageHeader headerTitle="Deliverables" hasOverlay={true} isAdmin={false}>
        {
          'Upload and manage your deliverable documents here.\nThis enables Services Australia and DXC to verify that you are meeting your milestones.\n\nNote: If you want a version number to show for your document, you need to add this to the filename.'
        }
      </PageHeader>
      <PageOverlayContent>
        <div className="Overlay_box">
          <StakeholderDocumentTable
            data={allData}
            tableName={'Deliverables'}
            isLoading={isLoading}
            canSeeUploader={true}
            canUpload={true}
            canDelete={true}
            isAccordion={false}
            deleteFunction={openDeleteDialog}
            uploadFunction={() => setUploadOpen(true)}
            fileCategory={'Deliverables'}
          />
        </div>
      </PageOverlayContent>
    </>
  );
};

export default Deliverables;
