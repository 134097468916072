import { AxiosResponse } from 'axios';
import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { DocumentRow } from '../../models/documentRow';
import { FactsheetRow } from '../../models/FactsheetRow';
import { userTypeKeyToUserTypeName } from '../../models/mainnav';
import ApiService from '../../services/ApiService';
import DocumentTable from '../Shared/DocumentTable';
import FactsheetTable from '../Shared/FactsheetTable';
import PageHeader from '../Shared/PageHeader';
import PageOverlayContent from '../Shared/PageOverlayContent';
import './PLBResources.css';
import { PageTitles } from '../../utils/PageTitles';
import { Helmet } from 'react-helmet';

const PLBResources = () => {
  const authContextValue = useContext(AuthContext);
  const [specificationLoading, specificationIsLoading] = React.useState(true);
  const [templatesLoading, templatesIsLoading] = React.useState(true);
  const [testingLoading, testingIsLoading] = React.useState(true);
  const [specificationTableData, setSpecificationTableData] = React.useState<
    DocumentRow[]
  >([]);
  const [templateTableData, setTemplateTableData] = React.useState<
    DocumentRow[]
  >([]);
  const [testingTableData, setTestingTableData] = React.useState<DocumentRow[]>(
    [],
  );
  const [factSheetLoading, factSheetIsLoading] = React.useState(true);
  const [factSheetData, setFactSheetData] = React.useState<FactsheetRow[]>([]);

  const getSpecificationDocuments = () => {
    ApiService.getDocuments('specification')
      .then((x) => {
        let data = x.data;
        let newTable: DocumentRow[] = [];
        for (let dataItem of data) {
          let newRow: DocumentRow = {
            id: newTable.length,
            name: dataItem.fileName,
            date: new Date(dataItem.createdOn),
            link: dataItem.containerName + '/' + dataItem.fileName,
            role: dataItem.userType,
            type: 'specification',
            createdBy: dataItem.createdBy,
            isDownloading: false,
          };
          newTable.push(newRow);
        }
        setSpecificationTableData(newTable);
        specificationIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        specificationIsLoading(false);
      });
  };

  const getTemplateDocuments = () => {
    ApiService.getDocuments('template')
      .then((x) => {
        let data = x.data;
        let newTable: DocumentRow[] = [];
        for (let dataItem of data) {
          let newRow: DocumentRow = {
            id: newTable.length,
            name: dataItem.fileName,
            date: new Date(dataItem.createdOn),
            link: dataItem.containerName + '/' + dataItem.fileName,
            role: dataItem.userType,
            type: 'template',
            createdBy: dataItem.createdBy,
            isDownloading: false,
          };
          newTable.push(newRow);
        }
        setTemplateTableData(newTable);
        templatesIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        templatesIsLoading(false);
      });
  };

  const getTestingDocuments = () => {
    ApiService.getDocuments('testing')
      .then((x) => {
        let data = x.data;
        let newTable: DocumentRow[] = [];
        for (let dataItem of data) {
          let newRow: DocumentRow = {
            id: newTable.length,
            name: dataItem.fileName,
            date: new Date(dataItem.createdOn),
            link: dataItem.containerName + '/' + dataItem.fileName,
            role: dataItem.userType,
            type: 'testing',
            createdBy: dataItem.createdBy,
            isDownloading: false,
          };
          newTable.push(newRow);
        }
        setTestingTableData(newTable);
        testingIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        testingIsLoading(false);
      });
  };

  const getFactSheetDocuments = (userType: any) => {
    ApiService.getFactsheetDocuments(userType)
      .then((x: AxiosResponse) => {
        let data = x.data;
        let newTable: FactsheetRow[] = [];
        for (let dataItem of data.value) {
          let newRow: FactsheetRow = {
            id: newTable.length,
            fileName: dataItem.FactSheet.PLBPortalDisplayName,
            publishedDescription: dataItem.FactSheet.UpdatedDateDescription,
            storageUrl: dataItem.FactSheet.StorageUrl,
          };
          newTable.push(newRow);
        }
        setFactSheetData(newTable);
        factSheetIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        factSheetIsLoading(false);
      });
  };

  useEffect(() => {
    getSpecificationDocuments();
    getTemplateDocuments();
    getTestingDocuments();
    let userTypeName =
      userTypeKeyToUserTypeName[authContextValue.currentUserType as number];
    getFactSheetDocuments(userTypeName);
  }, []);

  const factsheets = (
    <div className="resources_box">
      <FactsheetTable
        currentUserType={authContextValue.currentUserType}
        data={factSheetData}
      />
    </div>
  );

  return (
    <>
      <Helmet>
        <title>{PageTitles.PLBResources}</title>
      </Helmet>
      <PageHeader headerTitle="PLB Resources" hasOverlay={true}>
        {
          'See all PLB Resources relevant to you here. Examples of resources include technical documents, factsheets, or specific guidance.\nThese documents will be helpful in providing you with information for developing and supporting PLB.'
        }
      </PageHeader>
      <PageOverlayContent>
        <div className="resources_padding">
          <DocumentTable
            data={specificationTableData}
            tableName={'Specifications'}
            isLoading={specificationLoading}
            isAccordion={true}
          />
        </div>
        <div className="resources_padding">
          <DocumentTable
            data={templateTableData}
            tableName={'Templates'}
            isLoading={templatesLoading}
            isAccordion={true}
          />
        </div>
        <div className="resources_padding">
          <DocumentTable
            data={testingTableData}
            tableName={'Testing'}
            isLoading={testingLoading}
            isAccordion={true}
          />
        </div>
        <div className="resources_padding">{factsheets}</div>
      </PageOverlayContent>
    </>
  );
};

export default PLBResources;
