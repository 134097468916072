import React from 'react';
import { Accordion, AccordionProps } from '../Accordion/Accordion';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

/// This AccordionGroup component takes in accordionProps, which is a list of AccordionProps defined as an interface within the Accordion component. Each AccordionProp takes in {header: string, body: string}, where the header is the title of the accordion, and the body is the body of the accordion. For each AccordionProp, an Accordion component is created, creating a group of Accordion components.

// AccordionGroup example:

/* <AccordionGroup
groupHeading = "Group heading"
accordionProps = {[
  {heading : "Accordion question 1",
  body:"Accordion body - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod."},

  {heading: "Accordion question 2",
  body: "Accordion body - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."},
]}
/> */

const useStyles = makeStyles()((theme) => {
  return {
    accordion_group_heading: {
      color: 'var(--primary-purple-text)',
      textAlign: 'left',
      font: 'normal normal 600 20px/32px Open Sans',
      letterSpacing: '-0.5px',
      opacity: '1',
    },
    header_icon: {
      marginLeft: '0.3125rem',
      color: 'var(--primary-purple-text)',
    },
    mui_accordion_summary_wrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    mui_accordion_wrapper: {
      boxShadow: 'none',
      '&::before': {
        display: 'none',
      },
      backgroundColor: 'rgba(0,0,0,0)',
    },
    mui_accordion_summary: {
      '&.Mui-expanded': {
        minHeight: 'unset',
      },
      '& div': {
        alignItems: 'center',
        margin: '0.5rem 0',
      },
    },
  };
});

interface AccordionGroupProps {
  groupIndex?: number;
  groupHeading: string;
  expanded?: boolean;
  accordionProps: AccordionProps[];
  handleAGChange?: (index?: number) => void;
  handleAChange?: (parentIndex?: number, index?: number) => void;
}

export const AccordionGroup = (accordionGroupProps: AccordionGroupProps) => {
  const { classes } = useStyles();

  const handleChange = (groupIndex?: number) => {
    if (accordionGroupProps.handleAGChange !== undefined) {
      accordionGroupProps.handleAGChange(groupIndex);
    }
  };
  return (
    <>
      <MuiAccordion
        className={classes.mui_accordion_wrapper}
        disableGutters={true}
        expanded={accordionGroupProps.expanded}
        key={accordionGroupProps.groupIndex}
        onChange={() => handleChange(accordionGroupProps.groupIndex)}
      >
        <AccordionSummary className={classes.mui_accordion_summary}>
          {accordionGroupProps.groupHeading && (
            <span className={classes.mui_accordion_summary_wrapper}>
              <Typography className={classes.accordion_group_heading}>
                {accordionGroupProps.groupHeading}
              </Typography>
              {accordionGroupProps.expanded ? (
                <ExpandLessIcon className={classes.header_icon} />
              ) : (
                <ExpandMoreIcon className={classes.header_icon} />
              )}
            </span>
          )}
        </AccordionSummary>
        <AccordionDetails>
          {accordionGroupProps.accordionProps.map((item) => {
            return (
              <Accordion
                parentIndex={accordionGroupProps.groupIndex}
                index={item.index}
                heading={item.heading}
                body={item.body}
                expanded={item.expanded}
                handleAChange={accordionGroupProps.handleAChange}
              />
            );
          })}
        </AccordionDetails>
      </MuiAccordion>
    </>
  );
};

export default AccordionGroup;
